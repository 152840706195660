import $ from 'jquery';
import React, { useEffect, useState } from "react";
import * as Model from 'devexpress-dashboard/model';
import { FormItemTemplates } from 'devexpress-dashboard/designer';
import dxPopup from 'devextreme/ui/popup';
import DashboardControl , {Extensions, ViewerApi } from 'devexpress-dashboard-react';
import dxDashboardControl from 'devexpress-dashboard/integration';
import dxDataGrid from 'devextreme/ui/data_grid';
import dxTooltip from 'devextreme/ui/tooltip';
import dxTabPanel from 'devextreme/ui/tab_panel';
import dxForm from 'devextreme/ui/form';
import WebPageItem from './WebPageItem';
import KeplerItem from './keplerItem';
import ButtonItem from './ButtonCustomItem';
import { ComboBoxItem } from 'devexpress-dashboard/model';


var drilldownDashboard;
    // 1. Model
    var PagingProperty = {
        ownerType: Model.GridItem,
        propertyName: 'Paging',
        defaultValue: false,
        valueType: 'boolean'
    };

    var PageSizeProperty = {
        ownerType: Model.GridItem,
        propertyName: 'pageSize',
        defaultValue: 20,
        valueType: 'int'
    };

    var TooltipProperty = {
        ownerType: Model.GridItem,
        propertyName: 'Tooltip',
        defaultValue: false,
        valueType: 'boolean'
    };

    var PopupProperty = {
        ownerType: Model.GridItem,
        propertyName: 'Popup',
        defaultValue: false,
        valueType: 'boolean'
    };

    var popupDashboardProperty = {
        ownerType: Model.GridItem,
        propertyName: 'popupDashboard',
        defaultValue: 'dashboard9',
        valueType: 'string'
    };

    var popupWidthProperty = {
        ownerType: Model.GridItem,
        propertyName: 'popupWidth',
        defaultValue: '350',
        valueType: 'string'
    };

    var popupHeightProperty = {
        ownerType: Model.GridItem,
        propertyName: 'popupHeight',
        defaultValue: '250',
        valueType: 'string'
    }

    var popupfullscreenProperty = {
        ownerType: Model.GridItem,
        propertyName: 'popupFullScreen',
        defaultValue: false,
        valueType: 'boolean'
    }

    var NewPageProperty = {
        ownerType: Model.GridItem,
        propertyName: 'NewPage',
        defaultValue: false,
        valueType: 'boolean'
    };

    var NewPageDashboardIdProperty = {
        ownerType: Model.GridItem,
        propertyName: 'newPageDashboardId',
        defaultValue: "dashboard13",
        valueType: 'string'
    };

    var functionProperty = {
        ownerType: Model.GridItem,
        propertyName: 'functionProperty',
        valueType: 'string'
    };

    var highlightSearchProperty = {
        ownerType: Model.GridItem,
        propertyName: 'highlightSearchProperty',
        defaultValue: false,
        valueType: 'boolean'
    };

    var masterDetailProperty = {
        ownerType: Model.GridItem,
        propertyName: 'masterDetailProperty',
        defaultValue: false,
        valueType: 'boolean'
    };

    var EditingProperty = {
        ownerType: Model.GridItem,
        propertyName: 'editingProperty',
        defaultValue: false,
        valueType: 'boolean'
    };

    
    var itemNameProperty = {
        ownerType: Model.GridItem,
        propertyName: 'itemNameProperty',
        //defaultValue: false,
        valueType: 'string'
    };


    var comboBoxIdProperty = {
        ownerType: Model.ComboBoxItem,
        propertyName: 'comboBoxId',
        //defaultValue: false,
        valueType: 'string'
    };

    Model.registerCustomProperty(PagingProperty);
    Model.registerCustomProperty(PageSizeProperty);
    Model.registerCustomProperty(TooltipProperty);
    Model.registerCustomProperty(PopupProperty);
    Model.registerCustomProperty(popupDashboardProperty);
    Model.registerCustomProperty(popupWidthProperty);
    Model.registerCustomProperty(popupHeightProperty);
    Model.registerCustomProperty(popupfullscreenProperty);
    Model.registerCustomProperty(NewPageProperty);
    Model.registerCustomProperty(NewPageDashboardIdProperty);
   // Model.registerCustomProperty(functionProperty);
    //Model.registerCustomProperty(highlightSearchProperty);
    Model.registerCustomProperty(masterDetailProperty);
    Model.registerCustomProperty(EditingProperty);
    Model.registerCustomProperty(itemNameProperty);
    Model.registerCustomProperty(comboBoxIdProperty);

    // 2. Viewer
    function onItemWidgetOptionsPrepared(args) {
        if (args.dashboardItem instanceof Model.GridItem) {

            args.options.filterPanel = {
                visible :  true
            };
           args.options.columnChooser =  {
              enabled: true,
              mode: 'select'
            };

            // args.options.focusedRowEnabled = true;
            // args.options.focusedRowIndex = 0;
            args.options.allowColumnReordering = true;
        
            args.options.groupPanel = {
                visible: true,
            };
        
            args.options.grouping = {
                autoExpandAll: false
              };

                  args.options.toolbar = {
                items: [
                  'columnChooserButton',
                  'groupPanel',
                  'searchPanel',
                  'addRowButton',
                ],
              };

            var enablePaging = args.dashboardItem.customProperties.getValue(PagingProperty.propertyName);
            var pageSizeValue = args.dashboardItem.customProperties.getValue(PageSizeProperty.propertyName);
            var enableTooltip =  args.dashboardItem.customProperties.getValue(TooltipProperty.propertyName);
            var enablePopup =  args.dashboardItem.customProperties.getValue(PopupProperty.propertyName);
            var dashboardIdPopup = args.dashboardItem.customProperties.getValue(popupDashboardProperty.propertyName);
            var popupWidth = args.dashboardItem.customProperties.getValue(popupWidthProperty.propertyName);
            var popupHeight = args.dashboardItem.customProperties.getValue(popupHeightProperty.propertyName);
            var popupFullScreen = args.dashboardItem.customProperties.getValue(popupfullscreenProperty.propertyName);
            var enableNewPage =  args.dashboardItem.customProperties.getValue(NewPageProperty.propertyName);
            var newPageDashboardId = args.dashboardItem.customProperties.getValue(NewPageDashboardIdProperty.propertyName);
            var functionProp =  args.dashboardItem.customProperties.getValue(functionProperty.propertyName);
            var highlightSearch =  args.dashboardItem.customProperties.getValue(highlightSearchProperty.propertyName);
            var masterDetailProp =  args.dashboardItem.customProperties.getValue(masterDetailProperty.propertyName);
            var EditProp =  args.dashboardItem.customProperties.getValue(EditingProperty.propertyName);
            var itemName =  args.dashboardItem.customProperties.getValue(itemNameProperty.propertyName);

            var popupCustomOptions = {
                popupWidth: popupWidth,
                popupHeight: popupHeight,
                popupfullScreen: popupFullScreen,
                dashboardId: dashboardIdPopup
            }

            var newPageCustomOptions = {
                dashboardId : newPageDashboardId
            };

            //grid toopltip
            if(enableTooltip){
                args.options.onCellHoverChanged = function(e){
                    if(e.rowType == 'data'){
                        //console.log(e);
                        e.cellElement.id = "cell_"+e.key;
                         showTooltip(e.value, e.key);
                    }
                }
            }
       

           // console.log(enableTooltip);
            if(enablePaging && pageSizeValue){
                args.options.paging = {
                    enabled: enablePaging,
                    pageSize: pageSizeValue
                }
                args.options.scrolling = {
                    mode: 'standard',
                }
                // args.options.paging.enabled = enablePaging;
                // args.options.paging.pageSize = pageSizeValue;

             }//else if(enablePaging === false) {
            //    // args.options.remoteOperations = true;
            //     args.options.scrolling = {
            //       mode: 'infinite',
            //      // rowRenderingMode: 'virtual',
            //     };
            
            //     // args.options.paging = {
            //     //     pageSize: 100,
            //     //   };
            // }

            // if(pageSizeValue) {
            //     args.options.paging = {
            //         pageSize : pageSizeValue }
            // }


            if(enableTooltip){
                args.options.onCellPrepared = function (e){
                   // console.log(e);
                    if(e.column.caption == 'Status'){
                        e.column.visible = false;
                    }
                    // var columns = e.component.getVisibleColumns();
                    // var length =  columns.length;
                    // columns[length-1].visible = false;

                    // console.log(e.component.getVisibleColumns())
                }
                args.options.onRowClick = function (e) { 
                if(e.rowType == 'data'){
                   // console.log(args.dashboardItem.hiddenDimensions()[0]);
                   // console.log(e);
                }
            }
            }


            if(enablePopup){
                args.options.onRowDblClick = function (e) { 
                    if(e.rowType == 'data'){
                        var data = {
                            MessageId: e.data.DataItem0,
                            FailureTimestamp : e.data.DataItem13
                        }
                       showPopup(data, popupCustomOptions)
                    }}
            } 

            if(highlightSearch){
        //   console.log(args.options.columns[1]) ;
        //  args.options.columns[1]  = {
        //     calculateCellValue(data) {
        //         console.log(data)
        //         return data.DataItem1.replaceAll('|')
             
        //       }
        //   }

        // args.options.columns[1].encodeHtml = true;
        
            //    args.options.searchPanel = {
            //     highlightCaseSensitive: false,
            //     highlightSearchText: true,
            //     searchVisibleColumnsOnly: true,
            //         visible: true,
            //         width: 140,
            //         placeholder: 'Search...',
            //       };

                //var opar = document.getElementById('paragraph').innerHTML;
                // var search;
                // setTimeout(function(){
                //     //alert('t')
                //      search  = document.getElementsByClassName('dx-texteditor-input');
                //     console.log(search);
                //     search[1].onkeyup = highlight(search[1]);
                // }, 5000);
                // search[1].addEventListener("keyup", highlight(search[1]));
                    
                
                
            }

            // function highlight(element) {
            //     console.log("test");
            //     console.log(document.getElementById('cell_0'));
            //     var opar  = document.getElementById('cell_0').innerHTML;
            //     console.log(opar);
            //     var paragraph = document.getElementById('cell_0');
            //     console.log(paragraph);
            //     var search = element.value;
            //     console.log(search);
            //     search = search.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
            //     var re = new RegExp(search, 'g');
              
            //     if (search.length > 0)
            //       paragraph.innerHTML = opar.replace(re, `<mark>$&</mark>`);
            //     else paragraph.innerHTML = opar;
            //   }

           // console.log(args.options);

            // args.options.onCellPrepared = function (e) {
            //     console.log(e);
            //     if (e.column.displayMode == 'Hyperlink' && e.rowType == 'data') {
            //         var a = e.cellElement.find('a');
            //         // var customJS = args.dashboardItem.customProperties.getValue(CustomJSProperty.propertyName);

            //         // if (customJS) {
            //         //     var uriKey = Object.keys(e.data).filter(key => key.indexOf(e.column.dataField + '_') == 0);
            //         //     a.attr('href', 'javascript:' + customJS.replace('{0}', e.data[uriKey]));
            //         // }

            //         a.attr('target', args.dashboardItem.customProperties.getValue(PagingProperty.propertyName));
            //     }
            // };

    if(masterDetailProp){
        args.options.masterDetail = {
        enabled: true,
        template: masterDetailTemplate,
        // template(container, options) {
            
        //   const currentEmployeeData = options.data;
        //   console.log(container);
        //   $('<div>')
        // //   .addClass('master-detail-caption')
        //   .text(`${currentEmployeeData.DataItem1}`)
        //   .appendTo(container);
        // },
      };
    }

    
    if(itemName !== null){
        args.itemName = itemName;
    }

    if(args.itemName === 'mogrid'){
        args.options.columns[0].width = 40;
        args.options.columns[1].width = 80;
        args.options.columns[2].width = 60;
        args.options.columns[3].width = 60;
        args.options.columns[4].width = 60;


    }

    
    if(args.itemName === 'mtgrid'){
        args.options.columns[0].width = 40;
        args.options.columns[1].width = 80;
        args.options.columns[2].width = 60;
        args.options.columns[3].width = 60;
        args.options.columns[4].width = 60;

    }


    if(EditProp){

        // args.options.cacheEnabled = false;
        // args.options.remoteOperations = true;
        args.options.columnAutoWidth = false;
        args.options.repaintChangesOnly = false;
        args.options.editing = {
          refreshMode: 'full',
            mode: 'popup',
            allowAdding: true,
            allowUpdating: true,
            allowDeleting: true,
            useIcons: true,
      };

      for(var i=0; i< args.options.columns.length; i++){
        args.options.columns[i].allowEditing = true;
       
        if(args.options.columns[i].caption.includes('ParameterID')){
            args.options.columns[i].visible = false;
        }
      }

    //   var dataGrid;
  
    //   args.options.onInitialized = function (e){
    //      console.log(e);
    //   dataGrid =  e.component;
    //   }

      if(args.itemName === 'parameters'){
        args.options.columnAutoWidth = false;
        args.options.onSaving = function(e){
            e.element[0].id = 'ParamGrid';

            console.log(e.element);
            if(e.changes[0].type === 'insert'){
                var save = {};
                save['ParameterName'] = e.changes[0].data.DataItem0;
                save['TemplateID'] = e.changes[0].data.DataItem2;
                var params = JSON.stringify({resource: [save]});
             //  console.log(e.changes[0].data.DataItem0);
                $.api.setRecord('Parameters', params, getToken('token'), function (data){
                });
                
               
           ;
                let parameters = e.component.option('dataSource');
                
            }
    
            if(e.changes[0].type === 'update'){
            //     const change = e.changes[0];
            //     var save = {};
            //     save['ParameterName'] = change.data.DataItem0;
            //     save['TemplateID'] = change.data.DataItem2;
            //     var params = JSON.stringify(save);
     
            //     let parameters = e.component.option('dataSource');
            //     var key = change.key;
            //     var id = parameters[key].DataItem1;
            //    console.log(e.component);

            //    var datagrid = e.component.instance();
            //    datagrid.refresh();
            //    console.log(datagrid);
              //  $.api.replaceRecord('Parameters/' + id, params, getToken('token'), function (data) {});
       
                // dataGrid.refresh();
               $("#ParamGrid").dxDataGrid("instance").refresh();
               // e.component.refresh();
              // $("#ParamGrid").dxDashboardControl("instance").refresh();
               
            }
    
            if(e.changes[0].type === 'remove'){
                let parameters = e.component.option('dataSource');
                var key = e.changes[0].key;
                var id = parameters[key].DataItem1;
                $.api.deleteRecord('Parameters/' + id, null, getToken('token'), function(data){});
              // parameters = applyChanges(parameters, [e.changes[0]], { keyExpr: 'index' });
            }

            
          }

        const parametersDropdown = [
            { 'Id' : 1,
            'Name' : 'RSRP'
            },
            { 'Id' : 2,
            'Name' : 'RSRQ'
            },
            { 'Id' : 3,
            'Name' : 'SINR'
            },
            { 'Id' : 4,
            'Name' : 'PCI'
            },
            { 'Id' : 5,
            'Name' : 'Earfcn'
            }
            
        ]
        // args.options.columns[0] = {
        //     dataField: 'ParameterID',
        //     caption: 'Parameter',
        //     width: 125,
        //     lookup :  {
        //         dataSource: parametersDropdown,
        //         displayExpr: 'Name',
        //         valueExpr: 'Id',
        //       }
        // }

        args.options.columns[0].lookup = {
                dataSource: parametersDropdown,
                displayExpr: 'Name',
                valueExpr: 'Name',
              }
       // }
      }else if(args.itemName === 'templates'){
        args.options.columnAutoWidth = false;

        args.options.onSaving = function(e){
            e.element.id = 'TemplateGrid';
            if(e.changes[0].type === 'insert'){
                var save = {};
                save['TemplateName'] = e.changes[0].data.DataItem1;
              //  save['TemplateID'] = e.changes[0].data.DataItem2;
                var params = JSON.stringify({resource: [save]});
             //  console.log(e.changes[0].data.DataItem0);
                $.api.setRecord('Templates', params, getToken('token'), function (data){
                });
                
               
                e.component.refresh();
                let templates = e.component.option('dataSource');
                
            }
    
            if(e.changes[0].type === 'update'){
                const change = e.changes[0];
                var save = {};
                save['TemplateName'] = change.data.DataItem0;
                //save['TemplateID'] = change.data.DataItem2;
                var params = JSON.stringify(save);
     
                let tempaltes = e.component.option('dataSource');
                var key = change.key;
                var id = tempaltes[key].DataItem0;
               //console.log(e.component);
                $.api.replaceRecord('Templates/' + id, params, getToken('token'), function (data) {});
       
                // dataGrid.refresh();
               // $("#ParamGrid").dxDataGrid("instance").refresh();
               // e.component.refresh();
              // $("#ParamGrid").dxDashboardControl("instance").refresh();
               
            }
    
            if(e.changes[0].type === 'remove'){
                let templates = e.component.option('dataSource');
                var key = e.changes[0].key;
                var id = templates[key].DataItem0;
                $.api.deleteRecord('Templates/' + id, null, getToken('token'), function(data){});
             //  parameters = applyChanges(parameters, [e.changes[0]], { keyExpr: 'index' });
            }

      }

      args.options.onOptionChanged= function(e) {
        console.log(e);
      }
    }



    }

    for(var i = 0; i<args.options.columns.length; i++){
       
        if(args.options.columns[i].caption.includes('Timestamp')){
           // console.log(args.options.columns[i]);
                //  args.options.columns[i].format = 'yyyy/dd/mm hh:mm:ss:fff'
                args.options.columns[i].dataType = 'datetime';
                // args.options.columns[i].calculateCellValue= function(rowData) {  
                //     console.log(rowData);
                // var _date = rowData.DataItem13;  
                // var month = _date.getMonth()+1;
                // return _date.getFullYear() + '-' + month + '-' + _date.getDate() + ' ' + _date.getHours() + ':' + _date.getMinutes() + ':' + _date.getSeconds() + '.' + _date.getMilliseconds();  
                // } 
            }
        }


       
}

    if(args.dashboardItem instanceof ComboBoxItem){
        //  console.log("data items: ",args.dashboardItem.dataItems())
        //  console.log("showAllValue: ",args.dashboardItem.showAllValue())


        var comboBoxId = args.dashboardItem.customProperties.getValue(comboBoxIdProperty.propertyName);
        if(comboBoxId == 'MO'){
            args.options.onValueChanged = function(e) {
               // console.log(e);
              
              // console.log(args.dashboardItem.hiddenMeasures()[0].dataMember()); 
              // console.log(args.dashboardItem.hiddenMeasures()[0]._model);
                var index = e.value;
                var ds = e.component.getDataSource();
              //console.log(ds);
              var templateName =  ds._items[index].value[0];
              $.ajax({
                type: "GET",
                url: "https://localhost:44361/api/trackers/template/"+ templateName,
                dataType: "json",
                success: function (result, status, xhr) {
               // console.log(result);
                var dataArray = result.data;
               // console.log(dataArray);
                var queryString = '';
              for(var i= 0; i < dataArray.length; i++){
          
                 queryString += '&Param' +(i+1)+ '=' + dataArray[i].parameterNames;
              }
            //   var urlMO = 'https://prod-uk-a.online.tableau.com/t/fusatuk22/views/Parameters/Sheet1?:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link&:embed=y&:toolbar=false';
            // //  var urlMT = 'https://prod-uk-a.online.tableau.com/t/fusatuk22/views/Parameters/Sheet1?:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link&:embed=y&:toolbar=false';
            //   document.getElementById('MoChart').src = urlMO + queryString;
            //  // document.getElementById('MTchart').src = urlMT + queryString;
         
                },
                error: function (xhr, status, error) {
                    alert("Result: " + status + " " + error + " " + xhr.status + " " + xhr.statusText)
                }
            });
  
             }
        }else if(comboBoxId == 'MT'){
            args.options.onValueChanged = function(e) {
              //  console.log(e);
                var index = e.value;
                var ds = e.component.getDataSource();
             // console.log(ds);
                var parameters =  ds._items[index].value[2];
            // console.log(parameters);
              var splitParam =  parameters.split(',');
              var queryString = '';
              for(var i= 0; i < splitParam.length; i++){
                 queryString += '&Param' +(i+1)+ '=' + splitParam[i];
                 
              }
             // var urlMO = 'https://prod-uk-a.online.tableau.com/t/fusatuk22/views/Parameters/Sheet1?:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link&:embed=y&:toolbar=false';
              var urlMT = 'https://prod-uk-a.online.tableau.com/t/fusatuk22/views/Parameters/Sheet1?:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link&:embed=y&:toolbar=false';
            //  document.getElementById('MoChart').src = urlMO + queryString;
              document.getElementById('MTchart').src = urlMT + queryString;
             }
        }
     
     }
};

    // 3. Designer
    function onCustomizeSections(args) {

        var item = args.dashboardItem;
        if (item instanceof Model.GridItem) {

            //pagination section
            args.addSection({
                title: "Pagination (custom)",
                items: [
                    {
                        dataField: PagingProperty.propertyName,
                        label: {
                            text: "Enable"
                        },
                        template: FormItemTemplates.buttonGroup,
                        editorOptions: {
                            keyExpr: "value",
                            items: [{
                                value: true,
                                text: "On"
                            }, {
                                value: false,
                                text: "Off"
                            }]
                        }
                    },
                    {
                        dataField: PageSizeProperty.propertyName,
                        editorType: "dxNumberBox",
                        label: {
                            text: "Page Size"
                        },
                        editorOptions: {
                          
                        }
                    }
                ]
            },
            );

            //tooltip section
            args.addSection({
                title: "Tooltip (custom)",
                items: [
                    {
                        dataField: TooltipProperty.propertyName,
                        label: {
                            text: "Enable"
                        },
                        template: FormItemTemplates.buttonGroup,
                        editorOptions: {
                            keyExpr: "value",
                            items: [{
                                value: true,
                                text: "On"
                            }, {
                                value: false,
                                text: "Off"
                            }]
                        }
                    }
                ]

            });

            //popup section
            args.addSection({
                title: "Resizable tooltip (custom)",
                items: [
                    {
                        dataField: PopupProperty.propertyName,
                        label: {
                            text: "Enable"
                        },
                        template: FormItemTemplates.buttonGroup,
                        editorOptions: {
                            keyExpr: "value",
                            items: [{
                                value: true,
                                text: "On"
                            }, {
                                value: false,
                                text: "Off"
                            }]
                        }
                    },
                    {
                        dataField: popupDashboardProperty.propertyName,
                        editorType: "dxTextBox",
                        label: {
                            text: "Dashboard Id"
                        },
                       
                        editorOptions: {
                          
                        }
                      
                    },
                    {
                        dataField: popupWidthProperty.propertyName,
                        editorType: "dxTextBox",
                        label: {
                            text: "Width"
                        },
                    }
                    ,
                    {
                        dataField: popupHeightProperty.propertyName,
                        editorType: "dxTextBox",
                        label: {
                            text: "Height"
                        },
                       
                    },
                    {
                        dataField: popupfullscreenProperty.propertyName,
                        label: {
                            text: "Enable Full Screen"
                        },
                        template: FormItemTemplates.buttonGroup,
                        editorOptions: {
                            keyExpr: "value",
                            items: [{
                                value: true,
                                text: "On"
                            }, {
                                value: false,
                                text: "Off"
                            }]
                        }
                    }
                    
                ]
            });

            //New Page Section 
            args.addSection({
                title: "New Page (custom)",
                items: [
                    {
                        dataField: NewPageProperty.propertyName,
                        label: {
                            text: "Enable"
                        },
                        template: FormItemTemplates.buttonGroup,
                        editorOptions: {
                            keyExpr: "value",
                            items: [{
                                value: true,
                                text: "On"
                            }, {
                                value: false,
                                text: "Off"
                            }]
                        }
                    },
                    {
                        dataField: NewPageDashboardIdProperty.propertyName,
                        editorType: "dxTextBox",
                        label: {
                            text: "Dashboard Id"
                        },
                       
                        editorOptions: {
                          
                        }
                      
                    }
                    
                ]
            });

             //function section
             args.addSection({
                title: "Function (custom)",
                items: [
                    {
                        dataField: functionProperty.propertyName,
                        editorType: "dxTextArea",
                        label: {
                            text: "javascript function"
                        },
                    }
                ]
            });

             //highlightSearch section
             args.addSection({
                title: "HighlighSearch (custom)",
                items: [
                    {
                        dataField: highlightSearchProperty.propertyName,
                        label: {
                            text: "Enable"
                        },
                        template: FormItemTemplates.buttonGroup,
                        editorOptions: {
                            keyExpr: "value",
                            items: [{
                                value: true,
                                text: "On"
                            }, {
                                value: false,
                                text: "Off"
                            }]
                        }
                    }
                ]

            });


             //masterDeatil section
             args.addSection({
                title: "Master Detail (custom)",
                items: [
                    {
                        dataField: masterDetailProperty.propertyName,
                        label: {
                            text: "Enable"
                        },
                        template: FormItemTemplates.buttonGroup,
                        editorOptions: {
                            keyExpr: "value",
                            items: [{
                                value: true,
                                text: "On"
                            }, {
                                value: false,
                                text: "Off"
                            }]
                        }
                    }
                ]

            });


             //edit section
             args.addSection({
                title: "Edit (custom)",
                items: [
                    {
                        dataField: EditingProperty.propertyName,
                        label: {
                            text: "Enable"
                        },
                        template: FormItemTemplates.buttonGroup,
                        editorOptions: {
                            keyExpr: "value",
                            items: [{
                                value: true,
                                text: "On"
                            }, {
                                value: false,
                                text: "Off"
                            }]
                        }
                    }
                ]

            });


             //itemname section
             args.addSection({
                title: "Item Name (custom)",
                items: [
                    {
                        dataField: itemNameProperty.propertyName,
                        editorType: "dxTextBox",
                        label: {
                            text: "Item Name"
                        },
                       
                    }
                    
                ]
            });


        }

        else if (item instanceof ComboBoxItem) {
            args.addSection({
                title: "Item Id (custom)",
                items: [
                    {
                        dataField: comboBoxIdProperty.propertyName,
                        editorType: "dxTextBox",
                        label: {
                            text: "Item Id"
                        },
                       
                    }
                    
                ]
            });
        }



    };

     function onItemCaptionToolbarUpdated(e,s) {  
        //remove maximize 
        if (e.itemName === "customItemDashboardItem3" || e.itemName === "customItemDashboardItem4" || e.itemName === "customItemDashboardItem5" || e.itemName === "customItemDashboardItem6") {  
      
            var target = e.options.actionItems.find(function (element) {  
                return element.name === "maximize-down-item";  
            });  
      
            var index = e.options.actionItems.indexOf(target);  
            if (index > -1) {  
                e.options.actionItems.splice(index, 1);  
            }  
        } 
    }

    // 4. Event Subscription
    class GridPaginationOptionsExtension {

        constructor(dashboardControl){
            this.name = "GridPaginationOptions";
            this.start = function () {
                var viewerApiExtension = dashboardControl.findExtension('viewer-api');
                if (viewerApiExtension) {
                    viewerApiExtension.on('itemWidgetOptionsPrepared', onItemWidgetOptionsPrepared);
                }
                var optionsPanelExtension = dashboardControl.findExtension("item-options-panel");
                if (optionsPanelExtension) {
                    optionsPanelExtension.on('customizeSections', onCustomizeSections);
                }
            };

            this.stop = function () {
                var viewerApiExtension = dashboardControl.findExtension('viewer-api');
                if (viewerApiExtension) {
                    viewerApiExtension.off('itemWidgetOptionsPrepared', onItemWidgetOptionsPrepared);
                }
                var optionsPanelExtension = dashboardControl.findExtension("item-options-panel");
                if (optionsPanelExtension) {
                    optionsPanelExtension.off('customizeSections', onCustomizeSections);
                }
            };
        }
    }



    // function onItemEndUpdate(e) {     
    //   drilldownDashboard = e.component;
    //   console.log(drilldownDashboard);
    //  // var state = "{\"Parameters\":{\"Name\":\"Id\",\"Value\":519,\"Type\":\"typeof(int)\"}}";
    //   // var state = "{\"Items\":{\"gridDashboardItem1\":{\"MasterFilterValues\":[[\"519\"]]}}}";
    // //   var state = "{\"Parameters\":{\"Id\":521}}";
    // //    drilldownDashboard.setDashboardState(state);
    //      //console.log("drilldownDashboard", drilldownDashboard);
    //      console.log(drilldownDashboard.getDashboardState());
    //    // dashboardControl.loadDashboard("dashboard9");
    // }

    // function openNewPageDashboard(MessageId,customOptions){

    //     var dashboardContainer = document.createElement('div');
    //     dashboardContainer.id = 'webDashboard';
    //     dashboardContainer.style.position = 'absolute';
    //     dashboardContainer.style.height = '100%';
    //     dashboardContainer.style.left = 0;
    //     dashboardContainer.style.right = 0;
    //     dashboardContainer.style.top = 0;
    //     dashboardContainer.style.bottom = 0;
    //     var dashboardOptions = getDashboardOptions(MessageId, customOptions);
    //     drilldownDashboard  = new dxDashboardControl(dashboardContainer, dashboardOptions); 
    //     console.log(drilldownDashboard);
    //     drilldownDashboard.loadDashboard(customOptions.dashboardId)  
    //     return dashboardContainer;
    // }

    function showPopup(data, customOptions) {
        var popupContainer = document.createElement('div');
        document.body.appendChild(popupContainer);

        var popupOptions = { 
            width : customOptions.popupWidth,
            height : customOptions.popupHeight,
            //container: '#popupContainer',
           // fullScreen: customOptions.popupfullScreen,
            dragEnabled:true,
            resizeEnabled: true,
           // showTitle:true,
            position: {
                at: 'center',
                my: 'center',
               // of: '.dx-dashboard-item'
              },
           // hideOnOutsideClick: true,
            showCloseButton:true,
            wrapperAttr: {
                // id: "elementId",
                class: "hide-overlay"
            },
            contentTemplate: function(contentContainer) {
              //  console.log(contentContainer);
                var dashboardContainer = document.createElement('div');
                dashboardContainer.id = 'webDashboard';
                dashboardContainer.style.position = 'relative';
                dashboardContainer.style.width = '100%';
                dashboardContainer.style.height = '100%';
                dashboardContainer.style.left = 0;
                dashboardContainer.style.right = 0;
                dashboardContainer.style.top = 0;
                dashboardContainer.style.bottom = 0;
                var dashboardOptions = getDashboardOptions(data, customOptions);
                drilldownDashboard  = new dxDashboardControl(dashboardContainer, dashboardOptions);   
                drilldownDashboard.registerExtension(new GridPaginationOptionsExtension(drilldownDashboard));
                drilldownDashboard.registerExtension(new WebPageItem(drilldownDashboard));
                drilldownDashboard.registerExtension(new KeplerItem(drilldownDashboard));
                drilldownDashboard.registerExtension(new ButtonItem(drilldownDashboard));
                var viewerApiExtension = drilldownDashboard.findExtension('viewer-api');
                if (viewerApiExtension) {
                 viewerApiExtension.on('itemWidgetOptionsPrepared', onItemWidgetOptionsPrepared);
                 viewerApiExtension.on('itemCaptionToolbarUpdated', onItemCaptionToolbarUpdated);
                }
                return dashboardContainer;

                //  var gridContainer = document.createElement('div');
                //  gridContainer.id = 'drilldowngrid';
                // var gridOptions = getGridOptions(MessageId);
                // new dxDataGrid(gridContainer, gridOptions);
                // return gridContainer;


            },
            onHidden: function() {
                document.body.removeChild(popupContainer)
            },
            // title: 'Drill Down',
        };

        var popup = new dxPopup(popupContainer, popupOptions);
        popup.show();
      }



      function showTooltip(value,key) {
        var tooltipContainer = document.createElement('div');
        document.body.appendChild(tooltipContainer);
        var tooltipOptions = {
            target : '#cell_'+key, 
            showEvent: 'mouseover',
            hideEvent: 'mouseout',
            hideOnOutsideClick: true,
           // contentTemplate: value,
           contentTemplate: function(data) {
           //console.log(data);
          return "<div style='text-align: left'>" + value + "</div>";
          },
        };

        var tooltip = new dxTooltip(tooltipContainer, tooltipOptions);
      
      }

      function formatTimestamp(Timestamp){
        console.log(Timestamp); // 2022/4/27 9:57:21:860
       // var objectDate = new Date("2020-04-10 17:14:00:123");
       var objectDate = new Date(Timestamp);
       console.log(objectDate);
        var fullYear = objectDate.getFullYear();
        var month =  objectDate.getMonth() + 1;
        var day = objectDate.getDate();
        var hour =  objectDate.getHours();
        var minute = objectDate.getMinutes();
        var second =  objectDate.getSeconds();
        var millisecond =  objectDate.getMilliseconds();

        var newFormat = month + "/" + day + "/" + fullYear + " " + hour +":"+ minute+ ":" + second ;
        console.log(newFormat);
        return newFormat;
      }

      function getDashboardOptions(data, customOptions){
        var timestamp = formatTimestamp(data.FailureTimestamp);
       var dashOptions = {
        endpoint: 'http://localhost:5000/api/dashboard',
      //endpoint: "https://insight2-server.azurewebsites.net/api/dashboard",
       //endpoint : 'https://localhost:44361/api/dashboard',
       // onItemEndUpdate : onItemEndUpdate ,
        initialDashboardId: customOptions.dashboardId,
        workingMode: "ViewerOnly",
        initialDashboardState: "{\"Parameters\":{\"Id\":" + data.MessageId + ",\"MOtimestamp\": \"" + timestamp + "\",\"MTtimestamp\":\"" + timestamp + "\"}}",
       //initialDashboardState: "{\"Parameters\":{\"Id\":" + data.MessageId + "}}",
      //  initialDashboardState : "{\"Items\":{\"gridDashboardItem1\":{\"DrillDownValues\":[\""+ MessageId+"\"]}}}",
       //initialDashboardState : "{\"Items\":{\"gridDashboardItem1\":{\"MasterFilterValues\":[[\""+ MessageId+"\"]]}}}",
        extensions: {
            "urlState": {
                includeDashboardStateToUrl: true
            }
        }
       }
       return dashOptions;
      }

      function masterDetailTemplate(_, masterDetailOptions) {
        var element = document.createElement('div');
        var tabPanelContainer = document.createElement('div');

        var tabPanelOptions =  {items: [{
            title: 'Categorizations',
            template: createOrdersTabTemplate(masterDetailOptions.data),
          }, {
            title: 'Solutions',
            template: createSolutionsTabTemplate(masterDetailOptions.data),
          },
          {
            title: 'Tickets',
          template: createTicketsTabTemplate(masterDetailOptions.data),
          },
          {
            title: 'Comments',
            template: createCommentsTabTemplate(masterDetailOptions.data),
          }]};

        element.appendChild(tabPanelContainer);
      
        var tabPanel = new dxTabPanel(tabPanelContainer, tabPanelOptions);
        return element;
      }

      function createOrdersTabTemplate(masterDetailData) {
        return function () {
          let orderHistoryDataGrid;
          function onProductChanged(productID) {
            //orderHistoryDataGrid.option('dataSource', createOrderHistoryStore(productID));
          }
          function onDataGridInitialized(e) {
            orderHistoryDataGrid = e.component;
          }

          var element = document.createElement('div');
        var formContainer = document.createElement('div');
        var formOptions = {
            labelLocation: 'top',
            items: [ {
           
              template: createOrderHistoryTemplate(onDataGridInitialized),
            }],
          }

          element.appendChild(formContainer);
          var form = new dxForm(formContainer, formOptions);
        return element;

        };
      }
      

      
      function createOrderHistoryTemplate(onDataGridInitialized) {
        return function () {
            var gridOptions = {
                onInitialized: onDataGridInitialized,
                showBorders: true,
                columns: [
                  'Id',
                  'Level1',
                  'Level2',
                  'Level3',
                  'Side',
                  'RAT',
                  'CreatedBy',
                  'Categorization Type',
                  'Timestamp'
                ],
              };

              var element = document.createElement('div');
              var gridContainer = document.createElement('div');
              element.appendChild(gridContainer);
          var dxGrid = new dxDataGrid(gridContainer, gridOptions);
        return element;
        };
      }
      

      function createSolutionsTabTemplate(masterDetailData) {
        return function () {
          let orderHistoryDataGrid;
          function onProductChanged(productID) {
            //orderHistoryDataGrid.option('dataSource', createOrderHistoryStore(productID));
          }
          function onDataGridInitialized(e) {
            orderHistoryDataGrid = e.component;
          }

          var element = document.createElement('div');
        var formContainer = document.createElement('div');
        var formOptions = {
            labelLocation: 'top',
            items: [ {
           
              template: createSolutionHistoryTemplate(onDataGridInitialized),
            }],
          }

          element.appendChild(formContainer);
          var form = new dxForm(formContainer, formOptions);
        return element;

        };
      }

      function createSolutionHistoryTemplate(onDataGridInitialized) {
        return function () {
            var gridOptions = {
                onInitialized: onDataGridInitialized,
           
                columnsAutoWidth: false,
                showBorders: true,
                
                columns: [
                  'Id',
                  'Type',
                  'Summary',
                  'Status',
                  'Owner',
                  'Category',
                ],
              };

              var element = document.createElement('div');
              var gridContainer = document.createElement('div');
              element.appendChild(gridContainer);
          var dxGrid = new dxDataGrid(gridContainer, gridOptions);
        return element;
        };
      }


      function createTicketsTabTemplate(masterDetailData) {
        return function () {
          let orderHistoryDataGrid;
          function onProductChanged(productID) {
            //orderHistoryDataGrid.option('dataSource', createOrderHistoryStore(productID));
          }
          function onDataGridInitialized(e) {
            orderHistoryDataGrid = e.component;
          }

          var element = document.createElement('div');
        var formContainer = document.createElement('div');
        var formOptions = {
            labelLocation: 'top',
            items: [ {
           
              template: createTicketsHistoryTemplate(onDataGridInitialized),
            }],
          }

          element.appendChild(formContainer);
          var form = new dxForm(formContainer, formOptions);
        return element;

        };
      }

      function createTicketsHistoryTemplate(onDataGridInitialized) {
        return function () {
            var gridOptions = {
                onInitialized: onDataGridInitialized,
                showBorders: true,
                
                columns: [
                  'TicketId',
                  'Status',
                  'Category',
                ],
              };

              var element = document.createElement('div');
              var gridContainer = document.createElement('div');
              element.appendChild(gridContainer);
          var dxGrid = new dxDataGrid(gridContainer, gridOptions);
        return element;
        };
      }


      function createCommentsTabTemplate(masterDetailData) {
        return function () {
          let orderHistoryDataGrid;
          function onProductChanged(productID) {
            //orderHistoryDataGrid.option('dataSource', createOrderHistoryStore(productID));
          }
          function onDataGridInitialized(e) {
            orderHistoryDataGrid = e.component;
          }

          var element = document.createElement('div');
        var formContainer = document.createElement('div');
        var formOptions = {
            labelLocation: 'top',
            items: [ {
           
              template: createCommentsHistoryTemplate(onDataGridInitialized),
            }],
          }

          element.appendChild(formContainer);
          var form = new dxForm(formContainer, formOptions);
        return element;

        };
      }

      function createCommentsHistoryTemplate(onDataGridInitialized) {
        return function () {
            var gridOptions = {
                onInitialized: onDataGridInitialized,
                showBorders: true,
                
                columns: [
                  'User Name',
                  'Comment',
                  'test',
                  'test',

                ],
              };

              var element = document.createElement('div');
              var gridContainer = document.createElement('div');
              element.appendChild(gridContainer);
          var dxGrid = new dxDataGrid(gridContainer, gridOptions);
        return element;
        };
      }

      
      function formItemTemplate(item) {
        return $('<span>').text(item.editorOptions.value);
      }

      function getToken(key) {
        return sessionStorage.getItem(key);
    }
      
    //   function createOrderHistoryStore(productID) {
    //     return DevExpress.data.AspNet.createStore({
    //       key: 'OrderID',
    //       loadParams: { ProductID: productID },
    //       loadUrl: `${url}/GetOrdersByProduct`,
    //     });
    //   }


    //   function getFields() 
    //   { const fields = [
    //     {
    //       caption: 'MessageId',
    //       dataField: 'MessageId',
    //       dataType: 'number',
    //       visible: false
    //     }, {
    //         caption: 'MessageContent',
    //       dataField: 'MessageContent',
    //       dataType: 'string',
    //     }
    //   ];

    //   return fields;
    // }
      
    //   function getDatabyId(MessageId){
    //     var data = [
    //         {
    //             "MessageId": 512,
    //             "MessageContent": "Device: EQ4|Time : 08:46:34.981|Stack : 1|Sub Cmd : DM LTE Data Out|Cmd Type : 8|Timestamp : 40374839|Total Throughput|  UL Throughput : 0 kb/s|  DL Throughput : 0 kb/s|Throughput per CC : |  [0 ] : |    Throughput Info|      UL Throughput : 0 kb/s|      DL Throughput : 0 kb/s|  [1 ] : |    Throughput Info|      UL Throughput : 0 kb/s|      DL Throughput : 0 kb/s|  [2 ] : |    Throughput Info|      UL Throughput : 0 kb/s|      DL Throughput : 0 kb/s|  [3 ] : |    Throughput Info|      UL Throughput : 0 kb/s|      DL Throughput : 0 kb/s|  [4 ] : |    Throughput Info|      UL Throughput : 0 kb/s|      DL Throughput : 0 kb/s|  [5 ] : |    Throughput Info|      UL Throughput : 0 kb/s|      DL Throughput : 0 kb/s|	",
              
    //         },
    //         {
    //             "MessageId": 519,
    //             "MessageContent": "Device: EQ4|Time : 08:46:34.981|Stack : 1|Sub Cmd : DM EDGE Data Out|Cmd Type : 5|Timestamp : 40374839|Arfcn : 65535|BSIC : 0-0|RxLev : 0 (-111 dBm)|Nco : 0|Crh : 0|Nmo : 0|Location Area Identification|  Mobile Country Code (MCC) : 000|  Mobile Network Code (MNC) : 000|  Location Area Code (LAC) : 0 (Hex 0x0000)|Rac : 0|CellId : 0|GprsSupported : False|EgprsSupported : False|PbcchPresent : False|Dtx : False|BsPaMfrms : 0|CcchPageMode : Normal|PccchPageMode : Normal|ChannelDescription : 0|ChannelMode : SignallingOnly|ChannelType : Invalid|CipherInd : CipherOff|TimingAdvance : 0|C1 : 0|C2 : 0|C31 : 0|C32 : 0|DRX Cycle : 65535 (ms)|	",
     
    //         },
    //         {
    //             "MessageId": 521,
    //             "MessageContent": "Device: EQ4|Time : 08:46:34.981|Stack : 1|Sub Cmd : DM EDGE Data Out|Cmd Type : 11|Timestamp : 40374840|Serving cell|  Arfcn : 0|  BSIC : 0-0|  RxLev : 0 (-111 dBm)|  RxLev full : 0 (-111 dBm)|  RxQual full : 0|  RxLev sub : 0 (-111 dBm)|  RxQual sub : 0|  TxLev : 0|Neighbor cells : |  [0 ] : |    Arfcn : 0|    BSIC : 0-0|    RxLev : 0 (-111 dBm)|    FrameNumOffset : 0|  [1 ] : |    Arfcn : 0|    BSIC : 0-0|    RxLev : 0 (-111 dBm)|    FrameNumOffset : 0|  [2 ] : |    Arfcn : 0|    BSIC : 0-0|    RxLev : 0 (-111 dBm)|    FrameNumOffset : 0|  [3 ] : |    Arfcn : 0|    BSIC : 0-0|    RxLev : 0 (-111 dBm)|    FrameNumOffset : 0|  [4 ] : |    Arfcn : 0|    BSIC : 0-0|    RxLev : 0 (-111 dBm)|    FrameNumOffset : 0|  [5 ] : |    Arfcn : 0|    BSIC : 0-0|    RxLev : 0 (-111 dBm)|    FrameNumOffset : 0|  [6 ] : |    Arfcn : 0|    BSIC : 0-0|    RxLev : 0 (-111 dBm)|    FrameNumOffset : 0|  [7 ] : |    Arfcn : 0|    BSIC : 0-0|    RxLev : 0 (-111 dBm)|    FrameNumOffset : 0|  [8 ] : |    Arfcn : 0|    BSIC : 0-0|    RxLev : 0 (-111 dBm)|    FrameNumOffset : 0|	",

    //         },
    //         {
    //             "MessageId": 544,
    //             "MessageContent": "Device: EQ4|CurrentRAT: LTE|Time : 08:46:34.981|Stack : 1|Sub Cmd : DM Common Data Out|Cmd Type : 0|Timestamp : 40374875|RAT : EUTRAN|Modem Status : Initial|PCell MIMO Type : 2x2|Rx Frequency : 2119900000 Hz|Tx Frequency : 1929900000 Hz|Rx Frequency2 : 3249773312 Hz|NumberOfScells : 0|	",
       
    //         },
    //         {
    //             "MessageId": 1040,
    //             "MessageContent": "Device: EQ4|CurrentRAT: LTE|Time : 08:46:34.981|Stack : 1|Sub Cmd : DM LTE Data Out|Cmd Type : 27|Timestamp : 40374885|PCell Modulation QPSK Cnt : 1|PCell Modulation 16QAM Cnt : 0|PCell Modulation 64QAM Cnt : 0|PCell Modulation 256QAM Cnt : 0|PCell RB Num (Avg) : 0|PCell RB Num (Sum) : 9|PCell Number of Allocated TTIs : 1|PCell Number of Total TTIs : 5760|PDSCH Bler Info Total Info Total BLER CRC Pass Count : 1|PDSCH Bler Info Total Info Total BLER CRC Fail Count : 0|	",
          
    //         }
    //     ]
    //         return  data;
    //   }

    //   function getGridOptions(MessageId) {
    //     var gridOptions = {
    //         dataSource : getDatabyId(MessageId),
    //         columns: getFields(),
    //         filterSyncEnabled: true,
    //         wordWrapEnabled: true,
    //         filterValue: ["MessageId", "=", MessageId], 
    //     }

    //     return gridOptions;
    //   }



export default GridPaginationOptionsExtension;