import $ from 'jquery';
import { Dashboard, CustomItem } from 'devexpress-dashboard/model'
import { CustomItemViewer, ResourceManager } from 'devexpress-dashboard/common'
import { FormItemTemplates } from 'devexpress-dashboard/designer'
import dxSelectBox from 'devextreme/ui/select_box';
import dxChart from 'devextreme/viz/chart'
import CustomStore from "devextreme/data/custom_store";
//var url = "https://insight2-server.azurewebsites.net/";
var url = "https://localhost:44361/";
var svgIcon = '<svg id="selectBoxIcon" viewBox="0 0 24 24"><path stroke="#ffffff" fill="#f442ae" d="M12 2 L2 22 L22 22 Z" /></svg>';
var SelectBoxMetaData = {
	// bindings: [{
	// 	propertyName: 'measureValue',
	// 	dataItemType: 'Measure',
	// 	displayName: 'Value'
	// }, {
	// 	propertyName: 'dimensionValue',
	// 	dataItemType: 'Dimension',
	// 	displayName: 'Argument',
	// 	enableColoring: true,
	// 	enableInteractivity: true
	// }],
	interactivity: {
		filter: true
	},
	customProperties: [
    {
        ownerType: CustomItem,
        propertyName: 'datasourceProperty',
        valueType: 'string',
        defaultValue: 'LteMeasurement'
    },
    {
        ownerType: CustomItem,
        propertyName: 'datasourcekeyprop',
        // defaultValue: false,
        valueType: 'string'
    },
    {
        ownerType: CustomItem,
        propertyName: 'selectBoxId',
        valueType: 'string',
       // defaultValue: 'LteMeasurement'
    },
    ],
	optionsPanelSections: [{
		title: 'Custom options',
		items: [
        {
            dataField: 'datasourceProperty',
            label: {
                text: "DataSource Name"
            },
            editorType: "dxTextBox",
            editorOptions: {
                placeholder: "Enter datasource name"
            }
        },
        {
            dataField: 'datasourcekeyprop',
            editorType: "dxTextBox",
            editorOptions: {
                placeholder: "Enter key for Datasource"
            }
        },
        {
            dataField: 'selectBoxId',
            editorType: "dxTextBox",
            editorOptions: {
                placeholder: "Enter id"
            }
        },
    ]
	},

],
	icon: 'selectBoxIcon',
	title: 'Select Box'
};
window.$templates = [];
function isNotEmpty(value) {
    return value !== undefined && value !== null && value !== "";
}

class SelectBoxItemViewer extends CustomItemViewer {
	constructor(model, $container, options) {		
        super(model, $container, options);
        this.dxSelectBoxWidget = null;
        this.dxSelectBoxWidgetSettings = undefined;
	}



    _getDataSource() {
        var _this = this;
        var dsName = this.getPropertyValue('datasourceProperty');
        //var keys = [];
        var keys = this.getPropertyValue('datasourcekeyprop');
        //console.log(keys);
        var customDataSource = new CustomStore({
             key: keys,
            load(loadOptions) {
                return _this.loadTable(loadOptions, dsName);
            },
            byKey: function (key) {
                var data = $.getJSON(url + "api/trackers/templatebykey/" + key);
                window.$templates = data;
                return data;
            }
        });
        return customDataSource;
    };


    loadTable(loadOptions, dsName) {
        var d = $.Deferred();
    var params = {};
    [
        "filter",
        "group",
        "groupSummary",
        "parentIds",
        "requireGroupCount",
        "requireTotalCount",
        "searchExpr",
        "searchOperation",
        "searchValue",
        "select",
        "sort",
        "skip",
        "take",
        "totalSummary",
        "userData"
    ].forEach(function (i) {
        if (i in loadOptions && isNotEmpty(loadOptions[i])) {
            params[i] = JSON.stringify(loadOptions[i]);
        }
    });
       //console.log(url+"api/trackers/" + dsName);
       
     
        $.getJSON(url+"api/trackers/" + dsName, params).done(function (response) {
            d.resolve(response.data, {
                totalCount: response.totalCount,
                summary: response.summary,
                groupCount: response.groupCount

            });
        }).fail(function () {
            throw "Data loading error"
        });

        return d.promise();
    // }
  
  
}


    _getdxSelectBoxWidgetSettings() {
       
        var _this = this;
        return {
            dataSource:  this._getDataSource(),
            elementAttr: {
                id: this.getPropertyValue('selectBoxId')
            },
            displayExpr: "templateName",
            valueExpr: 'templateName',
 
              
        };
    }


    setSelection() {
        var _this = this;
        this.dxSelectBoxWidget.getAllItems().forEach(function (item) {
            item.select(_this.isSelected(item.data.clientDataRow));
        });
    }

    clearSelection() {
        this.dxSelectBoxWidget.clearSelection();
    }

    // setSize(width, height) {
    //     Object.getPrototypeOf(SelectBoxItemViewer.prototype).setSize.call(this, width, height);
    //     this.dxSelectBoxWidget.render();
    // }

    renderContent(element, changeExisting) {

        if (!changeExisting) {

            while (element.firstChild)
                element.removeChild(element.firstChild);

            var div = document.createElement('div');
            div.style.width = "100%";
            div.style.height = "100%";
            element[0].appendChild(div);
            this.dxSelectBoxWidget = new dxSelectBox(div, this._getdxSelectBoxWidgetSettings());
        } else {
            this.dxSelectBoxWidget.option(this._getdxSelectBoxWidgetSettings());
        }

        this.selectTemplate(this.dxSelectBoxWidget)


    }


    selectTemplate(selectBoxInstance){
        if (selectBoxInstance._$element[0].id === 'MO') {
           // console.log('window.$templates bl select template', window.$templates)
            //console.log("args",args.options)
           // var ds =  selectBoxInstance.getDataSource();
          
                selectBoxInstance.option('value', 'LTE');
     
            //       var templateName = e.selectedItem.templateName;
            //       $.ajax({
            //           type: "GET",
            //           url: url + "api/trackers/templatebykey/" + templateName,
            //           dataType: "json",
            //           success: function (result, status, xhr) { // console.log(result);
            //               var dataArray = result.data;
            //              //  console.log("dataArray",dataArray);
            //               var panes = [];
            //               var series = [];
            //               var valueField = 'value';
            //               for (let i = 0; i < dataArray.length; i++) {
            //                   let obj = panes.find(panes => panes.name === dataArray[i].chartName);
  
            //                   if (obj === undefined) {
            //                       panes.push({name: dataArray[i].chartName})
            //                   }
            //                   series.push({pane: dataArray[i].chartName, valueField: valueField, name: dataArray[i].parameter})
            //               }

            //             //   console.log("panes",panes)
            //             //   console.log("series",series)
  
            //               var MOdataSource = [];
  
            //               for (let i = 0; i < window.$mometrics.length; i++) {
            //                   let found = dataArray.find(item => item['parameter'] === window.$mometrics[i].name);
                             
            //                   if (found !== undefined) {
            //                       MOdataSource.push(window.$mometrics[i]);
            //                   }
                               
            //               }
  
            //               // console.log('mo panes', panes);
            //               // console.log('mo series', series);
            //               // console.log("MOdataSource",MOdataSource);
  
            //               moChart.option('dataSource', MOdataSource);
            //               moChart.option('panes', panes);
            //               moChart.option('series', series);
            //               moChart.option('tooltip.customizeTooltip', function (info) { // console.log(info)
            //               })
            //               moChart.refresh();
            //           },
            //           error: function (xhr, status, error) {
            //               console.log("Result: " + status + " " + error + " " + xhr.status + " " + xhr.statusText)
            //           }
            //       });
  
            //   });
                selectBoxInstance.option('onSelectionChanged', function (e) {
                    //selectBoxInstance.option('value', 'LTE');
                    console.log(e);
                    console.log('window.$mometrics',window.$mometrics);
                  var moChart = $('#MOchart').dxChart('instance');
                  var templateName = e.selectedItem.templateName;
                  $.ajax({
                      type: "GET",
                      url: url + "api/trackers/templatebykey/" + templateName,
                      dataType: "json",
                      success: function (result, status, xhr) { // console.log(result);
                          var dataArray = result.data;
                         //  console.log("dataArray",dataArray);
                          var panes = [];
                          var series = [];
                          var valueField = 'value';
                          for (let i = 0; i < dataArray.length; i++) {
                              let obj = panes.find(panes => panes.name === dataArray[i].chartName);
  
                              if (obj === undefined) {
                                  panes.push({name: dataArray[i].chartName})
                              }
                              series.push({pane: dataArray[i].chartName, valueField: dataArray[i].parameter, name: dataArray[i].parameter})
                          }

                        //   console.log("panes",panes)
                        //   console.log("series",series)
  
                          var MOdataSource = [];
  
                          for(let i = 0; i< window.$mometrics.length; i++){
                            let found = dataArray.find( item => item['parameter'] === window.$mometrics[i].name );
                            if(found !== undefined){
                             // console.log(found,window.$mometrics[i]);
                             var myvalue={
                             [window.$mometrics[i].name]: window.$mometrics[i].value,
                             timestamp: window.$mometrics[i].timestamp
                            };
                              MOdataSource.push(myvalue);
                            }
                          }

  
                          // console.log('mo panes', panes);
                          // console.log('mo series', series);
                          // console.log("MOdataSource",MOdataSource);
  
                          moChart.option('dataSource', MOdataSource);
                          moChart.option('panes', panes);
                          moChart.option('series', series);
                          moChart.option('tooltip.customizeTooltip', function (info) { // console.log(info)
                          })
                          moChart.refresh();
                      },
                      error: function (xhr, status, error) {
                          console.log("Result: " + status + " " + error + " " + xhr.status + " " + xhr.statusText)
                      }
                  });
  
              });
          }else if(selectBoxInstance._$element[0].id === 'MT'){
            console.log('window.$mtmetrics',window.$mtmetrics);
            selectBoxInstance.option('value', 'LTE');
            selectBoxInstance.option('onSelectionChanged' , function (e) {
                var mtChart = $('#MTchart').dxChart('instance');
                var templateName = e.selectedItem.templateName;
                $.ajax({
                    type: "GET",
                    url: url + "api/trackers/templatebykey/" + templateName,
                    dataType: "json",
                    success: function (result, status, xhr) { // console.log(result);
                        var dataArray = result.data;
                        // console.log(dataArray);
                        var panes = [];
                        var series = [];
                        var valueField = 'value';
                        for (var i = 0; i < dataArray.length; i++) {
                            let obj = panes.find(panes => panes.name === dataArray[i].chartName);

                            if (obj === undefined) {
                                panes.push({name: dataArray[i].chartName})
                            }
                            series.push({pane: dataArray[i].chartName, valueField: dataArray[i].parameter, name: dataArray[i].parameter})
                        }

                        var MTdataSource = [];

                        for(let i = 0; i< window.$mtmetrics.length; i++){
                            let found = dataArray.find( item => item['parameter'] === window.$mtmetrics[i].name );
                            if(found !== undefined){
                              // console.log(found,window.$mometrics[i]);
                              var myvalue={
                              [window.$mtmetrics[i].name]: window.$mtmetrics[i].value,
                              timestamp: window.$mtmetrics[i].timestamp
                             };
                               MTdataSource.push(myvalue);
                             }                       
                          }

                        // console.log('mo panes', panes);
                        // console.log('mo series', series);
                        // console.log("MTdataSource",MTdataSource);

                        mtChart.option('dataSource', MTdataSource);
                        mtChart.option('panes', panes);
                        mtChart.option('series', series);
                        mtChart.option('tooltip.customizeTooltip', function (info) { // console.log(info)
                        })
                        mtChart.refresh();
                    },
                    error: function (xhr, status, error) {
                        console.log("Result: " + status + " " + error + " " + xhr.status + " " + xhr.statusText)
                    }
                });
            });
          }
    }
}
class SelectBoxItem {
    constructor(dashboardControl) {
        ResourceManager.registerIcon(svgIcon);
        this.name = "SelectBoxItemViewer";
		this.metaData = SelectBoxMetaData;
    }

    createViewerItem(model, $element, content) {
        return new SelectBoxItemViewer(model, $element, content);
    }
}

export default SelectBoxItem;