import { CustomItemViewer } from 'devexpress-dashboard/common'
import { CustomItem } from 'devexpress-dashboard/model'
import { FormItemTemplates } from 'devexpress-dashboard/designer'
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
// import $ from 'jquery';
 // import React, { useRef, useEffect, useState } from 'react';
// import GisMap from './GisMap';
mapboxgl.accessToken = 'pk.eyJ1IjoiaGFkaWFoLWhhbWFkIiwiYSI6ImNsNTB3eWQ5MTAwMzIzY3J5aXBkYTdnajQifQ.2qTqWE-rJd-A7RxzWqfFzQ';

const GIS_MAP_EXTENSION_NAME = 'OnlineMap';




const svgIcon = `<svg version="1.1" id="` + GIS_MAP_EXTENSION_NAME + `" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
            viewBox="0 0 24 24" style="enable-background:new 0 0 24 24;" xml:space="preserve">
        <path class="dx_darkgray" d="M12,1C8.1,1,5,4.1,5,8c0,3.9,3,10,7,15c4-5,7-11.1,7-15C19,4.1,15.9,1,12,1z M12,12c-2.2,0-4-1.8-4-4
            c0-2.2,1.8-4,4-4s4,1.8,4,4C16,10.2,14.2,12,12,12z"/>
        <circle class="dx_red" cx="12" cy="8" r="2"/>
        </svg>`;

const onlineMapMetadata = {
    bindings:[{
        propertyName: 'Latitude',
        dataItemType: 'Dimension',
        array: false,
        enableInteractivity: true,
        displayName: 'Latitude',
        emptyPlaceholder: 'Set Latitude',
        selectedPlaceholder: 'Configure Latitude',
        constraints: {
            allowedTypes: ['Double']
        }
    },  {
        propertyName: 'Longitude',
        dataItemType: 'Dimension',
        array: false,
        enableInteractivity: true,
        displayName: 'Longitude',
        emptyPlaceholder: 'Set Longitude',
        selectedPlaceholder: 'Configure Longitude',
        constraints: {
            allowedTypes: ['Double']
        
    }},
        {
        propertyName: 'dimensionValue',
        dataItemType: 'Dimension',
        displayName: 'Argument',
        enableColoring: true,
        array: true,
        emptyPlaceholder: 'Set Argument',
        selectedPlaceholder: 'Configure Argument'

    }],
    customProperties: [{
        ownerType: CustomItem,
        propertyName: 'Provider',
        valueType: 'string',
        defaultValue: 'Gis',
     }//,{
    //     ownerType: CustomItem,
    //     propertyName: 'Type',
    //     valueType: 'string',
    //     defaultValue: 'RoadMap',
    // },{
    //     ownerType: CustomItem,
    //     propertyName: 'DisplayMode',
    //     valueType: 'string',
    //     defaultValue: 'Markers',
    // }
],
    optionsPanelSections: [{
        title: 'Custom Options',
        items: [{
            dataField: 'Provider',
            template: FormItemTemplates.buttonGroup,
            editorOptions: {
                 items: [{ text: 'GIS' }/*, { text: 'Bing' }*/]
            },
        } /*,{
            dataField: 'Type',
            template: FormItemTemplates.buttonGroup,
            editorOptions: {
                items: [{ text: 'RoadMap' }, { text: 'Satellite' }, { text: 'Hybrid' }]
            },
        },{
            dataField: 'DisplayMode',
            template: FormItemTemplates.buttonGroup,
            editorOptions: {
                keyExpr: 'value',
                items: [{
                    value: 'Markers',
                    text: 'Markers'
                }, {
                    value: 'Routes',
                    text: 'Routes'
                }, {
                    value: 'MarkersAndRoutes',
                    text: 'All'
                }],
            },
        }*/ ]
    }],
    interactivity: {
        filter: true,
        drillDown: false
    },

    icon: GIS_MAP_EXTENSION_NAME,
    title: 'Gis Map',
    index: 1
};

class OnlineMapItemViewer extends CustomItemViewer {

    constructor(model, $container, options) {  
        super(model, $container, options);

        this.mapViewer = null;
        this.model = model;
        // Viewport settings
        this.state = {
            longitude: 0.1276,
            latitude: 51.5072,
            // longitude: -151.5129, 
            // latitude: 63.1016,
            zoom: 4
            };

  
    }    
    
    _getDataSource(){
        let data = [];
        if (this.getBindingValue('dimensionValue').length > 0) {
         var _this = this;
            this.iterateData(function (dataRow) {
                //console.log(dataRow);
                var dataItem = {
                    long: dataRow.getValue('Longitude') || "",
                    lat:  dataRow.getValue('Latitude') || "",
                    color: dataRow.getColor()[0],
                    arg : dataRow.getValue('dimensionValue'),
                    argName: _this.getBindingValue('dimensionValue')[0].displayName(),
                    clientDataRow: dataRow
                };

                // var measureValues = dataRow.getValue('measureValue');
                //     console.log(measureValues);
                // for (var i = 0; i < measureValues.length; i++) {
                //     dataItem["measureValue" + i] = measureValues[i];
                // }

                data.push(dataItem);
            });
        }

        //console.log(data);

        return data;
    }

    _onClick(row) {
        this.setMasterFilter(row);
        this._updateSelection();
    }

    _updateSelection() {
        // let markers = this.mapViewer.option('markers');
        // markers.forEach(marker => {
        //     marker.iconSrc = this.isSelected(marker.tag) ? "https://js.devexpress.com/Demos/RealtorApp/images/map-marker.png" : null;
        // });
        // this.mapViewer.option('autoAdjust', false);
        // this.mapViewer.option('markers', markers);
    }

    // setSize(width, height) {
    //     super.setSize(width, height);
    //     let contentWidth = this.contentWidth(),
    //         contentHeight = this.contentHeight();
    //     this.mapViewer.option('width', contentWidth);
    //     this.mapViewer.option('height', contentHeight);
    // }
    setSelection(values) {
        super.setSelection(values);
        this._updateSelection();
    }
    
    clearSelection() {
        super.clearSelection();
        this._updateSelection();
    }

    renderContent(element, changeExisting) {
      
        //this.mapViewer = new dxMap(element, options);


        let options = {
            provider: this.getPropertyValue('Provider').toLowerCase(),
            controls: true,
            width: this.contentWidth(),
            height: this.contentHeight(),
        };

        if (changeExisting && this.mapViewer) {
                //this.mapViewer.option(options);
        }
         else {
                const { longitude, latitude, zoom } = this.state;
                this.mapViewer = new mapboxgl.Map({
                    container: element,
                    style: 'mapbox://styles/mapbox/light-v10',
                    center: [longitude, latitude],
                    zoom: zoom
                    });

                this.mapViewer.on('load', () => {

                    let data = this._getDataSource();
                   // console.log(data);
                    let paramColorsArr = [];
                    var geoJsonData = {
                            "type": "FeatureCollection",
                            "crs": { "type": "name", "properties": { "name": "urn:ogc:def:crs:OGC:1.3:CRS84" } },
                            "features": []
                    };
                  for(var i = 0 ; i< data.length; i++){
                   var feature =  { "type": "Feature", "properties": {  [data[i].argName] : data[i].arg[0]}, "geometry": { "type": "Point", "coordinates": [ data[i].long[0],  data[i].lat[0], 0.0 ] } };
                   geoJsonData.features.push(feature);
                   paramColorsArr.push(data[i].arg[0], data[i].color);
                  }
                  var distinctParamColorsArr = [...new Set(paramColorsArr)];
                   // console.log(distinctParamColorsArr);
                    this.mapViewer.addSource('data', {
                        type: 'geojson',
                        // Use a URL for the value for the `data` property.
                         data:  geoJsonData
                        });
                         
                        this.mapViewer.addLayer({
                        'id': 'point-layer',
                        'type': 'circle',
                        'source': 'data',
                        'paint': {
                        'circle-radius': 4,
                        'circle-stroke-width': 0,
                        'circle-color': ['match', ['string', ['get', 't311']], ...distinctParamColorsArr, '#AAAAAA']
                        // 'circle-color': [
                        //     'match',
                        //     ['get', 't311'],
                        //     'ms10000',
                        //     '#da5859',
                        //     'ms15000',
                        //     '#fbc987',
                        //     'ms20000',
                        //     '#e97c82',
                        //     'ms3000',
                        //     '#a5d7d0',
                        //     'ms5000',
                        //     '#a5bdd7',
                        //     /* other */ '#ccc'
                        //     ]
                        }
                        });
                      });

                      this.mapViewer.addControl(new mapboxgl.FullscreenControl());
                      this.mapViewer.addControl(new mapboxgl.NavigationControl(), 'top-right');
                 
            }
       
    }
}

class OnlineMapItem {
    constructor(dashboardControl) {
        dashboardControl.registerIcon(svgIcon);    
        this.name = GIS_MAP_EXTENSION_NAME;
        this.metaData = onlineMapMetadata;
    }

    createViewerItem(model, $element, content) {
        return new OnlineMapItemViewer(model, $element, content);
    }
}

export default OnlineMapItem;