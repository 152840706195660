import $ from 'jquery';
import { Dashboard, CustomItem } from 'devexpress-dashboard/model'
import { CustomItemViewer, ResourceManager } from 'devexpress-dashboard/common'
import { FormItemTemplates } from 'devexpress-dashboard/designer'
import dxChart from 'devextreme/viz/chart'
import CustomStore from "devextreme/data/custom_store";
import './global';


// var url = "https://insight2-server.azurewebsites.net/";
// //var url = "https://localhost:44361/";
var url = window.link;
var svgIcon = '<svg id="lineChartItemIcon" viewBox="0 0 24 24"><path stroke="#ffffff" fill="#f442ae" d="M12 2 L2 22 L22 22 Z" /></svg>';
var LineChartItemMetaData = {
	// bindings: [{
	// 	propertyName: 'measureValue',
	// 	dataItemType: 'Measure',
	// 	displayName: 'Value'
	// }, {
	// 	propertyName: 'dimensionValue',
	// 	dataItemType: 'Dimension',
	// 	displayName: 'Argument',
	// 	enableColoring: true,
	// 	enableInteractivity: true
	// }],
	interactivity: {
		filter: true
	},
	customProperties: [
    {
        ownerType: CustomItem,
        propertyName: 'datasourceProperty',
        valueType: 'string',
        defaultValue: 'LteMeasurement'
    },
    {
        ownerType: CustomItem,
        propertyName: 'datasourcekeyprop',
        // defaultValue: false,
        valueType: 'string'
    },
    {
        ownerType: CustomItem,
        propertyName: 'chartId',
        valueType: 'string',
       // defaultValue: 'LteMeasurement'
    },
    ],
	optionsPanelSections: [{
		title: 'Custom options',
		items: [
        {
            dataField: 'datasourceProperty',
            label: {
                text: "DataSource Name"
            },
            editorType: "dxTextBox",
            editorOptions: {
                placeholder: "Enter datasource name"
            }
        },
        {
            dataField: 'datasourcekeyprop',
            editorType: "dxTextBox",
            editorOptions: {
                placeholder: "Enter key for Datasource"
            }
        },
        {
            dataField: 'chartId',
            editorType: "dxTextBox",
            editorOptions: {
                placeholder: "Enter chart id"
            }
        },
    ]
	},

],
	icon: 'lineChartItemIcon',
	title: 'Line Chart'
};

function isNotEmpty(value) {
    return value !== undefined && value !== null && value !== "";
}

class LineChartItemViewer extends CustomItemViewer {
	constructor(model, $container, options) {		
        super(model, $container, options);
        this.dxChartWidget = null;
        this.dxChartWidgetSettings = undefined;
	}

    _getDataSource() {
        // var clientData = [];
        // if (this.getBindingValue('measureValue').length > 0) {
        //     this.iterateData(function (dataRow) {
        //         clientData.push({
        //             measureValue: dataRow.getValue('measureValue')[0],
        //             dimensionValue: dataRow.getValue('dimensionValue')[0] || '',
        //             dimensionDisplayText: dataRow.getDisplayText('dimensionValue')[0],
        //             measureDisplayText: dataRow.getDisplayText('measureValue')[0],
        //             dimensionColor: dataRow.getColor('dimensionValue')[0],
        //             clientDataRow: dataRow
        //         });
        //     });
        // }
        // return clientData;

        var _this = this;
        var dsName = this.getPropertyValue('datasourceProperty');
       // var extraKey = this.getPropertyValue('ExtraKey');
        var keys = [];
        keys = this.getPropertyValue('datasourcekeyprop');
        var customDataSource = new CustomStore({
            key: keys,
            load(loadOptions) {
                return _this.loadTable(loadOptions, dsName);
            }
        });
        return customDataSource;
    };


    loadTable(loadOptions, dsName) {
        var d = $.Deferred();
        // var urlParam = window.location.search;
        // urlParam = urlParam.split('%22');
        // // console.log(urlParam);
        // var moLogFile = urlParam[5];
        // var mtLogFile = urlParam[9];
        // var timestampValue = urlParam[13];
        // if(timestampValue !== undefined){
        //     timestampValue =  timestampValue.replaceAll('%3A', ':');
        //     timestampValue = timestampValue.replace('T', ' ');
        // }

        var urlParam = window.location.search;
        urlParam = urlParam.split('&');
       //  console.log(urlParam);
        var moLogFile = urlParam[3].split('=');
        moLogFile= moLogFile[1];
        var mtLogFile = urlParam[4].split('=');
        mtLogFile= mtLogFile[1];
        var timestampValue = urlParam[2].split('=');
        timestampValue = timestampValue[1];
        if (timestampValue !== undefined) {
            timestampValue = timestampValue.replace('T', ' ');
        }


       if(this.getPropertyValue('datasourceProperty') == 'LteMeasurementMO') {
        var myuserData= {
            MOlogfile: moLogFile,
            Timestamp:timestampValue
        };
        // console.log('myuserData', myuserData)
        loadOptions.userData = myuserData;
    
       }

       if(this.getPropertyValue('datasourceProperty') == 'LteMeasurementMT') {
            var myuserData= {
                MTlogfile: mtLogFile,
                Timestamp:timestampValue
            };
            // console.log('myuserData', myuserData)
            loadOptions.userData = myuserData;
        
        }
    
    var params = {};
    [
        "filter",
        "group",
        "groupSummary",
        "parentIds",
        "requireGroupCount",
        "requireTotalCount",
        "searchExpr",
        "searchOperation",
        "searchValue",
        "select",
        "sort",
        "skip",
        "take",
        "totalSummary",
        "userData"
    ].forEach(function (i) {
        if (i in loadOptions && isNotEmpty(loadOptions[i])) {
            params[i] = JSON.stringify(loadOptions[i]);
        }
    });
       //console.log(url+"api/trackers/" + dsName);
        $.getJSON(url+"api/trackers/" + dsName, params).done(function (response) {
            d.resolve(response.data, {
                totalCount: response.totalCount,
                summary: response.summary,
                groupCount: response.groupCount

            });
        }).fail(function () {
            throw "Data loading error"
        });

        return d.promise();
    // }
  
  
}


    _getDxChartWidgetSettings() {
       // console.log('mometrics', window.$mometrics)
        var _this = this;
        return {
           // dataSource:  window.$mometrics,
            elementAttr: {
                id: this.getPropertyValue('chartId')
            },
            legend :{
                verticalAlignment: 'top',
      
            },
           
            commonSeriesSettings: {
                argumentField: 'timestamp',
                type: 'line',
                point: {
                    size: 5,
                    selectionStyle: {
                        border: {
                            width: 2
                        }
                    },
                    selectionMode: 'allArgumentPoints'//'allArgumentPoints' // or 'onlyPoint' | 'allSeriesPoints' | 'none'
                },
              },
              tooltip: {
                zIndex: 9999,
                enabled: true,
                shared: true,
              },
             
              crosshair: {
                enabled: true,
                horizontalLine: {
                  visible: false,
                },
                label: {
                  visible: true,
                  format: {
                    type: 'fixedPoint',
                    precision: 2,
                  },
                },
                
              },
            //   adaptiveLayout: {
            //     width: 400,
            //     },
              onPointClick:  function(e){
                var chart = e.component;
                var MTgrid = $('#MT').dxDataGrid('instance');
                var MOgrid = $('#MO').dxDataGrid('instance');
                var MOchart = $('#MOchart').dxChart('instance');
                var MTchart = $('#MTchart').dxChart('instance');
                const point = e.target;
                if (point.isSelected()) {
                  point.clearSelection();
                } else {
                  point.select();
                  var moGridData = window.$modata;
                  var mtGridData = window.$mtdata;
                //   var moMetricsData = window.$mometrics;
                //   var mtMetricsData = window.$mtmetrics;
                  _this.selectGrids(moGridData,MOgrid,point.argument);
                  _this.selectGrids(mtGridData,MTgrid,point.argument);
                  if(chart._$element[0].id === 'MOchart'){
                    var mtdatasource = MTchart._dataSource._items;
                   // console.log('mtdatasource', mtdatasource)
                    var pointMT = mtdatasource.find(x => new Date(x.timestamp).getTime() - new Date(point.argument).getTime() >= 0)
                    _this.selectPoint(MTchart, pointMT);
                  }else if(chart._$element[0].id === 'MTchart'){
                    var modatasource = MOchart._dataSource._items;
                   // console.log('modatasource', modatasource)
                    var pointMO = modatasource.find(x => new Date(x.timestamp).getTime() - new Date(point.argument).getTime() >= 0)
                    _this.selectPoint(MOchart, pointMO);
                  }
                
                }
             
                
              }
        };
    }

    selectGrids(dataGrid,gridInstance,argument ){
        var row = dataGrid.find(x => new Date(x.timestamp).getTime() - new Date(argument).getTime() >= 0);
        var rowIndex = dataGrid.indexOf(row);
        var current = gridInstance.option('focusedRowIndex');
        if (current !== rowIndex) {
            gridInstance.option('focusedRowIndex', rowIndex);
        }
    }

    selectPoint(chart, point){
        //console.log("point",point)
        var key = Object.keys(point)[0];
        if(chart.getSeriesByName(key).isSelected()){
            chart.getSeriesByName(key).clearSelection();
           }else{
            // console.log(chart.getSeriesByName(key));
            // console.log("argggg", point.timestamp);
            chart.getSeriesByName(key).getPointsByArg(point.timestamp)[0].select();
           }
    }

    setSelection() {
        var _this = this;
        this.dxChartWidget.getAllItems().forEach(function (item) {
            item.select(_this.isSelected(item.data.clientDataRow));
        });
    }

    clearSelection() {
        this.dxChartWidget.clearSelection();
    }

    setSize(width, height) {
        Object.getPrototypeOf(LineChartItemViewer.prototype).setSize.call(this, width, height);
        this.dxChartWidget.render();
    }

    renderContent(element, changeExisting) {

        if (!changeExisting) {

            while (element.firstChild)
                element.removeChild(element.firstChild);

            var div = document.createElement('div');
            div.style.width = "100%";
            div.style.height = "100%";
            element[0].appendChild(div);
            this.dxChartWidget = new dxChart(div, this._getDxChartWidgetSettings());
        } else {
            this.dxChartWidget.option(this._getDxChartWidgetSettings());
        }
    }
}
class LineChartItem {
    constructor(dashboardControl) {
        ResourceManager.registerIcon(svgIcon);
        this.name = "LineChartCustomItem";
		this.metaData = LineChartItemMetaData;
    }

    createViewerItem(model, $element, content) {
        return new LineChartItemViewer(model, $element, content);
    }
}

export default LineChartItem;