import $ from 'jquery';
import React from 'react';
import ReactDOM from "react-dom/client";
import {Button} from 'devextreme-react/button';
import {ProgressBar} from 'devextreme-react/progress-bar';
import './loadDrillDownData.css'
import DashboardControl from 'devexpress-dashboard-react';
import KeplerItem from '../keplerItem'
// import LoginForm from './components/LoginForm';
import * as Model from 'devexpress-dashboard/model';
import ButtonItem from '../ButtonCustomItem';
import CustomGrid from '../CustomGrid';
import LineChartItem from '../CustomChart';
import customSelectBox from '../customSelectBox';
import {json} from 'react-router-dom';
import {ComboBoxItem} from 'devexpress-dashboard/model';
import '../global';

// var url = "https://insight2-server.azurewebsites.net/";
// //var url = "https://localhost:44361/";
var url = window.link;
var comboBoxIdProperty = {
    ownerType: Model.ComboBoxItem,
    propertyName: 'comboBoxId',
    // defaultValue: false,
    valueType: 'string'
};

var dashboardPanel;
var dashboardControl;
var showpanel = true;
const maxValue = 200;


// function formatTime(value) {
// return `00:00:${(`0${value}`).slice(-2)}`;
// }

function statusFormat(ratio) {

    return `Loading: ${
        parseInt(ratio * 100)
    }%`;
}

function onBeforeRender(e) {
    dashboardControl = e.component;
    dashboardControl.registerExtension(new KeplerItem(dashboardControl));
    dashboardControl.registerExtension(new ButtonItem(dashboardControl));
    dashboardControl.registerExtension(new CustomGrid(dashboardControl));
    dashboardControl.registerExtension(new LineChartItem(dashboardControl));
    dashboardControl.registerExtension(new customSelectBox(dashboardControl));

    var UrlStateExtension = dashboardControl.findExtension('url-state');
    UrlStateExtension._options.includeDashboardIdToUrl = false;
    UrlStateExtension._options.includeDashboardStateToUrl = false;

    var viewerApiExtension = dashboardControl.findExtension('viewer-api');
    if (viewerApiExtension) {
        viewerApiExtension.on('itemWidgetOptionsPrepared', onItemWidgetOptionsPrepared);
        viewerApiExtension.on('itemCaptionToolbarUpdated', onItemCaptionToolbarUpdated);
        viewerApiExtension.on('dashboardTitleToolbarUpdated', onDashboardTitleToolbarUpdated);

    }

}

function onDashboardTitleToolbarUpdated(e) {
    if (showpanel) {
        e.options.navigationItems.push({
            type: "button",
            icon: "dx-dashboard-designer-menu-icon",
            hint: "Menu",
            click: function (element) {
                if (dashboardPanel.visible() === false && dashboardControl.isDesignMode() === false) {
                    dashboardPanel.showPanelAsync({}).done(function (e) {
                        dashboardControl.surfaceLeft(e.surfaceLeft);
                    });
                } else if (dashboardPanel.visible() === true && dashboardControl.isDesignMode() === false) {
                    dashboardPanel.hidePanelAsync({}).done(function (e) {

                        dashboardControl.surfaceLeft(e.surfaceLeft);
                    });
                }
            }
        });
    }

}

function onItemCaptionToolbarUpdated(e, s) { // remove maximize
    if (e.itemName === "customItemDashboardItem1" || e.itemName === "customItemDashboardItem2" || e.itemName === "customItemDashboardItem3" || e.itemName === "customItemDashboardItem4" || e.itemName === "customItemDashboardItem5" || e.itemName === "customItemDashboardItem6" || e.itemName === "customItemDashboardItem7" || e.itemName === "customItemDashboardItem8" ||e.itemName === "customItemDashboardItem9" ) {

        var target = e.options.actionItems.find(function (element) {
            return element.name === "maximize-down-item";
        });

        var index = e.options.actionItems.indexOf(target);
        if (index > -1) {
            e.options.actionItems.splice(index, 1);
        }
    }

}

function onItemWidgetOptionsPrepared(args) {

    if (args.dashboardItem instanceof ComboBoxItem) {
        // console.log("data items: ",args.dashboardItem.dataItems())
        // console.log("showAllValue: ",args.dashboardItem.showAllValue())
        //Model.registerCustomProperty(comboBoxIdProperty);
        var comboBoxId = args.dashboardItem.customProperties.getValue(comboBoxIdProperty.propertyName);
         console.log(comboBoxId);
        if (comboBoxId === 'MO') {
            args.options.onSelectionChanged = function (e) {
                console.log(e);
                  var moChart = $('#MOchart').dxChart('instance');
               //   console.log(moChart);
                  var index = e.value;
                  var ds = e.component.getDataSource();
                  // console.log(ds);
               //   var templateName = ds._items[index].value[0];
               var templateName = e.selectedItem.text;
                  $.ajax({
                      type: "GET",
                      url: url + "api/trackers/templatebykey/" + templateName,
                      dataType: "json",
                      success: function (result, status, xhr) { // console.log(result);
                          var dataArray = result.data;
    
                        //   console.log("dataArray",dataArray);
                          var panes = [];
                          var series = [];
                          var valueField = 'value';
                          for (let i = 0; i < dataArray.length; i++) {
                            let obj = panes.find(panes => panes.name === dataArray[i].chartName);
    
                            if (obj === undefined) {
                                panes.push({name: dataArray[i].chartName})
                            }
                            series.push({pane: dataArray[i].chartName, valueField: dataArray[i].parameter, name: dataArray[i].parameter})
                          }
    
                          var MOdataSource = [];
    
                          // for(let i = 0; i< window.$mometrics.length; i++){
                          //   let found = dataArray.find( item => item['parameter'] === window.$mometrics[i].name );
                          //   if(found !== undefined){
                          //     MOdataSource.push(window.$mometrics[i]);
                          //   }
                          // }
    
                          for(let i = 0; i< window.$mometrics.length; i++){
                            let found = dataArray.find( item => item['parameter'] === window.$mometrics[i].name );
                            if(found !== undefined){
                             // console.log(found,window.$mometrics[i]);
                             var myvalue={
                             [window.$mometrics[i].name]: window.$mometrics[i].value,
                             timestamp: window.$mometrics[i].timestamp
                            };
                              MOdataSource.push(myvalue);
                            }
                          }
    
                        //   console.log('mo panes', panes);
                        //   console.log('mo series', series);
                        //   console.log("MOdataSource",MOdataSource);
    
                          moChart.option('dataSource', MOdataSource);
                          moChart.option('panes', panes);
                          moChart.option('series', series);
                          moChart.option('tooltip.customizeTooltip', function (info) { // console.log(info)
                          })
                         moChart.refresh();
                      },
                      error: function (xhr, status, error) {
                          console.log("Result: " + status + " " + error + " " + xhr.status + " " + xhr.statusText)
                      }
                  });
    
              }
        } else if (comboBoxId === 'MT') {
            args.options.onSelectionChanged = function (e) {

                var mtChart = $('#MTchart').dxChart('instance');
                var templateName = e.selectedItem.text;
                // var index = e.value;
                // var ds = e.component.getDataSource();
                // // console.log(ds);
                // var templateName = ds._items[index].value[0];
                $.ajax({
                    type: "GET",
                    url: url + "api/trackers/templatebykey/" + templateName,
                    dataType: "json",
                    success: function (result, status, xhr) { // console.log(result);
                        var dataArray = result.data;
                        // console.log(dataArray);
                        var panes = [];
                        var series = [];
                        var valueField = 'value';
                        for (var i = 0; i < dataArray.length; i++) {
                            let obj = panes.find(panes => panes.name === dataArray[i].chartName);

                            if (obj === undefined) {
                                panes.push({name: dataArray[i].chartName})
                            }
                           // series.push({pane: dataArray[i].chartName, valueField: valueField, name: dataArray[i].parameter})
                           series.push({pane: dataArray[i].chartName, valueField: dataArray[i].parameter, name: dataArray[i].parameter})
                        }

                        var MTdataSource = [];

                        // for (let i = 0; i < window.$mtmetrics.length; i++) {
                        //     let found = dataArray.find(item => item['parameter'] === window.$mtmetrics[i].name);
                        //     if (found !== undefined) {
                        //         MTdataSource.push(window.$mtmetrics[i]);
                        //     }
                        // }

                        for(let i = 0; i< window.$mtmetrics.length; i++){
                            let found = dataArray.find( item => item['parameter'] === window.$mtmetrics[i].name );
                            if(found !== undefined){
                              // console.log(found,window.$mometrics[i]);
                              var myvalue={
                              [window.$mtmetrics[i].name]: window.$mtmetrics[i].value,
                              timestamp: window.$mtmetrics[i].timestamp
                             };
                               MTdataSource.push(myvalue);
                             }                       
                          }

                        // console.log('mo panes', panes);
                        // console.log('mo series', series);
                        // console.log("MTdataSource",MTdataSource);

                        mtChart.option('dataSource', MTdataSource);
                        mtChart.option('panes', panes);
                        mtChart.option('series', series);
                        mtChart.option('tooltip.customizeTooltip', function (info) { // console.log(info)
                        })
                        mtChart.refresh();
                    },
                    error: function (xhr, status, error) {
                        console.log("Result: " + status + " " + error + " " + xhr.status + " " + xhr.statusText)
                    }
                });

            }
        }

    }


}

let intervalId;

class LoadDrillDown extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            seconds: maxValue,
            inProgress: false,
            isActive: true
        };

        // this.MTstate = {
        // seconds: maxValue,
        // inProgress: false,
        // isActive: true
        // };

        this.gridData = this.props.gridData;
        // console.log(" this.gridData",  this.gridData);
        this.moData = {
            //FailureTimestamp: this.gridData.failureTimeStamp.replace('T', ' '),
            StartTimestamp: this.gridData.startTimestamp.replace('T', ' '),
            EndTimestamp: this.gridData.endTimestamp.replace('T', ' '),
            MOlogfile: this.gridData.MoDriveTestLogFile,
            TimeBefore: this.gridData.timeBefore,
            TimeAfter: this.gridData.timeAfter

        }

        // console.log(" this.modata",  this.moData);

        this.modatastr = JSON.stringify(this.moData);

        this.mtData = {
           // FailureTimestamp: this.gridData.failureTimeStamp.replace('T', ' '),
            StartTimestamp: this.gridData.startTimestamp.replace('T', ' '),
            EndTimestamp: this.gridData.endTimestamp.replace('T', ' '),
            MTlogfile: this.gridData.MtDriveTestLogFile,
            TimeBefore: this.gridData.timeBefore,
            TimeAfter: this.gridData.timeAfter
        }
        // console.log(" this.mtdata",  this.mtData);
        this.mtdatastr = JSON.stringify(this.mtData);

        // global variables
        window.$modata = [];
        window.$mtdata = [];

        this.timer = this.timer.bind(this);
    }

    render() { // console.log(this.state);

        var _this = this;
        if (this.state.isActive) {
        return (

          <div>

<div className="welcome"
       style={
           {
               position: 'absolute',
               top: '0px',
               left: '0px',
               right: '0px',
               bottom: '0px',
               height: '100%'
           }
         }> 
    
      <DashboardControl style={
               {height: '100%'}
           }
           id='webDashboard'
            endpoint= {window.endpoint}
           onBeforeRender={onBeforeRender}
           defaultWorkingMode="Viewer"
           initialDashboardId="drilldownNew_"></DashboardControl>
      </div>

                <div className="form" id='progressBar'>
                    <ProgressBar id="progress-bar-MO"
                        className={
                            this.state.seconds === 0 ? 'complete' : ''
                        }
                        width="90%"
                        min={0}
                        max={maxValue}
                        statusFormat={statusFormat}
                        value={
                            maxValue - this.state.seconds
                        }
                        onInitialized= {
                                  function (e) {
                                    const state = {
                                        inProgress: !_this.state.inProgress
                                    };
                                        if (_this.state.seconds === 0) {
                                            state.seconds = maxValue;
                                        }
                                         intervalId = setInterval(() => _this.timer(), 300);
                                        _this.setState(state);
                                    // var ajaxTime = new Date().getTime();
                                    var ajaxMessagesDone = false;
                                    var ajaxMeasurementsDone = false;
                                    var ajaxMTMessagesDone = false;
                                    var ajaxMTMeasurementsDone = false;
                                    
                                    // $.ajax({
                                    //     url: url + 'api/trackers/l3messageo2MO?userData=' + _this.modatastr,
                                    //     type: 'GET',
                                    //     dataType: 'json',
                                    //     // data: JSON.stringify(_this.moData),
                                    //     success: function (data, textStatus, xhr) {
                                            
                                    //         window.$modata = data;
                                    //         ajaxMessagesDone = true;
                                    //         _this.rebindMOgridDataSource(window.$modata)
                                    //   if(ajaxMessagesDone && ajaxMeasurementsDone & ajaxMTMessagesDone & ajaxMTMeasurementsDone){
                                    //    // console.log('moL3Messages')
                                    //    _this.loadDashbord();
                                       
                            
                                    //   }
                                    //     },
                                    //     error: function (xhr, textStatus, errorThrown) {
                                    //         console.log('Error in Operation');
                                    //     }
                                    // })
                            
                                    $.ajax({
                                        url: url + 'api/trackers/metricsmo?userData=' + _this.modatastr,
                                        type: 'GET',
                                        dataType: 'json',
                                        // data: JSON.stringify(_this.moData),
                                        success: function (data, textStatus, xhr) {
                                            console.log("data",data);

                                            window.$mometrics = data.item2;
                                            var state = '{"Items":{"comboBoxDashboardItem1":{"MasterFilterValues":[["LTE"]]}}}';
                                            dashboardControl.setDashboardState(state);
                                            ajaxMeasurementsDone = true;
                                            _this.rebindMOchartDataSource(window.$mometrics);

                                            window.$modata = data.item1;
                                            ajaxMessagesDone = true;   
                                            _this.rebindMOgridDataSource(window.$modata);  
                                        
                                           
                                            if(ajaxMessagesDone && ajaxMeasurementsDone & ajaxMTMessagesDone & ajaxMTMeasurementsDone){
                                              //console.log('moMetricsDone')
                                              _this.loadDashboard();
                                            }
                                        },
                                        error: function (xhr, textStatus, errorThrown) {
                                            console.log('xhr' + xhr);
                                            console.log('textStatus' + textStatus);
                                            console.log('Error in Operation' + errorThrown);
                                           // _this.loadDashboard();
                                        }
                                    })
                            
                                    // $.ajax({
                                    //     url: url + 'api/trackers/metricsmo?userData=' + _this.modatastr,
                                    //     type: 'GET',
                                    //     dataType: 'json',
                                    //     // data: JSON.stringify(_this.moData),
                                    //     success: function (data, textStatus, xhr) {
                            
                                    //         window.$mometrics = data;
                                    //         var state = '{"Items":{"comboBoxDashboardItem1":{"MasterFilterValues":[["LTE"]]}}}';
                                    //         dashboardControl.setDashboardState(state);
                                    //         ajaxMeasurementsDone = true;
                                    //         _this.rebindMOchartDataSource(window.$mometrics)
                                    //         if(ajaxMessagesDone && ajaxMeasurementsDone & ajaxMTMessagesDone & ajaxMTMeasurementsDone){
                                    //           //console.log('moMetricsDone')
                                    //           _this.loadDashbord();
                                        
                                    //         }
                                    //     },
                                    //     error: function (xhr, textStatus, errorThrown) {
                                    //         console.log('Error in Operation');
                                    //     }
                                    // })
                            
                                    
                                //     $.ajax({
                                //       url: url + 'api/trackers/l3messageo2MT?userData='+ _this.mtdatastr,  
                                //       type: 'GET',  
                                //       dataType: 'json',
                                //       success: function (data, textStatus, xhr) {  
                                       
                                //        window.$mtdata = data;
                            
                                //           ajaxMTMessagesDone = true;
                                //           _this.rebindMTgridDataSource(window.$mtdata)
                                //           if(ajaxMessagesDone && ajaxMeasurementsDone & ajaxMTMessagesDone & ajaxMTMeasurementsDone){
                                //             //console.log('mtL3MessagesDone')
                                //             // _this.MTstate.seconds = 0;
                                //             _this.loadDashbord();
                                //           } 
                                //       },  
                                //       error: function (xhr, textStatus, errorThrown) {  
                                //           console.log('Error in Operation');  
                                //       }  
                                //   })
                            
                                //   $.ajax({
                                //     url: url + 'api/trackers/metricsmt?userData=' + _this.mtdatastr,
                                //     type: 'GET',
                                //     dataType: 'json',
                                //     // data: JSON.stringify(_this.moData),
                                //     success: function (data, textStatus, xhr) {
                            
                                //         window.$mtmetrics = data;
                                //         var state = '{"Items":{"comboBoxDashboardItem2":{"MasterFilterValues":[["LTE"]]}}}';
                                //         dashboardControl.setDashboardState(state);
                                //         ajaxMTMeasurementsDone = true;
                                //         _this.rebindMTchartDataSource(window.$mtmetrics);
                                //         if(ajaxMessagesDone && ajaxMeasurementsDone & ajaxMTMessagesDone & ajaxMTMeasurementsDone){
                                //           // console.log('mtMetricsDone')
                                //           _this.loadDashbord();
                                 
                                //         }
                                //     },
                                //     error: function (xhr, textStatus, errorThrown) {
                                //         console.log('Error in Operation');
                                //     }
                                // })

                                $.ajax({
                                    url: url + 'api/trackers/metricsmt?userData=' + _this.mtdatastr,
                                    type: 'GET',
                                    dataType: 'json',
                                    // data: JSON.stringify(_this.moData),
                                    success: function (data, textStatus, xhr) {
                                        //console.log(data);
                                        window.$mtmetrics = data.item2;
                                        var state = '{"Items":{"comboBoxDashboardItem2":{"MasterFilterValues":[["LTE"]]}}}';
                                        dashboardControl.setDashboardState(state);
                                        ajaxMTMeasurementsDone = true;
                                        _this.rebindMTchartDataSource(window.$mtmetrics);

                                        window.$mtdata = data.item1; 
                                       ajaxMTMessagesDone = true;
                                        _this.rebindMTgridDataSource(window.$mtdata);

                                        if(ajaxMessagesDone && ajaxMeasurementsDone & ajaxMTMessagesDone & ajaxMTMeasurementsDone){
                                          // console.log('mtMetricsDone')
                                          _this.loadDashboard();
                                        }
                                    },
                                    error: function (xhr, textStatus, errorThrown) {
                                        console.log('xhr' + xhr);
                                        console.log('textStatus' + textStatus);
                                        console.log('Error in Operation' + errorThrown);
                                       // _this.loadDashboard();
                                    }
                                })
                            
                              

                            
                                        }   
                                      }

                                      onComplete= {function(e){
                                        $('#progressBar').hide();
                                        // console.log('mometrics on complete', window.$mometrics)
                                        //  _this.setState({
                                        //    isActive: false
                                        //  });
                                       }
                                    
                                    }
                                     />
                             
                             
                                   </div>
                             
                             </div>
                             
                                 );
                               }
                               }

    loadDashboard(){
      var _this =this;
      const state = {
        seconds: 0,
      };
        _this.setState(state);
    }



    rebindMOgridDataSource(datasource){
        var count = datasource.length;
      $('#MO').dxDataGrid('instance').option("dataSource",datasource);
     // $('#MO').dxDataGrid('instance').option("filterPanel.texts.clearFilter", ' Records');
    }

    rebindMTgridDataSource(datasource){
        var count = datasource.length;
      $('#MT').dxDataGrid('instance').option("dataSource",datasource);
     // $('#MT').dxDataGrid('instance').option("filterPanel.texts.clearFilter", ' Records');
    }

    rebindMOchartDataSource(datasource){
     var mochart = $('#MOchart').dxChart('instance');
     //console.log('mochart', mochart)
      mochart.option('datasource', datasource)
    //  mochart.refresh()
    }

    rebindMTchartDataSource(datasource){
     var mtchart = $('#MTchart').dxChart('instance');
    // console.log('mtchart', mtchart)
     mtchart.option('datasource', datasource)
    // mtchart.refresh()
    }//


    timer() {
        const state = {
            seconds: this.state.seconds - 1
        };
        if (this.state.seconds > 1) {
            this.setState(state);
        }
    }

    updateLoader(val){
        //alert(1);
        const state = {
            seconds: this.state.seconds - val
        };
        this.setState(state);
        // if (this.state.seconds > 1) {
        //     this.setState(state);
        // }
    }


    // MTtimer() {
    // const state = {
    //     seconds: this.MTstate.seconds - 1,
    // };
    // if(state.seconds>1){
    //     this.MTstate.seconds = state.seconds;
    // }


    // }


}

export default LoadDrillDown;

