import React from 'react';
import { Dashboard, CustomItem } from 'devexpress-dashboard/model'
import { CustomItemViewer, ResourceManager } from 'devexpress-dashboard/common'
import { FormItemTemplates } from 'devexpress-dashboard/designer'
import dxRangeSelector from 'devextreme/viz/range_selector'
import DashboardControl , {Extensions, ViewerApi } from 'devexpress-dashboard-react';
import $ from 'jquery';



var svgIcon = '<svg id="rangeSliderItemIcon" viewBox="0 0 24 24"><path stroke="#ffffff" fill="#f442ae" d="M12 2 L2 22 L22 22 Z" /></svg>';
var rangeSliderItemMetaData = {
	bindings: [{
		propertyName: 'dimensionValue',
		dataItemType: 'Dimension',
		displayName: 'Argument',
        constraints: {
            allowedTypes: ['DateTime']
        },
        enableInteractivity: true
	}],
	interactivity: {
        filter: true,

    },
	icon: 'rangeSliderItemIcon',
	title: 'Range Slider'
};


class RangeSliderItem {

    constructor(dashboardControl) {
        ResourceManager.registerIcon(svgIcon);
        this.name = "rangeSliderCustomItem";
		this.metaData = rangeSliderItemMetaData;
        // this.dashboardControl = dashboardControl;
        // console.log(dashboardControl)
    
    }
    createViewerItem( model, $element, content) {

        return new RangeSliderItemViewer(model, $element, content);
    }
}


class RangeSliderItemViewer extends CustomItemViewer {
	constructor(model, $container, options) {
        super(model, $container, options);
        this.dxRangeSliderWidget = null;
        this.dxRangeSliderSettings = undefined;
        this.rangeSliderOptions = options;
        // this.dashboardControl = dashboardControl;
        // console.log(dashboardControl)
	}

    _getDxRangeSliderWidgetSettings() {
     var _this = this;
      this.data = [];
        this.iterateData(function (dataRow) {

            var dataItem = {
                arg: dataRow.getValue('dimensionValue')[0] || "",
                clientDataRow: dataRow
            };
            _this.data.push(dataItem);


        });
        var length = _this.data.length -1;
        return {
                margin: {
                  top: 50,
                },
                scale: {
                  startValue: _this.data[0].arg,
                  endValue: _this.data[length].arg,
                   tickInterval: 'week',
                  minorTick: {
                    visible: false,
                  },
                },
                sliderMarker: {
                  format: 'monthAndDay',
                },
                value: [_this.data[0].arg, _this.data[length].arg],
                // onValueChanged: function (e) {
                //     var startDate = e.value[0];
                //     var endDate = e.value[1];
                //     var filterValues = [];
    
                //     _this.iterateData(function (dataRow) {
                //         var value = dataRow.getValue('dimensionValue')[0];
                        
                //         if (value > startDate && value < endDate) {
                //             filterValues.push([value]);
                //           // _this.setMasterFilter(selectedValues[length].clientDataRow);
                //          // _this.setMasterFilter(dataRow);  
                //         }
                //     });
    
                //     var viewerApiExtension = _this.dashboardControl.findExtension("viewer-api");
                //     viewerApiExtension.setMasterFilter(_this.model.componentName(), filterValues);
    
                //     /*window.setTimeout(function() {
                //         viewerApiExtension.clearMasterFilter(_this.model.componentName());
                //     }, 5000);*/
                // }
                onInitialized: function (e) {
                    
                },
                onValueChanged: function(e){
                    var selectedValues = [];

                    _this.data.forEach(element => {
                        selectedValues = $.grep(
                    _this.data,
                    (element) => element.arg >= e.value[0] && element.arg <= e.value[1],
                    );
                });
    
                    var length = selectedValues.length-1;
                    console.log(selectedValues);
                    if(length > 0 ){
                        _this.setMasterFilter(selectedValues[length].clientDataRow);
                      // _this.setMasterFilter();
                    }
                }
                
        };
    }

    setSelection(values) {
        super.setSelection(values);
    }

    clearSelection() {
        super.clearSelection();
    }

    setSize(width, height) {
        Object.getPrototypeOf(RangeSliderItemViewer.prototype).setSize.call(this, width, height);
        this.dxRangeSliderWidget.render();
    }

    renderContent(element, changeExisting) {
        if (!changeExisting) {

            // while (element.firstChild)
            //     element.removeChild(element.firstChild);

            // var div = document.createElement('div');
            // div.style.width = "100%";
            // div.style.height = "100%";
            // element.appendChild(div);
            this.dxRangeSliderWidget = new dxRangeSelector(element, this._getDxRangeSliderWidgetSettings());
        } else {
            this.dxRangeSliderWidget.option(this._getDxRangeSliderWidgetSettings());
        }
    }
}
export default RangeSliderItem;