import $ from 'jquery';
 //import ReactDOM from "react-dom";
import CustomStore from "devextreme/data/custom_store";
import {CustomItem} from 'devexpress-dashboard/model'
import {CustomItemViewer, ResourceManager} from 'devexpress-dashboard/common'
import {FormItemTemplates} from 'devexpress-dashboard/designer'
import dxDataGrid from 'devextreme/ui/data_grid';
import * as Model from 'devexpress-dashboard/model';
import dxPopup from 'devextreme/ui/popup';
import dxDashboardControl from 'devexpress-dashboard/integration';
import KeplerItem from './keplerItem';
import ButtonItem from './ButtonCustomItem';
import WebPageItem from './WebPageItem';
import dxForm from "devextreme/ui/form";
import dxButton from "devextreme/ui/button";
import dxDropDownBox from "devextreme/ui/drop_down_box";
// import dxTooltip from "devextreme/ui/tooltip";
import LineChartItem from './CustomChart';
import {ComboBoxItem} from 'devexpress-dashboard/model';
 import LoadDrillDown from "./components/loadDrillDownData";
 import dxScrollView from  "devextreme/ui/scroll_view";
import dxTreeView from 'devextreme/ui/tree_view';
import Toast from "devextreme/ui/toast";
import './global';

// var url = "https://insight2-server.azurewebsites.net/";
 var url = window.link;

var comboBoxIdProperty = {
    ownerType: Model.ComboBoxItem,
    propertyName: 'comboBoxId',
    // defaultValue: false,
    valueType: 'string'
};

var svgIcon = '<svg id="customGridItemIcon" viewBox="0 0 24 24"><path stroke="#ffffff" fill="#f442ae" d="M12 2 L2 22 L22 22 Z" /></svg>';
var CustomGridMetaData = {
    // bindings: [ {
    // propertyName: 'dimensionValue',
    // dataItemType: 'Dimension',
    // displayName: 'Dimension',
    // enableColoring: true,
    // enableInteractivity: true
    // }],
    interactivity: {
        filter: true
    },
    customProperties: [
        {
            ownerType: CustomItem,
            propertyName: 'datasourceProperty',
            valueType: 'string',
            defaultValue: 'voicetracker'
        },
        {
            ownerType: CustomItem,
            propertyName: 'Popup',
            defaultValue: false,
            valueType: 'boolean'
        },
        {
            ownerType: CustomItem,
            propertyName: 'DashboardID',
            valueType: 'string'
        },
        {
            ownerType: CustomItem,
            propertyName: 'popupWidth',
            defaultValue: '350',
            valueType: 'string'
        }, {
            ownerType: CustomItem,
            propertyName: 'popupHeight',
            defaultValue: '250',
            valueType: 'string'
        }, {
            ownerType: CustomItem,
            propertyName: 'GridId',
            // defaultValue: false,
            valueType: 'string'
        }, {
            ownerType: CustomItem,
            propertyName: 'DrillDownPopup',
            defaultValue: false,
            valueType: 'boolean'
        }, {
            ownerType: CustomItem,
            propertyName: 'DrillDownDashboardID',
            valueType: 'string'
        }, {
            ownerType: CustomItem,
            propertyName: 'DrillDownPopupWidth',
            defaultValue: '350',
            valueType: 'string'
        }, {
            ownerType: CustomItem,
            propertyName: 'DrillDownPopupHeight',
            defaultValue: '250',
            valueType: 'string'
        }, {
            ownerType: CustomItem,
            propertyName: 'EnableRowFocus',
            defaultValue: false,
            valueType: 'boolean'
        }, {
            ownerType: CustomItem,
            propertyName: 'ColumnChooser',
            defaultValue: false,
            valueType: 'boolean'
        }, {
            ownerType: CustomItem,
            propertyName: 'AddButton',
            defaultValue: false,
            valueType: 'boolean'
        }, {
            ownerType: CustomItem,
            propertyName: 'EditButton',
            defaultValue: false,
            valueType: 'boolean'
        },
        {
            ownerType: CustomItem,
            propertyName: 'DownloadButton',
            defaultValue: false,
            valueType: 'boolean'
        },{
            ownerType: CustomItem,
            propertyName: 'ExportToExcel',
            defaultValue: false,
            valueType: 'boolean'
        }, {
            ownerType: CustomItem,
            propertyName: 'Refresh',
            defaultValue: false,
            valueType: 'boolean'
        }, {
            ownerType: CustomItem,
            propertyName: 'DrillDownMenu',
            defaultValue: false,
            valueType: 'boolean'
        }, {
            ownerType: CustomItem,
            propertyName: 'GroupPanel',
            defaultValue: false,
            valueType: 'boolean'
        }, {
            ownerType: CustomItem,
            propertyName: 'CustomAddButton',
            defaultValue: false,
            valueType: 'boolean'
        },
        {
            ownerType: CustomItem,
            propertyName: ' CustomEditButton',
            defaultValue: false,
            valueType: 'boolean'
        },
        {
            ownerType: CustomItem,
            propertyName: 'ExtraKey',
            // defaultValue: false,
            valueType: 'string'
        }, {
            ownerType: CustomItem,
            propertyName: 'enableAdd',
            defaultValue: true,
            valueType: 'boolean'
        }, {
            ownerType: CustomItem,
            propertyName: 'enableEdit',
            defaultValue: true,
            valueType: 'boolean'
        }, {
            ownerType: CustomItem,
            propertyName: 'enableDelete',
            defaultValue: true,
            valueType: 'boolean'
        }, {
            ownerType: CustomItem,
            propertyName: 'colorFunction',
            defaultValue: false,
            valueType: 'boolean'
        }, {
            ownerType: CustomItem,
            propertyName: 'isDrilldownGrid',
            defaultValue: false,
            valueType: 'boolean'
        }, {
            ownerType: CustomItem,
            propertyName: 'datasourcekeyprop',
            // defaultValue: false,
            valueType: 'string'
        }, {
            ownerType: CustomItem,
            propertyName: 'enablecolumnsprop',
            defaultValue: false,
            valueType: 'boolean'
        }, {
            ownerType: CustomItem,
            propertyName: 'columnsprop',
            // defaultValue: false,
            valueType: 'string'
        }, {
            ownerType: CustomItem,
            propertyName: 'enableLookupprop',
            defaultValue: false,
            valueType: 'boolean'
        }, {
            ownerType: CustomItem,
            propertyName: 'lookupnameprop',
            // defaultValue: false,
            valueType: 'string'
        }, {
            ownerType: CustomItem,
            propertyName: 'lookupkeyprop',
            // defaultValue: false,
            valueType: 'string'
        }, {

            ownerType: CustomItem,

            propertyName: 'lookupcolumnprop',

            // defaultValue: false,

            valueType: 'string'

        }, {

            ownerType: CustomItem,

            propertyName: 'lookupdisplayprop',

            // defaultValue: false,

            valueType: 'string'

        }, {

            ownerType: CustomItem,

            propertyName: 'lookupvalueprop',

            // defaultValue: false,

            valueType: 'string'

        }, {

            ownerType: CustomItem,

            propertyName: 'columnvisibleprop',

            // defaultValue: false,

            valueType: 'string'

        },  {
            ownerType: CustomItem,
            propertyName: 'enableRowTemplate',
            defaultValue: false,
            valueType: 'boolean'
        }, {
            ownerType: CustomItem,
            propertyName: 'customizeFormItems',
            defaultValue: false,
            valueType: 'boolean'
        }, {
            ownerType: CustomItem,
            propertyName: 'formItems',
            valueType: 'string'
        }, {
            ownerType: CustomItem,
            propertyName: 'formItemsDataSource',
            valueType: 'string'
        }, {
            ownerType: CustomItem,
            propertyName: 'customTooltip',
            defaultValue: false,
            valueType: 'boolean'
        }, {
            ownerType: CustomItem,
            propertyName: 'isMessageContentGrid',
            defaultValue: false,
            valueType: 'boolean'
        }
    ],
    optionsPanelSections: [
        {
            title: 'Custom Options',
            items: [
                {
                    dataField: 'datasourceProperty',
                    label: {
                        text: "DataSource Name"
                    },
                    editorType: "dxTextBox",
                    editorOptions: {
                        placeholder: "Enter datasource name"
                    }
                },
                {
                    dataField: 'datasourcekeyprop',
                    editorType: "dxTextBox",
                    editorOptions: {
                        placeholder: "Enter key for Datasource"
                    }
                },
                {
                    dataField: 'ExtraKey',
                    label: {
                        text: "Extra Key for drilldown"
                    },
                    editorType: "dxTextBox",
                    editorOptions: {
                        placeholder: "Enter key"
                    }
                },
                {
                    dataField: 'isDrilldownGrid',
                    label: {
                        text: "is drilldown grid"
                    },
                    template: FormItemTemplates.buttonGroup,
                    editorOptions: {
                        keyExpr: "value",
                        items: [
                            {
                                value: true,
                                text: "On"
                            }, {
                                value: false,
                                text: "Off"
                            }
                        ]
                    }
                }, {
                    dataField: 'isMessageContentGrid',
                    label: {
                        text: "is MessageContent Grid"
                    },
                    template: FormItemTemplates.buttonGroup,
                    editorOptions: {
                        keyExpr: "value",
                        items: [
                            {
                                value: true,
                                text: "On"
                            }, {
                                value: false,
                                text: "Off"
                            }
                        ]
                    }
                }, {
                    dataField: 'Popup',
                    label: {
                        text: "Enable Trackers Popup"
                    },
                    template: FormItemTemplates.buttonGroup,
                    editorOptions: {
                        keyExpr: "value",
                        items: [
                            {
                                value: true,
                                text: "On"
                            }, {
                                value: false,
                                text: "Off"
                            }
                        ]
                    }
                }, {
                    dataField: 'DashboardID',
                    editorType: "dxTextBox",
                    label: {
                        text: "Dashboard Id"
                    },

                    editorOptions: {}

                }, {
                    dataField: 'popupWidth',
                    editorType: "dxTextBox",
                    label: {
                        text: "Popup Width"
                    }
                }, {
                    dataField: 'popupHeight',
                    editorType: "dxTextBox",
                    label: {
                        text: "Popup Height"
                    }

                }, {
                    dataField: 'GridId',
                    label: {
                        text: "Grid ID"
                    },
                    editorType: "dxTextBox"
                }, {
                    dataField: 'colorFunction',
                    label: {
                        text: "Color Function"
                    },
                    template: FormItemTemplates.buttonGroup,
                    editorOptions: {
                        keyExpr: "value",
                        items: [
                            {
                                value: true,
                                text: "On"
                            }, {
                                value: false,
                                text: "Off"
                            }
                        ]
                    }
                }, {
                    dataField: 'customTooltip',
                    label: {
                        text: "Enable Custom Tooltip"
                    },
                    template: FormItemTemplates.buttonGroup,
                    editorOptions: {
                        keyExpr: "value",
                        items: [
                            {
                                value: true,
                                text: "On"
                            }, {
                                value: false,
                                text: "Off"
                            }
                        ]
                    }
                },
            ]
        },
        {
            title: "Drill Down Popup (custom)",
            items: [
                {
                    dataField: 'DrillDownPopup',
                    label: {
                        text: "Enable Popup"
                    },
                    template: FormItemTemplates.buttonGroup,
                    editorOptions: {
                        keyExpr: "value",
                        items: [
                            {
                                value: true,
                                text: "On"
                            }, {
                                value: false,
                                text: "Off"
                            }
                        ]
                    }
                },
                {
                    dataField: 'DrillDownDashboardID',
                    editorType: "dxTextBox",
                    label: {
                        text: "Dashboard Id"
                    },

                    editorOptions: {}

                },
                {
                    dataField: 'DrillDownPopupWidth',
                    editorType: "dxTextBox",
                    label: {
                        text: "Popup Width"
                    }
                },
                {
                    dataField: 'DrillDownPopupHeight',
                    editorType: "dxTextBox",
                    label: {
                        text: "Popup Height"
                    }

                }, {
                    dataField: 'EnableRowFocus',
                    label: {
                        text: "Enable Row Focus"
                    },
                    template: FormItemTemplates.buttonGroup,
                    editorOptions: {
                        keyExpr: "value",
                        items: [
                            {
                                value: true,
                                text: "On"
                            }, {
                                value: false,
                                text: "Off"
                            }
                        ]
                    }
                },
            ]
        },
        {
            title: "Menu Items (custom)",
            items: [
                {
                    dataField: 'ColumnChooser',
                    label: {
                        text: "Enable Column Chooser"
                    },
                    template: FormItemTemplates.buttonGroup,
                    editorOptions: {
                        keyExpr: "value",
                        items: [
                            {
                                value: true,
                                text: "On"
                            }, {
                                value: false,
                                text: "Off"
                            }
                        ]
                    }
                },
                {
                    dataField: 'AddButton',
                    label: {
                        text: "Enable Add Button"
                    },
                    template: FormItemTemplates.buttonGroup,
                    editorOptions: {
                        keyExpr: "value",
                        items: [
                            {
                                value: true,
                                text: "On"
                            }, {
                                value: false,
                                text: "Off"
                            }
                        ]
                    }
                },
                {
                    dataField: 'EditButton',
                    label: {
                        text: "Enable Edit Button"
                    },
                    template: FormItemTemplates.buttonGroup,
                    editorOptions: {
                        keyExpr: "value",
                        items: [
                            {
                                value: true,
                                text: "On"
                            }, {
                                value: false,
                                text: "Off"
                            }
                        ]
                    }
                },
                {
                    dataField: 'CustomAddButton',
                    label: {
                        text: "Enable custom Add Button"
                    },
                    template: FormItemTemplates.buttonGroup,
                    editorOptions: {
                        keyExpr: "value",
                        items: [
                            {
                                value: true,
                                text: "On"
                            }, {
                                value: false,
                                text: "Off"
                            }
                        ]
                    }
                },
                {
                    dataField: 'CustomEditButton',
                    label: {
                        text: "Enable custom Edit Button"
                    },
                    template: FormItemTemplates.buttonGroup,
                    editorOptions: {
                        keyExpr: "value",
                        items: [
                            {
                                value: true,
                                text: "On"
                            }, {
                                value: false,
                                text: "Off"
                            }
                        ]
                    }
                }, {
                    dataField: 'ExportToExcel',
                    label: {
                        text: "Enable Export To Excel"
                    },
                    template: FormItemTemplates.buttonGroup,
                    editorOptions: {
                        keyExpr: "value",
                        items: [
                            {
                                value: true,
                                text: "On"
                            }, {
                                value: false,
                                text: "Off"
                            }
                        ]
                    }
                },
                {
                    dataField: 'DownloadButton',
                    label: {
                        text: "Enable Download Button"
                    },
                    template: FormItemTemplates.buttonGroup,
                    editorOptions: {
                        keyExpr: "value",
                        items: [
                            {
                                value: true,
                                text: "On"
                            }, {
                                value: false,
                                text: "Off"
                            }
                        ]
                    }
                }, {
                    dataField: 'Refresh',
                    label: {
                        text: "Enable Refresh"
                    },
                    template: FormItemTemplates.buttonGroup,
                    editorOptions: {
                        keyExpr: "value",
                        items: [
                            {
                                value: true,
                                text: "On"
                            }, {
                                value: false,
                                text: "Off"
                            }
                        ]
                    }
                }, {
                    dataField: 'DrillDownMenu',
                    label: {
                        text: "Enable Drill Down"
                    },
                    template: FormItemTemplates.buttonGroup,
                    editorOptions: {
                        keyExpr: "value",
                        items: [
                            {
                                value: true,
                                text: "On"
                            }, {
                                value: false,
                                text: "Off"
                            }
                        ]
                    }
                }, {
                    dataField: 'GroupPanel',
                    label: {
                        text: "Enable Group Panel"
                    },
                    template: FormItemTemplates.buttonGroup,
                    editorOptions: {
                        keyExpr: "value",
                        items: [
                            {
                                value: true,
                                text: "On"
                            }, {
                                value: false,
                                text: "Off"
                            }
                        ]
                    }
                },
            ]
        },
        {
            title: "Editing options (custom)",
            items: [
                {
                    dataField: 'enableAdd',
                    label: {
                        text: "Enable Add"
                    },
                    template: FormItemTemplates.buttonGroup,
                    editorOptions: {
                        keyExpr: "value",
                        items: [
                            {
                                value: true,
                                text: "On"
                            }, {
                                value: false,
                                text: "Off"
                            }
                        ]
                    }
                }, {
                    dataField: 'enableEdit',
                    label: {
                        text: "Enable Edit"
                    },
                    template: FormItemTemplates.buttonGroup,
                    editorOptions: {
                        keyExpr: "value",
                        items: [
                            {
                                value: true,
                                text: "On"
                            }, {
                                value: false,
                                text: "Off"
                            }
                        ]
                    }
                }, {
                    dataField: 'enableDelete',
                    label: {
                        text: "Enable Delete"
                    },
                    template: FormItemTemplates.buttonGroup,
                    editorOptions: {
                        keyExpr: "value",
                        items: [
                            {
                                value: true,
                                text: "On"
                            }, {
                                value: false,
                                text: "Off"
                            }
                        ]
                    }
                },


            ]
        }, {
            title: "Lookup options (custom)",
            items: [
                {
                    dataField: 'enableLookupprop',
                    label: {
                        text: "Enable Lookup"
                    },
                    template: FormItemTemplates.buttonGroup,
                    editorOptions: {
                        keyExpr: "value",
                        items: [
                            {
                                value: true,
                                text: "On"
                            }, {
                                value: false,
                                text: "Off"
                            }
                        ]
                    }
                },
                {
                    dataField: 'lookupnameprop',
                    label: {
                        text: "Lookup Name"
                    },
                    editorType: "dxTextBox",
                    editorOptions: {
                        placeholder: "Enter Lookup datasource name"
                    }
                },
                {
                    dataField: 'lookupkeyprop',
                    label: {
                        text: "Lookup Key"
                    },
                    editorType: "dxTextBox",
                    editorOptions: {
                        placeholder: "Enter key for Lookup"
                    }
                },

                {

                    dataField: 'lookupdisplayprop',

                    label: {

                        text: "Lookup display expr"

                    },

                    editorType: "dxTextBox",

                    editorOptions: {

                        placeholder: "Enter display expression"

                    }

                }, {

                    dataField: 'lookupvalueprop',

                    label: {

                        text: "Lookup value exp"

                    },

                    editorType: "dxTextBox",
                    editorOptions: {
                        placeholder: "Enter value expression"
                    }

                }, {
                    dataField: 'lookupcolumnprop',
                    label: {
                        text: "Lookup column"
                    },

                    editorType: "dxTextBox",
                    editorOptions: {
                        placeholder: "Enter column to be Lookup"
                    }

                }
            ]
        }, {
            title: "Columns options (custom)",
            items: [
                {
                    dataField: 'enablecolumnsprop',
                    label: {
                        text: "Enable Columns"
                    },
                    template: FormItemTemplates.buttonGroup,
                    editorOptions: {
                        keyExpr: "value",
                        items: [
                            {
                                value: true,
                                text: "On"
                            }, {
                                value: false,
                                text: "Off"
                            }
                        ]
                    }
                }, {
                    dataField: 'columnsprop',
                    label: {
                        text: "columns Names"
                    },
                    editorType: "dxTextBox",
                    editorOptions: {
                        placeholder: "Enter columns names with comma"
                    }
                }, {

                    dataField: 'columnvisibleprop',

                    label: {

                        text: "enter if column is visible"

                    },

                    editorType: "dxTextBox",

                    editorOptions: {

                        placeholder: "false,true,false"

                    }

                }
            ]
        }, {
            title: 'Row Template (custom)',
            items: [
                {
                    dataField: 'enableRowTemplate',
                    label: {
                        text: "Enable Row Template"
                    },
                    template: FormItemTemplates.buttonGroup,
                    editorOptions: {
                        keyExpr: "value",
                        items: [
                            {
                                value: true,
                                text: "On"
                            }, {
                                value: false,
                                text: "Off"
                            }
                        ]
                    }
                },

            ]
        }, {
            title: 'Customize Form Items',
            items: [
                {
                    dataField: 'customizeFormItems',
                    label: {
                        text: "Enable custom form items"
                    },
                    template: FormItemTemplates.buttonGroup,
                    editorOptions: {
                        keyExpr: "value",
                        items: [
                            {
                                value: true,
                                text: "On"
                            }, {
                                value: false,
                                text: "Off"
                            }
                        ]
                    }
                }, {
                    dataField: 'formItemsDataSource',
                    label: {
                        text: "DataSource for dropdown fields"
                    },
                    editorType: "dxTextBox"
                }, {
                    dataField: 'formItems',
                    label: {
                        text: "Column name and editor type"
                    },
                    editorType: "dxTextArea",
                    editorOptions: {
                        placeholder: "Enter 'columnName/editorType' with comma"
                    }
                }
                // {
                //     dataField:'addColumns',
                //     template: function (args, element) {
                //         var buttonContainer = document.createElement('div');
                //         new dxButton(buttonContainer, {
                //             text: 'Add Columns',
                //             onClick: function () {
                //                 _showFormPopup()
                //             }
                //         })
                //         return buttonContainer;
                //     },
                //     label: {
                //         visible: false
                //     }
                // }
            ]
        },
    ],
    icon: 'customGridItemIcon',
    title: 'Custom Grid'
};

// function _showFormPopup() {
// var popupContainer = document.createElement('div');
// document.body.appendChild(popupContainer);
// var popupOptions = {
//     width: '800px',
//     height: 'auto',
//     closeOnOutsideClick: false,
//     contentTemplate: function (contentContainer) {
//         var formContainer = document.createElement('div');
//         var textBoxContainer = document.createElement('div');
//         var buttonContainer = document.createElement('div');
//         var formOptions = '';
//         new dxTextBox(textBoxContainer, {
//             elementAttr: {
//                 id: 'formItems'
//             },
//             value: '132'
//         });
//         new dxButton(buttonContainer, {
//             text: 'Submit',
//             onClick: function(e){
//                 var test = document.getElementById('formItems')
//                 console.log(test);
//             }
//         });
//         formContainer.appendChild(textBoxContainer)
//         formContainer.appendChild(buttonContainer)
//         return formContainer;
//     },
//     onHidden: function () {
//         document.body.removeChild(popupContainer)
//     },
//     title: 'Customize Form Items'
// };
// var popup = new dxPopup(popupContainer, popupOptions);
// popup.show();
// }


function isNotEmpty(value) {
    return value !== undefined && value !== null && value !== "";
}
// function handleErrors(response) {
//     if (! response.ok)
//         throw Error(response.statusText);


//     return response;
// }


class CustomGridViewer extends CustomItemViewer {
    constructor(model, $container, options) {
        super(model, $container, options);
        this.dxDataGridWidget = null;
        this.dxDataGridWidgetSettings = undefined;
        this.enablePopup = this.getPropertyValue('Popup');
        this.enableDrillDownPopup = this.getPropertyValue('DrillDownPopup');
        this.drilldownDashboard = null;
        this.popupCustomOptions = {
            popupWidth: this.getPropertyValue('popupWidth'),
            popupHeight: this.getPropertyValue('popupHeight'),
            dashboardId: this.getPropertyValue('DashboardID')
        }
        this.drillDownPopupCustomOptions = {
            popupWidth: this.getPropertyValue('DrillDownPopupWidth'),
            popupHeight: this.getPropertyValue('DrillDownPopupHeight'),
            dashboardId: this.getPropertyValue('DrillDownDashboardID')
        }
        this.dataSourceName = this.getPropertyValue('datasourceProperty');

        this.MOGrid = null;
        this.MTGrid = null;

        this.toolbarMenuItems = [];
        this.enableColumnChooser = this.getPropertyValue('ColumnChooser');
        this.enableAddButton = this.getPropertyValue('AddButton');
        this.enableEditButton = this.getPropertyValue('EditButton');
        this.enableDownloadButton = this.getPropertyValue('DownloadButton');
        this.enableCustomAddButton = this.getPropertyValue('CustomAddButton');
        this.enableExportToExcel = this.getPropertyValue('ExportToExcel');
        this.enableRefresh = this.getPropertyValue('Refresh');
        this.enableDrillDownMenu = this.getPropertyValue('DrillDownMenu');
        this.enableGroupPanel = this.getPropertyValue('GroupPanel');
        // this.urlParam =  null;
        this.enableAdd = this.getPropertyValue('enableAdd');
        this.enableEdit = this.getPropertyValue('enableEdit');
        this.enableDelete = this.getPropertyValue('enableDelete');
        this.enableCustomEditButton = this.getPropertyValue('CustomEditButton');
        this.enableLookup = this.getPropertyValue('enableLookupprop');
        this.lookupName = this.getPropertyValue('lookupnameprop');
        this.lookupKey = this.getPropertyValue('lookupkeyprop');
        this.lookupColumn = this.getPropertyValue('lookupcolumnprop');
        this.lookupDispExp = this.getPropertyValue('lookupdisplayprop');
        this.lookupValExp = this.getPropertyValue('lookupvalueprop');
        this.enableColumns = this.getPropertyValue('enablecolumnsprop');
        this.Columns = this.getPropertyValue('columnsprop');
        this.isDrilldownGrid = this.getPropertyValue('isDrilldownGrid');
        this.formItemsDataSource = this.getPropertyValue('formItemsDataSource');
        this.columnsvisible = this.getPropertyValue('columnvisibleprop');
        this.selectedChart = -1;
        this.templateId = -1;

    }

     Base64ToBytes(base64) {

        var s = window.atob(base64);

        var bytes = new Uint8Array(s.length);

        for (var i = 0; i < s.length; i++) {

            bytes[i] = s.charCodeAt(i);

        }

        return bytes;

    };


    _createToolbarMenuItems(dataGrid) {
        var _this = this;
        if (this.enableColumnChooser) {
            var columnChooserOptions = {
                name: "columnChooserButton",
                location: "before"

            }
            this.toolbarMenuItems.push(columnChooserOptions)
        }

        if (this.enableAddButton) {
            var addButtonOptions = {
                name: "addRowButton",
                location: "before"
            }
            this.toolbarMenuItems.push(addButtonOptions)
        }

        if (this.enableCustomAddButton) {
            var data = {
                templateId: -1
            };
            var customAddOptions = {
                location: 'before',
                widget: 'dxButton',
                options: {
                    icon: 'add',
                    hint: 'Add',
                    onClick() {
                        _this._showTemplatePopup(data, _this.popupCustomOptions,data.templateId);
                    }
                }

            }

            this.toolbarMenuItems.push(customAddOptions)


        }

        if (this.enableDownloadButton) {
            var downloadButton = {
                location: 'before',
                widget: 'dxButton',
                options: {
                    icon: 'download',
                    hint: 'Download',
                    onClick(e) {
                      
                        var buttonInstance =  e.component;
                         var selectedRowsData = dataGrid.getSelectedRowsData();
                         if(selectedRowsData[0].modriveTestLogFileName !== undefined){
                        var mofile = selectedRowsData[0].modriveTestLogFileName;
                      
                        _this.downloadFile(buttonInstance,mofile, false)
                       
                    }
                        if(selectedRowsData[0].mtdriveTestLogFileName !== undefined){
                        var mtfile = selectedRowsData[0].mtdriveTestLogFileName;
              
                        _this.downloadFile(buttonInstance,mtfile, false)
                 
                    }
                        if(selectedRowsData[0].driveTestLogFileName !== undefined){
                            var pcap = selectedRowsData[0].driveTestLogFileName;
                         
                            _this.downloadFile(buttonInstance,pcap, true)
                            _this.downloadFile(buttonInstance,pcap, false)
                         
                        }

                    }
                }

            }

            this.toolbarMenuItems.push(downloadButton)
        }
  

        if (this.enableEditButton) {
             var customAddOptions = {
                location: 'before',
                widget: 'dxButton',
                options: {
                    icon: 'edit',
                    hint: 'Edit',
                    onClick() {
                        const toast = _this.createToast();
                        var selectedRowsData = dataGrid.getSelectedRowsData();
                       
                        if(selectedRowsData.length> 0){
                            var key = selectedRowsData[0].id;
                            var index = dataGrid.getRowIndexByKey(key)
                            dataGrid.editRow(index);
                        }else {
                            toast.show();
                        }
            
                    }
                }

            }

            this.toolbarMenuItems.push(customAddOptions)


        }
        if (this.enableExportToExcel) {
            var exportOptions = {
                name: "exportButton",
                location: "before"
            }
            this.toolbarMenuItems.push(exportOptions)
        }

        if (this.enableRefresh) {
            var refreshOptions = {
                location: 'before',
                widget: 'dxButton',
                options: {
                    icon: 'refresh',
                    hint: 'Refresh',
                    onClick() {
                       _this.dxDataGridWidget.refresh();
                      // _this.dxDataGridWidget.state(null);
                    }
                }
            }
            this.toolbarMenuItems.push(refreshOptions)
        }

        if (this.enableDrillDownMenu) {
            var timeBefore = 0;
            var timeAfter = 0;
            var drillDownOptions = {
                location: "before",
                widget: "dxDropDownMenu",
                options: {
                    hint: 'Drill Down',
                    items: [
                        {
                            text: "Normal",
                            onClick: function (e) {
                                if (_this.enableDrillDownPopup) {
                                    if(_this.dataSourceName === 'voicetracker'){
                                        var selectedRow = _this.dxDataGridWidget.getSelectedRowsData();
                                        console.log(selectedRow);
                                        const toast =  _this.createToast();
                                        if(selectedRow.length > 0 ){
                                        var data = {
                                            FailureTimestamp: selectedRow[0].failureTimestamp,
                                            MoDriveTestLogFile: selectedRow[0].modriveTestLogFileName,
                                            MtDriveTestLogFile: selectedRow[0].mtdriveTestLogFileName,
                                            StartTimestamp: selectedRow[0].startTimestamp,
                                            EndTimestamp: selectedRow[0].endTimestamp
                                        }
                                      // window.open("http://localhost:3000/?dashboardId=drilldownNew_&timeBefore="+timeBefore +"&timeAfter="+ timeAfter+"&FailureTimestamp=" + data.FailureTimestamp + "&MoDriveTestLogFile="+data.MoDriveTestLogFile + "&MtDriveTestLogFile=" +data.MtDriveTestLogFile + "&StartTimestamp=" +data.StartTimestamp+ "&EndTimestamp=" + data.EndTimestamp, "_blank");
                                        window.open(window.appLink + "?dashboardId=drilldownNew_&timeBefore="+timeBefore +"&timeAfter="+ timeAfter+ "&MoDriveTestLogFile="+data.MoDriveTestLogFile + "&MtDriveTestLogFile=" +data.MtDriveTestLogFile+ "&StartTimestamp=" +data.StartTimestamp+ "&EndTimestamp=" + data.EndTimestamp, "_blank");
                                        }else {
                                            toast.show();
                                        }
                       

                                        // const state = {
                                        //     data: data
                                        // }
                                        // const loadDrillDown = ReactDOM.createRoot(document.getElementById('loadDrillDown'));

                                        // loadDrillDown.render (
                                        //     <LoadDrillDown gridData={
                                        //         state.data
                                        //     }/>
                                        // );

                                        // _this._showPopup(data, _this.drillDownPopupCustomOptions);
                                    }else if(_this.dataSourceName === 'packet') {
                                        const toast =   _this.createToast();
                                    var selectedRow = _this.dxDataGridWidget.getSelectedRowsData();
                                    if(selectedRow.length > 0){
                                        var data = {
                                            MessageId: selectedRow[0].id,
                                            FailureTimestamp: selectedRow[0].failureTimestamp,
                                            DriveTestLogFile: selectedRow[0].driveTestLogFileName,
                                            DriveTestLogFileId: selectedRow[0].id,
                                            StartTimestamp: selectedRow[0].startTimestamp,
                                            EndTimestamp: selectedRow[0].endTimestamp
                                           // MtDriveTestLogFile: selectedRow[0].mtdriveTestLogFileName
                                        }
                                     //  window.open("http://localhost:3000/?dashboardId=drilldownpacket_&timeBefore="+timeBefore+"&timeAfter="+timeAfter+ "&FailureTimestamp=" + data.FailureTimestamp + "&DriveTestLogFileName=" + data.DriveTestLogFile + "&DriveTestLogFile="+data.DriveTestLogFileId+ "&StartTimestamp=" +data.StartTimestamp+ "&EndTimestamp=" + data.EndTimestamp, "_blank");
                                                       
                                  window.open(window.appLink + "?dashboardId=drilldownpacket_&timeBefore="+timeBefore+"&timeAfter="+timeAfter+ "&DriveTestLogFileName=" + data.DriveTestLogFile + "&DriveTestLogFile="+data.DriveTestLogFileId+ "&StartTimestamp=" +data.StartTimestamp+ "&EndTimestamp=" + data.EndTimestamp, "_blank");
                                    // _this._showPopup(data, _this.drillDownPopupCustomOptions);
                                    }else {
                                        toast.show();
                                    }
                    
                   
                                }
                            }
                        }

                        }, {
                            text: "Extended",
                            onClick: function (e) { // console.log(e);
                                const toast =   _this.createToast();
                                var selectedRow = _this.dxDataGridWidget.getSelectedRowsData();
                                if(selectedRow.length > 0){
                                var data = {
                                    MessageId: selectedRow[0].id,
                                    FailureTimestamp: selectedRow[0].failureTimestamp,
                                    MoDriveTestLogFile: selectedRow[0].modriveTestLogFileName,
                                    MtDriveTestLogFile: selectedRow[0].mtdriveTestLogFileName,
                                    StartTimestamp: selectedRow[0].startTimestamp,
                                    EndTimestamp: selectedRow[0].endTimestamp
                                }
                                _this._showExtendedPopup(data, _this.dxDataGridWidget);
                            }else {
                                toast.show();
                            }
                            }
                        }
                    ]


                }
            }
            this.toolbarMenuItems.push(drillDownOptions)
        }


        if (this.enableGroupPanel) {
            var groupPanelOptions = {
                name: "groupPanel",
                location: "before"
            }

            this.toolbarMenuItems.push(groupPanelOptions)
        }

        return this.toolbarMenuItems;
    }

    createToast(){
        var container = document.createElement('div');
        document.body.appendChild(container);
        const toast = new Toast(container, { displayTime: 2000, width: '15vw', position: 'bottom right'});
        const message = `Please select a row!`;
        const type = 'error';
        toast.option({message, type});
        return toast;
    }
    onBeforeRender(data, drilldownDashboard) {
        var _this = this;
        drilldownDashboard.registerExtension(new WebPageItem(drilldownDashboard));
        drilldownDashboard.registerExtension(new KeplerItem(drilldownDashboard));
        drilldownDashboard.registerExtension(new ButtonItem(drilldownDashboard));
        drilldownDashboard.registerExtension(new CustomGrid(drilldownDashboard));
        drilldownDashboard.registerExtension(new LineChartItem(drilldownDashboard));
        var viewerApiExtension = drilldownDashboard.findExtension('viewer-api');
        if (viewerApiExtension) {
            viewerApiExtension.on('itemWidgetOptionsPrepared', _this.onItemWidgetOptionsPrepared);
            viewerApiExtension.on('itemCaptionToolbarUpdated', _this.onItemCaptionToolbarUpdated);
        }

        drilldownDashboard.on('dashboardEndUpdate', function () {
            var parameterDialogExt = drilldownDashboard.findExtension('dashboardParameterDialog');
            var parameters = parameterDialogExt.getParameters();
            var paramMO = parameters.getParameterByName("MOlogfile"),
                paramMT = parameters.getParameterByName("MTlogfile"),
                paramFailureTime = parameters.getParameterByName("Timestamp");
            paramMO.setValue(data.MoDriveTestLogFile);
            paramMT.setValue(data.MtDriveTestLogFile);
            paramFailureTime.setValue(data.FailureTimestamp);
           // console.log(parameters);
        });


    }

    _getDataSource() {
        var _this = this;
        var dsName = this.dataSourceName;
        var extraKey = this.getPropertyValue('ExtraKey');
        var keys = [];
        if (extraKey !== undefined && extraKey !== null) {
            keys = ['messageId', extraKey] // drilldown
        } else {
            keys = this.getPropertyValue('datasourcekeyprop');
        }
        var customDataSource = new CustomStore({
            key: keys,
            load(loadOptions) {
                return _this.loadTable(loadOptions, dsName);
            },
            insert: function (values) {
                var deferred = $.Deferred();
                if (dsName.includes('chart')) {  
                    var urlParam = window.location.search; 
                       if (urlParam.includes('%22%3A%22')) {
                           urlParam = urlParam.split('%22%3A%22'); 
                           var mysessionguid = urlParam[1].split('%22');
                           _this.templateId = mysessionguid[0];
                       }   
                   values.templateId = _this.templateId;  
               }
                var stringvalues = JSON.stringify(values).toString();


                // console.log("after stringify", stringvalues);
                var mystringvalues = "'" + stringvalues + "'";
                if (! dsName.includes('/')) {
                    $.ajax({
                        url: url + "api/trackers/insert" + dsName + "/",
                        type: "POST",
                        data: JSON.stringify(mystringvalues),
                        dataType: 'json',
                        contentType: 'application/json',
                        success: function (data) { // console.log("inserted data", data);
                            deferred.resolve(data);
                            deferred.promise();
                        },
                        error: function (xhr, status, error) { // console.log("error", error);
                            deferred.reject("Result: " + status + " " + error + " " + xhr.status + " " + xhr.statusText);
                        }
                    })
                    return deferred.promise();
                } else {
                    var mysessionguid = [];
                    var urlParam = window.location.search;
                    urlParam = urlParam.split('%22%3A%22'); // 22e671e113-d90a-11ea-bb83-020017009382%22%7D%7D
                    mysessionguid = urlParam[1].split('%22');
                    // console.log(mysessionguid[0]);
                    var sessionGuid = dsName.replace('{sessionguid}', mysessionguid[0]);
                    // console.log(sessionGuid);
                    $.ajax({
                        url: url + "api/trackers/insert" + sessionGuid + "/",
                        type: "POST",
                        data: JSON.stringify(mystringvalues),
                        dataType: 'json',
                        contentType: 'application/json',
                        success: function (data) { // console.log("inserted data", data);
                            deferred.resolve(data);
                            deferred.promise();
                        },
                        error: function (xhr, status, error) { // console.log("error", error);
                            deferred.reject("Result: " + status + " " + error + " " + xhr.status + " " + xhr.statusText);
                        }
                    })
                    return deferred.promise();
                }
            },
            update: function (key, values) { // console.log('values', values);
                var deferred = $.Deferred();
                var mystringvalues = "'" + JSON.stringify(values) + "'";
                // console.log("to update mystringvalues", mystringvalues);
                // console.log("to update key", key);
                if (! dsName.includes('/')) {
                    $.ajax({
                        url: url + "api/trackers/update" + dsName + "/" + key,
                        method: "PUT",
                        contentType: "application/json",
                        dataType: "json",
                        data: JSON.stringify(mystringvalues),
                        success: function (result) { // console.log("updated data",result);
                            deferred.resolve();
                            deferred.promise();
                        },
                        error(err) {
                            console.log("error on updated data ", err);
                            deferred.reject('update Error');
                        }
                    })
                    return deferred.promise();
                } else {
                    var newdsName = dsName.replace('/{sessionguid}', '');
                    // console.log(newdsName);
                    $.ajax({
                        url: url + "api/trackers/update" + newdsName + "/" + key,
                        method: "PUT",
                        contentType: "application/json",
                        dataType: "json",
                        data: JSON.stringify(mystringvalues),
                        success: function (result) { // console.log("updated data",result);
                            deferred.resolve();
                            deferred.promise();
                        },
                        error: function (xhr, status, error) {
                            console.log("error", error);
                            deferred.reject("Result: " + status + " " + error + " " + xhr.status + " " + xhr.statusText);
                        }
                    })
                    return deferred.promise();
                }
            },
            remove: function (key) {
                var deferred = $.Deferred();
                var newdsName = dsName.replace('/{sessionguid}', '');
                // console.log(newdsName);
                $.ajax({
                    url: url + "api/trackers/delete" + newdsName + "/" + key,
                    method: "DELETE",
                    success: function (data) { // // console.log("removed data", data);
                        deferred.resolve(data);
                    },
                    error: function (xhr, status, error) {
                        console.log("error", error);
                        deferred.reject("Result: " + status + " " + error + " " + xhr.status + " " + xhr.statusText);
                    }
                })
                return deferred.promise();

            }
        });


        return customDataSource;

    };


    downloadFile(buttonInstance,fileName, isPcap)
    {
        var _this = this;
        var extension = isPcap ? ".zip" : ".trp";
        buttonInstance.option('disabled', true)

        $.ajax({
            type: "GET",
            url: url + "api/trackers/downloadFile/" + fileName + "/" +isPcap,
            contentType: "binary/octet-stream",
            dataType: "text",
            success: function (result) { 
            buttonInstance.option('disabled', false);
           //Convert Base64 string to Byte Array.
    var bytes = _this.Base64ToBytes(result);
    var blob = new Blob([bytes], { type: "application/octetstream" });
    //Check the Browser type and download the File.
    var isIE = false || !!document.documentMode;
    if (isIE) {
        window.navigator.msSaveBlob(blob, fileName+ extension);
    } else {
        var url = window.URL || window.webkitURL;
        var link = url.createObjectURL(blob);
        var a = $("<a />");
        a.attr("download", fileName+ extension);
        a.attr("href", link);
        $("body").append(a);
        a[0].click();
        $("body").remove(a);
    }
               //console.log(result);
            },
            error: function (xhr, status, error) {
                console.log("Result: " + status + " " + error + " " + xhr.status + " " + xhr.statusText)
            }
        })
     
   
    }

    loadTable(loadOptions, dsName) {

        var d = $.Deferred();
        var urlParam;
        var timestampValue;
        // if (this.getPropertyValue('isDrilldownGrid')) {

        //     urlParam = window.location.search;
        //     urlParam = urlParam.split('&');
        //    //  console.log(urlParam);
        //     var moLogFile = urlParam[3].split('=');
        //     moLogFile= moLogFile[1];
        //     var mtLogFile = urlParam[4].split('=');
        //     mtLogFile= mtLogFile[1];
        //     timestampValue = urlParam[2].split('=');
        //     timestampValue = timestampValue[1];
        //     if (timestampValue !== undefined) {
        //         timestampValue = timestampValue.replace('T', ' ');
        //     }


        //     if (this.getPropertyValue('datasourceProperty') === 'l3messageo2MOold') {
        //         var MOData = {
        //             MOlogfile: moLogFile,
        //             Timestamp: timestampValue
        //         };
        //         // console.log('myuserData', myuserData)
        //         loadOptions.userData = MOData;

        //     }

        //     if (this.getPropertyValue('datasourceProperty') === 'l3messageo2MTold') {
        //         var MTData = {
        //             MTlogfile: mtLogFile,
        //             Timestamp: timestampValue
        //         };
        //         // console.log('myuserData', myuserData)
        //         loadOptions.userData = MTData;

        //     }
        // }
        // if (this.getPropertyValue('isMessageContentGrid')) {

        //     urlParam = window.location.search;
        //     urlParam = urlParam.split('&');
        //     var messageId = urlParam[1].split('=');
        //     messageId = messageId[1]
        //     timestampValue = urlParam[2].split('=');
        //     timestampValue = timestampValue[1];
        //     timestampValue = timestampValue.replace('T', ' ');

        //     var myuserData = {
        //         MessageId: messageId,
        //         Timestamp: timestampValue
        //     };
        //     // console.log('myuserData', myuserData)
        //     loadOptions.userData = myuserData;

        // }


        // // console.log('myuserData', myuserData)
        // loadOptions.userData = myuserData;


        var params = {};
        [
            "filter",
            "group",
            "groupSummary",
            "parentIds",
            "requireGroupCount",
            "requireTotalCount",
            "searchExpr",
            "searchOperation",
            "searchValue",
            "select",
            "sort",
            "skip",
            "take",
            "totalSummary",
            "userData"
        ].forEach(function (i) {
            if (i in loadOptions && isNotEmpty(loadOptions[i])) {
                params[i] = JSON.stringify(loadOptions[i]);
            }
        });
        if (!dsName.includes('/')) {
            $.getJSON(url + "api/trackers/" + dsName, params).done(function (response) {
                d.resolve(response.data, {
                    totalCount: response.totalCount,
                    summary: response.summary,
                    groupCount: response.groupCount

                });
            }).fail(function (e) {
                throw e
            });

            return d.promise();

        } else if (dsName.includes('/')) {
            var mysessionguid = [];
            var sessionGuid;
            var urlParams = window.location.search;
            urlParams = urlParams.split('%22%3A%22');
            if (urlParams[1] !== undefined) {

                mysessionguid = urlParams[1].split('%22');
                // console.log(mysessionguid[0]);
                sessionGuid = dsName.replace('{sessionguid}', mysessionguid[0]);
                // console.log(sessionGuid);
            } else { // message content
                mysessionguid = urlParams[0].split('%22%3A');
                mysessionguid = mysessionguid[2].split('%7D%7D');
                sessionGuid = dsName.replace('{sessionguid}', mysessionguid[0]);
                // console.log(sessionGuid);
            }
            $.getJSON(url + "api/trackers/" + sessionGuid, params).done(function (response) {
                d.resolve(response.data, {
                    totalCount: response.totalCount,
                    summary: response.summary,
                    groupCount: response.groupCount
                });
                // console.log("response :", response.data);
            }).fail(function (e) {
                throw e
            });
            return d.promise();
        }
    }

    _getdxDataGridWidgetSettings() {
        var _this = this;

        return {
            dataSource: this._getDataSource(),
            // columns: this.getColumns(this.dataSourceName),
            elementAttr: {
                id: this.getPropertyValue('GridId')
            },
            editing: {
                mode: this.dataSourceName === "template" || this.dataSourceName === "templatechartparam" || this.dataSourceName === "chart" ? "row" : "popup",
                allowAdding: this.enableAdd,
                allowDeleting: this.enableDelete,
                allowUpdating: this.enableEdit,
                useIcons: true

            },
    
            filterRow: {
                visible: true
            },
            syncLookupFilterValues: false,

            // remoteOperations: true,
            grouping: {
                allowCollapsing: true,
                autoExpandAll: false,
                contextMenuEnabled: false,
                expandMode: "buttonClick",
                texts: {}
            },
            groupPanel: {
                allowColumnDragging: true,
                emptyPanelText: "Drag a column header here to group by that column",
                visible: true
            },
            selection: {
                mode: 'single'
            },
            hoverStateEnabled: true,
            paging: { // pageIndex: 0,
                pageSize: 20
            },
            pager: {
                visible: true,
                allowedPageSizes: [
                    20, 50, 100, 200
                ],
                showPageSizeSelector: true,
                showInfo: true,
                showNavigationButtons: false
            },
            remoteOperations: {
                filtering: true,
                grouping: true,
                groupPaging: true,
                paging: true,
                sorting: true,
                summary: true
            },
            // encodeHtml:true,
            // toolbar: {
            //     items: this._createToolbarMenuItems()
            // },
            export: {
                enabled: true
            },
            columnChooser: {
                enabled: true,
                mode: 'select'
            },
            filterPanel: {
                visible: true
            },
            columnAutoWidth: true,
            columnMinWidth: 10,
            focusedRowEnabled: true,
            autoNavigateToFocusedRow: true,
            // focusedRowIndex: 0,
            headerFilter: {
                allowSearch: true,
                height: 325,
                searchTimeout: 500,
                texts: {},
                visible: true,
                width: 252
            },
            onContentReady: function () {},
            onRowDblClick: function (e) {
                if (_this.enablePopup) {
                    if (e.rowType === 'data') {
                        var data = {
                            MessageId: e.data.id,
                            FailureTimestamp: e.data.failureTimestamp,
                            SessionGuid: e.data.sessionGuid,
                            AocId: e.data.id
                        }
                        _this._showPopup(data, _this.popupCustomOptions)
                    }
                }
            },
            onRowPrepared: function (e) {
                if (_this.getPropertyValue('colorFunction')) {
                    if (e.rowType === 'data') {
                        if (e.data.rank === 1) {
                            e.cells[3].cellElement[0].style.backgroundColor = "#eb2636"
                        } else if (e.data.rank === 2) {
                            e.cells[3].cellElement[0].style.backgroundColor = "#f5a432"
                            e.cells[3].cellElement[0].style.color = "#000"
                        } else if (e.data.rank === 3) {
                            e.cells[3].cellElement[0].style.backgroundColor = "#50c03a"
                            e.cells[3].cellElement[0].style.color = "#000"
                        }
                    }
                }

                if (_this.getPropertyValue('customTooltip')) {
                    if (e.rowType === 'data') { // console.log(e);
                        e.rowElement[0].id = 'tooltipTarget-' + e.rowIndex;
                        var tooltipContainer = document.createElement('div');
                        tooltipContainer.id = '#tooltipContainer-' + e.rowIndex;
                        document.body.appendChild(tooltipContainer);
                        // var tooltip = new dxTooltip(tooltipContainer, {
                        //     target: '#tooltipTarget-' + e.rowIndex,
                        //     showEvent: 'mouseenter',
                        //     hideEvent: 'mouseleave',
                        //     hideOnOutsideClick: false,
                        //     position: {
                        //         my: 'top left',
                        //         at: 'bottom left',
                        //         collision: 'fit flip'
                        //     },
                        //     contentTemplate(data) {
                        //         return "<div><span style='font-weight:bold'>Weight:</span><span>" + e.data.weight + "</span></div>" + "<div><span style='font-weight:bold'>Year:</span><span>" + e.data.lastDrivenYear + "</span></div>" + "<div><span style='font-weight:bold'>Week:</span><span>" + e.data.lastDrivenWeek + "</span></div>"
                        //     }
                        // })
                        // e.rowElement[0].appendChild(tooltipContainer);
                        // tooltip.show(e.rowElement);

                    }
                }

                // if (_this.getPropertyValue('isDrilldownGrid')) {
                //     if (e.rowType == 'data') {
                //         if (e.cells[2].value == 'ERRC') {
                //             e.cells[2].cellElement[0].style.backgroundColor = "#E23C4C"
                //         }
                //     }

                // }

            },
            onEditingStart: function (e) { // console.log('EditingStart-e',e);
                var data = e.data;
                if (_this.enableCustomEditButton) {
                    _this.templateId=data.templateId;
                    _this._showTemplatePopup(data, _this.popupCustomOptions,data.templateId);
                }
            },
            onSelectionChanged(selectedItems) { // console.log("onSelectionChanged");
                if (_this.dataSourceName === 'chart') {
                    const data = selectedItems.selectedRowsData[0];
                    var paramgrid = $('#param').dxDataGrid('instance');
                    // console.log("data", data);
                    if (data) { // console.log("onSelectionChanged - data.chartId", data.chartId);
                        if (paramgrid.option("dataSource")) { // console.log("paramgrid.option()", paramgrid.option("dataSource"));
                            paramgrid.clearFilter();
                            paramgrid.filter(['chartId', '=', data.chartId]);
                        }
                    }
                }

            },
            onFocusedRowChanged(selectedItems) { // console.log(_this.dataSourceName);
                if (_this.dataSourceName === 'chart') { // console.log("onFocusedRowChanged", selectedItems);
                    const data = selectedItems.row.data;
                    // console.log('data.chartId', data.chartId);
                    if (data) {
                        var paramgrid = $('#param').dxDataGrid('instance');
                        // console.log("onFocusedRowChanged - data.chartId", data.chartId);
                        if (paramgrid.option("dataSource")) { // console.log("paramgrid.option()", paramgrid.option("dataSource"));
                            paramgrid.clearFilter();
                            paramgrid.filter(['chartId', '=', data.chartId]);
                        }
                    }
                }
            },

            //    onFocusedRowChanging(e) {
            //     console.log("onchanging:",e);
            //     const rowsCount = e.component.getVisibleRows().length;
            //     const pageCount = e.component.pageCount();
            //     const pageIndex = e.component.pageIndex();
            //     const key = e.event && e.event.key;

            //     if (key && e.prevRowIndex === e.newRowIndex) {
            //       if (e.newRowIndex === rowsCount - 1 && pageIndex < pageCount - 1) {
            //         e.component.pageIndex(pageIndex + 1).done(() => {
            //           e.component.option('focusedRowIndex', 0);
            //         });
            //       } else if (e.newRowIndex === 0 && pageIndex > 0) {
            //         e.component.pageIndex(pageIndex - 1).done(() => {
            //           e.component.option('focusedRowIndex', rowsCount - 1);
            //         });
            //       }
            //     }
            // },
            // onFocusedRowChanged(e) {
            //     console.log("onchanged:", e);

            //     if(_this.getPropertyValue('MasterGrid')){
            //        var failureTimeStamp =  e.row.data.failureTimestamp;
            //        var index = e.rowIndex;
            //     }

            //     var failuretimestampFormatted =  _this._formatTimestamp(e.row.data.failureTimestamp);
            //     //const taskItem = getTaskDataItem(e.row);
            //     const focusedRowKey = e.component.option('focusedRowKey');
            //     // $('#taskSubject').html(taskItem.subject);
            //     // $('#taskDetails').html(taskItem.description);
            //     // $('#taskStatus').html(taskItem.status);
            //     // $('#taskProgress').text(taskItem.progress);
            //    // $('#taskId').dxNumberBox('instance').option('value', focusedRowKey);
            // },

        };
    }

    setSelection() {
        var _this = this;
        this.dxDataGridWidget.getAllItems().forEach(function (item) {
            item.select(_this.isSelected(item.data.clientDataRow));
        });
    }

    clearSelection() {
        this.dxDataGridWidget.clearSelection();
    }

    getColumns() { // console.log("get columns")
        var _this = this;
        var columnprop = this.Columns;
        columnprop = columnprop.replace(" ", "");
        let columnstemp = columnprop.split(",");
        let columns = [];
        // let colvisiblearr= this.columnsvisible != undefined? this.columnsvisible.replace(" ","").split(",") : [];
        let colvisibleenable = false;
        let colvisiblearr = "";
        if (this.columnsvisible !== undefined) {
            colvisiblearr = this.columnsvisible.replace(" ", "").split(",");
            colvisibleenable = true;
        }
        var i = 0;
        columnstemp.forEach(element => {
            var col = {};
            col.dataField = element;
            col.encodeHtml = false;

            if (element === 'date' & (this.dataSourceName === 'voicetracker' || this.dataSourceName === 'packet')) {
                col.dataType = 'date';
                col.sortOrder = "desc";
            }

            // if (element === 'reportStatus' & (this.dataSourceName === 'voicetracker' || this.dataSourceName === 'packet')) {
            //     col.filterValues = ['Valid by CET']
            // }
            if(element === 'startTimestamp'){
                col.calculateCellValue = function(rowData) {
                    var date = _this._formatTimestamp(rowData.startTimestamp);
                    return date;
                } 
            }

            if(element === 'endTimestamp'){
                col.calculateCellValue = function(rowData) {
                    var date = _this._formatTimestamp(rowData.endTimestamp);
                    return date;
                } 
            }

            if(element === 'failureTimestamp'){
                col.calculateCellValue = function(rowData) {
                    var date = _this._formatTimestamp(rowData.failureTimestamp);
                    return date;
                } 
            }

            if (element.includes('time') || element.includes('Time') || element.includes('Date') || element.includes('createdOn') || element.includes('updateOn') || element.includes('UpdateOn')) {
               col.dataType = 'datetime';
               col.sortOrder = "desc";
            }
            if (element === 'lastDrivenWeek' || element === 'lastDrivenYear') {
                col.sortOrder = "desc";
            }

            // if (element == 'id') {
            //     col.visible = false;
            // }
            if (this.isDrilldownGrid) {
                if (element === 'timestamp') {
                    col.calculateCellValue = function(rowData) {
                        var date = _this._formatTimestamp(rowData.timestamp);
                        return date;
                }
                col.sortOrder = "asc";
            }
                if (element === 'timeDelta') {
                    col.dataType = undefined;
                    col.sortOrder = undefined;
                }

               
        }
            // console.log(this.enableLookup);
            if (this.enableLookup && this.lookupColumn === col.dataField) {
                col.lookup = {};
                col.lookup.dataSource = new CustomStore({
                    key: this.lookupKey,
                    load(loadOptions) {
                        return _this.loadTable(loadOptions, _this.lookupName);
                    },

                    byKey: function (key) {
                        return $.getJSON(url + "api/trackers/" + _this.lookupName + "bykey/" + key);
                    }

                });

                col.lookup.valueExpr = this.lookupValExp;
                col.lookup.displayExpr = this.lookupDispExp;
                if(col.dataField.toLowerCase()==="parameter")
                {
                    col.editCellTemplate=_this._dropDownBoxEditorTemplate;    
                }

            }

            if (colvisibleenable === true && colvisiblearr[i] === "false") {
                col.visible = false;
            }

            if (col.dataField.toLowerCase()==="parameter" && this.dataSourceName === "templatechartparam") {
               // console.log("columns", columns);
                col.setCellValue = function (rowData, value,cellInfo) {                  
                    rowData.chartId = _this.selectedChart;
                    rowData.staticParamId = value.staticParamID;
                    rowData.parameter = value.parameter;                 
                    var paramgrid = $('#param').dxDataGrid('instance');
                    var filterval = paramgrid.getCombinedFilter(true);               
                    rowData.chartId = filterval[2];
                    if(cellInfo.parameterId!==undefined)
                    {
                       // console.log("update",cellInfo.parameterId);
                        _this.updateParam(cellInfo.parameterId,rowData);                        
                    }else {
                      //  console.log("insert",cellInfo.parameterId);
                        _this.addParam(rowData);
                    }               
                }               
            }

      
            columns.push(col);
            i++;
        });
        // console.log("columns", columns);
        return columns;
    }

    // setSize(width, height) {
    //     Object.getPrototypeOf(CustomGridViewer.prototype).setSize.call(this, width, height);
    //     this.dxDataGridWidget.render();
    // }


    _showPopup(data, customOptions) {

        var _this = this;
        var popupContainer = document.createElement('div');
        document.body.appendChild(popupContainer);
        var popupOptions = {
            width: customOptions.popupWidth,
            height: customOptions.popupHeight,
            // container: '#popupContainer',
            // fullScreen: customOptions.popupfullScreen,
            dragEnabled: true,
            resizeEnabled: true,
            // showTitle:true,
            position: {
                at: 'center',
                my: 'center',
                // of: '.dx-dashboard-item'
            },
            // hideOnOutsideClick: true,
            showCloseButton: true,
            wrapperAttr: { // id: "elementId",
                class: "hide-overlay"
            },
            contentTemplate: function (contentContainer) { // console.log(contentContainer);
                var dashboardContainer = document.createElement('div');
                dashboardContainer.id = 'webDashboard';
                dashboardContainer.style.position = 'relative';
                dashboardContainer.style.width = '100%';
                dashboardContainer.style.height = '100%';
                dashboardContainer.style.left = 0;
                dashboardContainer.style.right = 0;
                dashboardContainer.style.top = 0;
                dashboardContainer.style.bottom = 0;
                var dashboardOptions = _this._getDashboardOptions(data, customOptions);
                _this.drilldownDashboard = new dxDashboardControl(dashboardContainer, dashboardOptions);
                // _this.drilldownDashboard.registerExtension(new GridPaginationOptionsExtension(_this.drilldownDashboard));
                _this.drilldownDashboard.registerExtension(new WebPageItem(_this.drilldownDashboard));
                _this.drilldownDashboard.registerExtension(new KeplerItem(_this.drilldownDashboard));
                _this.drilldownDashboard.registerExtension(new ButtonItem(_this.drilldownDashboard));
                _this.drilldownDashboard.registerExtension(new CustomGrid(_this.drilldownDashboard));
                _this.drilldownDashboard.registerExtension(new LineChartItem(_this.drilldownDashboard));
                var viewerApiExtension = _this.drilldownDashboard.findExtension('viewer-api');

                if (viewerApiExtension) {
                    viewerApiExtension.on('itemWidgetOptionsPrepared', _this.onItemWidgetOptionsPrepared);
                    viewerApiExtension.on('itemCaptionToolbarUpdated', _this.onItemCaptionToolbarUpdated);
                }

                return dashboardContainer;
                // var gridContainer = document.createElement('div');
                // gridContainer.id = 'drilldowngrid';
                // var gridOptions = getGridOptions(MessageId);
                // new dxDataGrid(gridContainer, gridOptions);
                // return gridContainer;
            },
            onHidden: function () {
                document.body.removeChild(popupContainer)
            },
            // title: 'Drill Down',
        };
        var popup = new dxPopup(popupContainer, popupOptions);
        popup.show();

    }


    _showMessageContent(popup, data) {

        var _this = this;
        popup.option('contentTemplate', function (contentContainer) { // console.log(contentContainer);
            const content = $("<div class='drilldownPopup'/>");
            content.append(data);
            content.dxScrollView({
                height: "100%",
                width: "100%"
            });

            return content;
        },
        )
        popup.show();
       
        // var popupOptions = {
        //     width: customOptions.popupWidth,
        //     height: customOptions.popupHeight,
        //     // container: '#popupContainer',
        //     // fullScreen: customOptions.popupfullScreen,
        //     dragEnabled: true,
        //     resizeEnabled: true,
        //     // showTitle:true,
        //     position: {
        //         at: 'center',
        //         my: 'center',
        //         // of: '.dx-dashboard-item'
        //     },
        //     // hideOnOutsideClick: true,
        //     showCloseButton: true,
        //     wrapperAttr: { // id: "elementId",
        //         class: "hide-overlay"
        //     },
        //     contentTemplate: function (contentContainer) { // console.log(contentContainer);
        //         const content = $("<div />");
        //         content.append(data);
        //         content.dxScrollView({
        //             height: "100%",
        //             width: "100%"
        //         });

        //         return content;
        //     },
        //     onHidden: function () {
        //         document.body.removeChild(popupContainer)
        //     },
        //     // title: 'Drill Down',
        // };
       

    }


    onItemCaptionToolbarUpdated(e, s) { // remove maximize
        if (e.itemName === "customItemDashboardItem3" || e.itemName === "customItemDashboardItem4" || e.itemName === "customItemDashboardItem5" || e.itemName === "customItemDashboardItem6") {

            var target = e.options.actionItems.find(function (element) {
                return element.name === "maximize-down-item";
            });

            var index = e.options.actionItems.indexOf(target);
            if (index > -1) {
                e.options.actionItems.splice(index, 1);
            }
        }
    }


    onItemWidgetOptionsPrepared(args) {

        if (args.dashboardItem instanceof ComboBoxItem) {
            // console.log("data items: ",args.dashboardItem.dataItems())
            // console.log("showAllValue: ",args.dashboardItem.showAllValue())

            var comboBoxId = args.dashboardItem.customProperties.getValue(comboBoxIdProperty.propertyName);
            // console.log(comboBoxId);
            if (comboBoxId === 'MO') {
                args.options.onValueChanged = function (e) {

                    var moChart = $('#MOchart').dxChart('instance');

                    var index = e.value;
                    var ds = e.component.getDataSource();
                    // console.log(ds);
                    var templateName = ds._items[index].value[0];
                    $.ajax({
                        type: "GET",
                        url: url + "api/trackers/templatebykey/" + templateName,
                        dataType: "json",
                        success: function (result, status, xhr) { // console.log(result);
                            var dataArray = result.data;
                            // console.log(dataArray);
                            var panes = [];
                            var series = [];
                            var valueField;
                            for (var i = 0; i < dataArray.length; i++) {
                                if (dataArray[i].parameter.includes('rsrq') || dataArray[i].parameter.includes('RSRQ')) {
                                    valueField = 'rsrq';
                                }
                                if (dataArray[i].parameter.includes('rsrp') || dataArray[i].parameter.includes('RSRP')) {
                                    valueField = 'rsrp';
                                }
                                if (dataArray[i].parameter.includes('sinr') || dataArray[i].parameter.includes('SINR')) {
                                    valueField = 'sinr';
                                }
                                if (dataArray[i].parameter.includes('pci') || dataArray[i].parameter.includes('PCI')) {
                                    valueField = 'pci';
                                }
                                if (dataArray[i].parameter.includes('earfcn') || dataArray[i].parameter.includes('Earfcn') || dataArray[i].parameter.includes('EARFCN')) {
                                    valueField = 'earfcn';
                                }
                                panes.push({name: dataArray[i].chartName})
                                series.push({pane: dataArray[i].chartName, valueField: valueField, name: dataArray[i].parameter})


                            }

                            
                            
                           // console.log('mo panes', panes);
                           // console.log('mo series', series);
                            moChart.option('panes', panes);
                            moChart.option('series', series);
                            moChart.option('tooltip.customizeTooltip', function (info) { // console.log(info)
                            })
                            moChart.refresh();
                        },
                        error: function (xhr, status, error) {
                            console.log("Result: " + status + " " + error + " " + xhr.status + " " + xhr.statusText)
                        }
                    });

                }
            } else if (comboBoxId === 'MT') {
                args.options.onValueChanged = function (e) {

                    var mtChart = $('#MTchart').dxChart('instance');

                    var index = e.value;
                    var ds = e.component.getDataSource();
                    // console.log(ds);
                    var templateName = ds._items[index].value[0];
                    $.ajax({
                        type: "GET",
                        url: url + "api/trackers/templatebykey/" + templateName,
                        dataType: "json",
                        success: function (result, status, xhr) { // console.log(result);
                            var dataArray = result.data;
                           // console.log(dataArray);
                            var panes = [];
                            var series = [];
                            var valueField;
                            for (let i = 0; i < dataArray.length; i++) {
                                if (dataArray[i].parameter.includes('rsrq') || dataArray[i].parameter.includes('RSRQ')) {
                                    valueField = 'rsrq';
                                }
                                if (dataArray[i].parameter.includes('rsrp') || dataArray[i].parameter.includes('RSRP')) {
                                    valueField = 'rsrp';
                                }
                                if (dataArray[i].parameter.includes('sinr') || dataArray[i].parameter.includes('SINR')) {
                                    valueField = 'sinr';
                                }
                                if (dataArray[i].parameter.includes('pci') || dataArray[i].parameter.includes('PCI')) {
                                    valueField = 'pci';
                                }
                                if (dataArray[i].parameter.includes('earfcn') || dataArray[i].parameter.includes('Earfcn') || dataArray[i].parameter.includes('EARFCN')) {
                                    valueField = 'earfcn';
                                }


                                let obj = panes.find(panes => panes.name === dataArray[i].chartName);

                                if (obj === undefined) {
                                    panes.push({name: dataArray[i].chartName})
                                }


                                series.push({pane: dataArray[i].chartName, valueField: valueField, name: dataArray[i].parameter})
                            }

                           // console.log('mt panes', panes);
                           // console.log('mt series', series);
                            mtChart.option('panes', panes);
                            mtChart.option('series', series);
                            mtChart.refresh();
                        },
                        error: function (xhr, status, error) {
                            console.log("Result: " + status + " " + error + " " + xhr.status + " " + xhr.statusText)
                        }
                    });

                }
            }

        }


    }


    customizeTooltip(info) {

        const content = [
            "<div><div class='tooltip-header'></div>",
            "<div class='tooltip-body'><div class='series-name'>",
            "<span class='series-name0'></span>",
            ": </div><div class='value-text'>",
            "<span class='series-value0'></span>",
            "</div></div></div>"
        ].join('');

        const htmlContent = $(content);

        htmlContent.find('.tooltip-header').text(info.argumentText);
        htmlContent.find('.series-name0').text(info.points[0].seriesName);
        htmlContent.find('.series-value0').text(info.points[0].valueText);

        return {html: $('<div>').append(htmlContent).html()};
    }

    _showExtendedPopup(data) {
        var _this = this;
        var popupContainer = document.createElement('div');
        document.body.appendChild(popupContainer);
        var popupOptions = {
            width: 400,
            height: 400,
            // container: '#popupContainer',
            // fullScreen: customOptions.popupfullScreen,
            dragEnabled: true,
            resizeEnabled: true,
            // showTitle:true,
            position: {
                at: 'center',
                my: 'center',
                // of: '.dx-dashboard-item'
            },
            // hideOnOutsideClick: true,
            showCloseButton: true,
            wrapperAttr: { // id: "elementId",
                class: "hide-overlay"
            },
            contentTemplate: function (contentContainer) { // console.log(contentContainer);
                var formElement = document.createElement('div');
                
           var extendedForm =  new dxForm(formElement, {
                //    // formData: data,
                    elementAttr: { 
                        id : "extendedForm"
                    },
                    items: [
                        {
                            editorType: "dxNumberBox",
                            colSpan: 2,
                            label: {
                                location: "top",
                                text: "Time Before"
                            },
                            editorOptions: {
                                value: 60
                            },
                            dataField: 'timeBefore'

                        }, {

                            editorType: "dxNumberBox",
                            colSpan: 2,
                            label: {
                                location: "top",
                                text: "Time After"
                            },
                            editorOptions: {
                                value: 60
                            },
                            dataField: 'timeAfter'
                        }, {

                            editorType: "dxButton",
                            editorOptions: {
                                text: 'Submit',
                                stylingMode: 'contained',
                                type: 'success',
                                elementAttr: {
                                    class: 'displayInlineBlock'
                                },
                                onClick(e) {
                                    var timeBefore = extendedForm.getEditor('timeBefore').option('value');
                                    var timeAfter = extendedForm.getEditor('timeAfter').option('value');
                          
                                    if (_this.enableDrillDownPopup) { // console.log(selectedRow);

                                        if(_this.dataSourceName === 'voicetracker'){
                                            var selectedRow = _this.dxDataGridWidget.getSelectedRowsData();
                                        
                                            var data = {
                                              
                                                FailureTimestamp: selectedRow[0].failureTimestamp,
                                                MoDriveTestLogFile: selectedRow[0].modriveTestLogFileName,
                                                MtDriveTestLogFile: selectedRow[0].mtdriveTestLogFileName,
                                                StartTimestamp: selectedRow[0].startTimestamp,
                                                EndTimestamp: selectedRow[0].endTimestamp
                                            }
                                        // console.log(data);
                                        // _this._showPopup(data, _this.drillDownPopupCustomOptions)

                                      // window.open("http://localhost:3000/?dashboardId=drilldownNew_&timeBefore=" + timeBefore + "&timeAfter=" + timeAfter + "&FailureTimestamp=" + data.FailureTimestamp + "&MoDriveTestLogFile="+data.MoDriveTestLogFile + "&MtDriveTestLogFile=" +data.MtDriveTestLogFile+ "&StartTimestamp=" +data.StartTimestamp+ "&EndTimestamp=" + data.EndTimestamp, "_blank");
                                      window.open(window.appLink + "?dashboardId=drilldownNew_&timeBefore=" + timeBefore + "&timeAfter=" + timeAfter + "&MoDriveTestLogFile="+data.MoDriveTestLogFile + "&MtDriveTestLogFile=" +data.MtDriveTestLogFile+ "&StartTimestamp=" +data.StartTimestamp+ "&EndTimestamp=" + data.EndTimestamp, "_blank");

                                        }else if(_this.dataSourceName === 'packet') {
                                            var selectedRow = _this.dxDataGridWidget.getSelectedRowsData();
                                            // console.log(selectedRow);
                                            var data = {
                                                FailureTimestamp: selectedRow[0].failureTimestamp,
                                                DriveTestLogFile: selectedRow[0].driveTestLogFileName,
                                                DriveTestLogFileId: selectedRow[0].id,
                                                StartTimestamp: selectedRow[0].startTimestamp,
                                                EndTimestamp: selectedRow[0].endTimestamp
                                               // MtDriveTestLogFile: selectedRow[0].mtdriveTestLogFileName
                                            }
                                        //   window.open("http://localhost:3000/?dashboardId=drilldownpacket_&timeBefore=" + timeBefore + "&timeAfter=" + timeAfter + "&FailureTimestamp=" + data.FailureTimestamp + "&DriveTestLogFileName=" + data.DriveTestLogFile + "&DriveTestLogFile="+data.DriveTestLogFileId+ "&StartTimestamp=" +data.StartTimestamp+ "&EndTimestamp=" + data.EndTimestamp, "_blank");
                                            window.open(window.appLink + "?dashboardId=drilldownpacket_&timeBefore=" + timeBefore + "&timeAfter=" + timeAfter + "&DriveTestLogFileName=" + data.DriveTestLogFile + "&DriveTestLogFile="+data.DriveTestLogFileId+ "&StartTimestamp=" +data.StartTimestamp+ "&EndTimestamp=" + data.EndTimestamp, "_blank");
                               
                                        }
                                    }
                                }
                            }


                        }, {

                            editorType: "dxButton",
                            editorOptions: {
                                text: 'Cancel',
                                stylingMode: 'contained',
                                type: 'danger',
                                elementAttr: {
                                    class: 'displayInlineBlock'
                                },
                                onClick(e) {
                                    popup.hide()
                                }
                            }


                        },

                    ]
                });
                

                return formElement;
            },
            onHidden: function () {
                document.body.removeChild(popupContainer)
            },
            // title: 'Drill Down',
        };
        var popup = new dxPopup(popupContainer, popupOptions);
        popup.show();
    }

    _showTemplatePopup(data, customOptions, template) {
        var _this = this;
        var popupContainer = document.createElement('div');
        document.body.appendChild(popupContainer);
        var btnCancel;
        var btnSubmit;
        var form;
        var popupOptions = {
            width: customOptions.popupWidth,
            height: customOptions.popupHeight,
            // container: '#popupContainer',
            // fullScreen: customOptions.popupfullScreen,
            dragEnabled: true,
            resizeEnabled: true,
            // showTitle:true,
            position: {
                at: 'center',
                my: 'center',
                // of: '.dx-dashboard-item'
            },
            // hideOnOutsideClick: true,
            showCloseButton: true,
            wrapperAttr: { // id: "elementId",
                class: "hide-overlay"
            },
            contentTemplate: function (contentContainer) { // console.log(contentContainer);
                var maincontainer = document.createElement('div');
                var formElement = document.createElement('div');
                // console.log("data",data);
                // console.log("data.templateName",data.templateName);
                // console.log("data.templateId",data.templateId);
                form = new dxForm(formElement, { // formData: this.mycustomstore,
                    items: [
                        {
                            dataField: 'TemplateName',
                            editorType: "dxTextBox",
                            colSpan: 2,
                            label: {
                                location: "top",
                                text: "TemplateName"
                            },
                            editorOptions: {
                                value: data.templateName,
                                // placeholder : data.templateName
                            }
                        }
                    ]
                })
                var submitButtonContainer = document.createElement('div');
                var cancelButtonContainer = document.createElement('div');
                btnSubmit = new dxButton(submitButtonContainer, {
                    stylingMode: 'contained',
                    text: 'Confirm',
                    type: 'success',
                    width: 120,
                    // onClick() {
                    //     // popup.hide();
                    // }
                })
                btnCancel = new dxButton(cancelButtonContainer, {
                    stylingMode: 'contained',
                    text: 'Cancel',
                    type: 'danger',
                    width: 120,
                    // onClick() {
                    // _this.hide();
                    // }
                })
                cancelButtonContainer.style.marginLeft = "50px";
                var dashboardContainer = document.createElement('div');
                dashboardContainer.id = 'webDashboard';
                dashboardContainer.style.position = 'relative';
                dashboardContainer.style.width = '100%';
                dashboardContainer.style.height = '60vh';
                dashboardContainer.style.left = 0;
                dashboardContainer.style.right = 0;
                dashboardContainer.style.top = 0;
                dashboardContainer.style.bottom = 0;
                dashboardContainer.style.marginTop = "10px";
                dashboardContainer.style.marginBottom = "10px";
                // console.log("_showTemplatePopup.customOptions",customOptions);
                customOptions.lookupName = data.templateId;
                // console.log(" customOptions.lookupName=", customOptions.lookupName);
                var dashboardOptions = _this._getDashboardOptions(data, customOptions);
                _this.panesDashboard = new dxDashboardControl(dashboardContainer, dashboardOptions);
                _this.panesDashboard.registerExtension(new CustomGrid(_this.panesDashboard));
                // var viewerApiExtension = _this.panesDashboard.findExtension('viewer-api');
                // if (viewerApiExtension) {
                // viewerApiExtension.on('itemWidgetOptionsPrepared', onItemWidgetOptionsPrepared);
                // viewerApiExtension.on('itemCaptionToolbarUpdated', onItemCaptionToolbarUpdated);
                // }
                maincontainer.appendChild(formElement);
                maincontainer.appendChild(dashboardContainer);
                maincontainer.appendChild(submitButtonContainer);
                maincontainer.appendChild(cancelButtonContainer);
                return maincontainer;
                // var gridContainer = document.createElement('div');
                // gridContainer.id = 'drilldowngrid';
                // var gridOptions = getGridOptions(MessageId);
                // new dxDataGrid(gridContainer, gridOptions);
                // return gridContainer;
            },
            onHidden: function () {
                document.body.removeChild(popupContainer)
            },
            // title: 'Drill Down',
        };
        var popup = new dxPopup(popupContainer, popupOptions);
        popup.show();

        btnCancel.option('onClick', function () {
            popup.hide();
        })
        btnSubmit.option('onClick', function () {
            this.templateId = template;          
            var editor;
            var values = {};
            var templategrid = $('#template').dxDataGrid('instance');
            if (template === -1) {  //console.log("Add");
                editor = form.getEditor("TemplateName");                
                values.templateName = editor._changedValue;              
                _this.addTemplate(values);              
                let dstemplates = new CustomStore({
                    key: "templateId",                  
                    load(loadOptions) {
                        return _this.loadTable(loadOptions, "template");
                    },
                    byKey: function (key) {                      
                        return $.getJSON(url + "api/trackers/templatebykey/" + key);
                    }
                });              
                templategrid.dataSource = dstemplates;              
                templategrid.refresh();
                popup.hide();
            } else { console.log("Edit");
                editor = form.getEditor("TemplateName");              
                values.templateName = editor._changedValue;              
                _this.updatetemplate(data.templateId, values)              
                templategrid.refresh();
                popup.hide();
            }
        })
    }

    addTemplate(values) { // var _this = this;
        var deferred = $.Deferred();
        var dsName = "addtemplate";
        var stringvalues = JSON.stringify(values).toString();
        var mystringvalues = "'" + stringvalues + "'";
        if (! dsName.includes('/')) {
            $.ajax({
                url: url + "api/trackers/" + dsName + "/",
                type: "POST",
                data: JSON.stringify(mystringvalues),
                dataType: 'json',
                contentType: 'application/json',
                success: function (data) {
                    deferred.resolve(data);
                    deferred.promise();
                    var tempgrid = $('#template').dxDataGrid('instance');
                    tempgrid.refresh();
                },
                error: function (xhr, status, error) { // console.log("error", error);
                    deferred.reject("Result: " + status + " " + error + " " + xhr.status + " " + xhr.statusText);
                }
            })
            return deferred.promise();
        }
    }

    updatetemplate(key, values) {
        var _this = this;
        var deferred = $.Deferred();
        var mystringvalues = "'" + JSON.stringify(values) + "'";
        $.ajax({
            url: url + "api/trackers/updatetemplate/" + key,
            method: "PUT",
            contentType: "application/json",
            dataType: "json",
            data: JSON.stringify(mystringvalues),
            success: function (result) { // console.log("updated data",result);
                deferred.resolve();
                deferred.promise();
                var tempgrid = $('#template').dxDataGrid('instance');
                let dstemplates = new CustomStore({
                    key: "templateId",
                    // group: 'group',
                    load(loadOptions) {
                        return _this.loadTable(loadOptions, "template");
                    },
                    byKey: function (key) {
                        // console.log("Lookup byKeytemplate"  , key);
                        // return _this.loadTable(loadOptions,'chart');
                        return $.getJSON(url + "api/trackers/templatebykey/" + key);
                    }
                });
                tempgrid.dataSource = dstemplates;
                tempgrid.refresh();
                // dstemplates = ApplyChanges(dstemplates, change, { keyExpr: 'templateId' });
            },
            error(err) { // console.log("error on updated data ", err);
                deferred.reject('update Error');
            }
        })
        return deferred.promise();
    }

    _getDashboardOptions(data, customOptions) { // var timestamp = this._formatTimestamp(data.FailureTimestamp);
        var dashOptions = {
            endpoint: window.endpoint,
            // onBeforeRender  : this.onBeforeRender(data),
            initialDashboardId: customOptions.dashboardId,
            workingMode: "ViewerOnly",
            initialDashboardState: data.templateId !== undefined ? '{"Parameters":{"key":"' + data.templateId + '"}}' : "{\"Parameters\":{\"Id\":" + data.MessageId + ",\"MOlogfile\": \"" + data.MoDriveTestLogFile + "\",\"MTlogfile\":\"" + data.MtDriveTestLogFile + "\",\"Timestamp\": \"" + data.FailureTimestamp + "\",\"SessionGuid\":\"" + data.SessionGuid + "\", \"AocId\":\"" + data.AocId + "\"}}",
            //    initialDashboardState: "{\"Parameters\":{\"Id\":" + data.MessageId + ",\"MOtimestamp\": \"" + data.FailureTimestamp + "\",\"MTtimestamp\":\"" + data.FailureTimestamp + "\",\"SessionGuid\":\"" + data.SessionGuid + "\"}}",
            // initialDashboardState: "{\"Parameters\":{\"Id\":" + data.MessageId + "}}",
            // initialDashboardState : "{\"Items\":{\"gridDashboardItem1\":{\"DrillDownValues\":[\""+ MessageId+"\"]}}}",
            // initialDashboardState : "{\"Items\":{\"gridDashboardItem1\":{\"MasterFilterValues\":[[\""+ MessageId+"\"]]}}}",
            extensions: {
                "urlState": {
                    includeDashboardStateToUrl: true
                }
            }
        }
        return dashOptions;
    }


    _formatTimestamp(Timestamp) { // var objectDate = new Date("2020-04-10 17:14:00:123");
        var objectDate = new Date(Timestamp);
        var fullYear = objectDate.getFullYear();
        var month = objectDate.getMonth() + 1;
        var day = objectDate.getDate();
        var hour = objectDate.getHours();
        var minute = objectDate.getMinutes();
        var second = objectDate.getSeconds();
        var millisecond = objectDate.getMilliseconds();
        // var newFormat = month + "/" + day + "/" + fullYear + " " + hour + ":" + minute + ":" + second + ":" + millisecond;
        var newFormat = month + "/" + day + "/" + fullYear + " " + hour + ":" + minute + ":" + second + ":" + millisecond;
        return newFormat;
    }

    _dropDownBoxEditorTemplate(cellElement, cellInfo) {
        //var _this = this;
       // cellInfo.value = cellInfo.data.staticParamId;
        // console.log("dropDownBoxEditorTemplate - cellInfo", cellInfo);
               var dsstaticparams = new CustomStore({
            key: 'staticParamID',
            load(loadOptions) {
                var d = $.Deferred();
                var params = {};
                [
                    "filter",
                    "group",
                    "groupSummary",
                    "parentIds",
                    "requireGroupCount",
                    "requireTotalCount",
                    "searchExpr",
                    "searchOperation",
                    "searchValue",
                    "select",
                    "sort",
                    "skip",
                    "take",
                    "totalSummary"
                ].forEach(function (i) {
                    if (i in loadOptions && isNotEmpty(loadOptions[i])) {
                        params[i] = JSON.stringify(loadOptions[i]);
                      //  console.log("params[" + i + "]", params[i]);
                    }
                });
               // console.log("LoadOptions", loadOptions)
                var controllerName = 'api/trackers/';
                // if(dsName=='messages') { controllerName='api/message/';}
              //  console.log("URL", url + controllerName + "staticparams")
                $.getJSON(url + controllerName + "staticparams", params).done(function (response) {
                    d.resolve(response.data, {
                        totalCount: response.totalCount,
                        summary: response.summary,
                        groupCount: response.groupCount
                    });
                    //("response : staticparams" , response.data);
                }).fail(function () {
                    throw "Data loading error"
                });
                return d.promise();
            },
            byKey: function (key) {
              // console.log("byKey", key);
              // console.log("byKey", rowData);
                // return _this.loadTable(loadOptions,'chart');
                return $.getJSON(url + "api/trackers/staticparamsbykey/" + key );
            }
        });
        return $('<div>').dxDropDownBox({
            dropDownOptions: { width: 500 },
            dataSource:dsstaticparams ,            
            value: cellInfo.value,
            valueExpr: 'staticParamID',
            displayExpr: 'parameter',
            contentTemplate(e) {
               // var $this=_this;
                return $('<div>').dxDataGrid({
                    dataSource: dsstaticparams,                   
                    remoteOperations: true,
                    columns: ['group', 'parameter'],
                    hoverStateEnabled: true,
                    scrolling: { mode: 'virtual' },
                    height: 250,
                    selection: { mode: 'single' },
                    selectedRowKeys: [cellInfo.value],
                    focusedRowEnabled: true,
                    focusedRowKey: cellInfo.value,
                    //headerFilter: { visible: true },
                    filterRow : {visible: true},
                    onSelectionChanged(selectionChangedArgs) {
                      //  console.log("onSelectionChanged - selectionChangedArgs", selectionChangedArgs);
                      //  console.log("onSelectionChanged - selectionChangedArgs.selectedRowKeys[0]", selectionChangedArgs.selectedRowKeys[0]);
                        if(selectionChangedArgs.selectedRowKeys[0]!==undefined)
                        {
                            // e.component.option('value', selectionChangedArgs.selectedRowKeys[0]);
                            //cellInfo.setValue(selectionChangedArgs.selectedRowKeys[0]);
                            e.component.option('value', selectionChangedArgs.selectedRowsData[0].parameter);
                          //  cellInfo.setValue(selectionChangedArgs.selectedRowsData[0]);
                          cellInfo.setValue(selectionChangedArgs.selectedRowsData[0],cellInfo);
                        }
                        if (selectionChangedArgs.selectedRowKeys.length > 0) {
                            e.component.close();
                        }
                    },
                });
            },
        });
    }

    addParam(values) { // var _this = this;
        var deferred = $.Deferred();
        var dsName = "inserttemplatechartparam";
        //var values="";
        var stringvalues = JSON.stringify(values).toString();
        var mystringvalues = "'" + stringvalues + "'";
        console.log("addParam",values);
        if (! dsName.includes('/')) {
            $.ajax({
                url: url + "api/trackers/" + dsName + "/",
                type: "POST",
                data: JSON.stringify(mystringvalues),
                dataType: 'json',
                contentType: 'application/json',
                success: function (data) {
                    deferred.resolve(data);
                    deferred.promise();
                    var tempgrid = $('#param').dxDataGrid('instance');
                    tempgrid.refresh();
                },
                error: function (xhr, status, error) { // console.log("error", error);
                    deferred.reject("Result: " + status + " " + error + " " + xhr.status + " " + xhr.statusText);
                }
            })
            return deferred.promise();
        }
    }
    updateParam(key, values) {
        var _this = this;
        var deferred = $.Deferred();
        var mystringvalues = "'" + JSON.stringify(values) + "'";
        //console.log(key,values);
        $.ajax({
            url: url + "api/trackers/updatetemplatechartparam/" + key,
            method: "PUT",
            contentType: "application/json",
            dataType: "json",
            data: JSON.stringify(mystringvalues),
            success: function (result) { // console.log("updated data",result);
                deferred.resolve();
                deferred.promise();
                var tempgrid = $('#param').dxDataGrid('instance');
                let dsparams = new CustomStore({
                    key: "parameterId",
                    // group: 'group',
                    load(loadOptions) {
                        return _this.loadTable(loadOptions, "templatechartparam");
                    },
                    byKey: function (key) {
                        // console.log("Lookup byKeytemplate"  , key);
                        // return _this.loadTable(loadOptions,'chart');
                        return $.getJSON(url + "api/trackers/parambykey/" + key);
                    }
                });
                tempgrid.dataSource = dsparams;
                tempgrid.refresh();
                // dstemplates = ApplyChanges(dstemplates, change, { keyExpr: 'templateId' });
            },
            error(err) { // console.log("error on updated data ", err);
                deferred.reject('update Error');
            }
        })
        return deferred.promise();
    }

    deselectRow(localStorageItem){
        var localStorageItem = JSON.parse(localStorage.getItem(localStorageItem)); 
        // console.log(localStorageItem);
        if(localStorageItem !== undefined && localStorageItem !== null ){
            var selectedKeys = localStorageItem.selectedRowKeys;
          //  console.log("selectedKeys", selectedKeys);
            if(selectedKeys)
            { 
                this.dxDataGridWidget.deselectRows(selectedKeys);
                  // console.log(selectedKeys);
            }
        }
    }

    renderContent(element, changeExisting) {
        var _this = this;
        if (!changeExisting) {
            while (element.firstChild)
                element.removeChild(element.firstChild);



            var div = document.createElement('div');
            div.style.width = "100%";
            div.style.height = "100%";
            element[0].appendChild(div);
            this.dxDataGridWidget = new dxDataGrid(div, this._getdxDataGridWidgetSettings());

        } else {
            this.dxDataGridWidget.option(this._getdxDataGridWidgetSettings());
        }

        if (this.getPropertyValue('EnableRowFocus') === true) {
            this._focusRow(this.dxDataGridWidget);
        }


        this.deselectRow('voiceStorage');
        this.deselectRow('packetStorage');

        // if (this.getPropertyValue('datasourceProperty') === 'pcap') {
        //     this.showFrameContent(this.dxDataGridWidget);
        // }


        if (this.enableColumns) {
            this.dxDataGridWidget.option('columns', this.getColumns());
        }

        var key = "";
    
var urlParam = window.location.search;
 if (urlParam.includes('%22%3A%22')) {
urlParam = urlParam.split('%22%3A%22');
 var mysessionguid = urlParam[1].split('%22');
key = mysessionguid[0];
 this.templateId = key;
 }

 if (this.dataSourceName === 'templatechartparam'){
    this.dxDataGridWidget.option('headerFilter.visible', false);
    this.dxDataGridWidget.option('filterPanel.visible', false);
    this.dxDataGridWidget.option('filterRow.visible', false);

 }

        if (this.dataSourceName === 'templatechartparam' && key !== "" && this.dxDataGridWidget.option("dataSource")) {
            this.dxDataGridWidget.clearFilter();
            this.dxDataGridWidget.filter(['templateId', '=', key]);
        }

        if (this.dataSourceName === 'chart' && key !== "" && this.dxDataGridWidget.option("dataSource")) { 
            this.dxDataGridWidget.clearFilter();
            this.dxDataGridWidget.filter(['templateId', '=', key]);
        }

        if (this.isDrilldownGrid) {
            if(this.dataSourceName !== 'pcap'){
                var popupContainer = document.createElement('div');
                document.body.appendChild(popupContainer);
                var popupOptions = {
                    // width: _this.drillDownPopupCustomOptions.popupWidth,
                    // height: _this.drillDownPopupCustomOptions.popupHeight,
                    width: 245,
                    height: 579,
                    dragEnabled: true,
                    resizeEnabled: true,
                    animation: null,
                    showCloseButton: true,
                    wrapperAttr: { 
                        class: "hide-overlay"
                    },
                    // onHidden: function () {
                    //     document.body.removeChild(popupContainer)
                    // },
                    // title: 'Drill Down',
                };

                if(this.dataSourceName === 'l3messageo2MO'){
                    popupOptions.position = {
                        at: 'right',
                        my: 'left',
                        of: '#MO'
                    }
                }
                if(this.dataSourceName === 'l3messageo2MT'){
                    popupOptions.position = {
                        at: 'left',
                        my: 'right',
                        of: '#MT'
                    }
                }
                var popup = new dxPopup(popupContainer, popupOptions);
              
                this.dxDataGridWidget.option('onRowDblClick', function (e) {
                    if (e.rowType === 'data') { 
                        var messageContent = e.data.messageContent.replaceAll( '|', '</br>');
                        _this._showMessageContent(popup,messageContent)

                        // var messageContent = JSON.stringify(e.data.messageContentJson);
                        // messageContent = messageContent.replaceAll( ',', '</br>');
                        // messageContent = messageContent.replaceAll('MessageContentObject:', '');
                        // messageContent = messageContent.replaceAll('\\', '');
                        // messageContent = messageContent.replaceAll('"', '');
                        // messageContent = messageContent.replaceAll('{', '');
                        // messageContent = messageContent.replaceAll('}', '');
                       
                        // _this._showMessageContent(popup,messageContent)

                    }
                });
            }

            if(this.dataSourceName === 'l3messageo2MO'){
                this.dxDataGridWidget.option('dataSource', window.$modata);
   
            }
            if(this.dataSourceName === 'l3messageo2MT'){
              //  console.log('globalMt', window.$mtdata)
                this.dxDataGridWidget.option('dataSource', window.$mtdata);
     
            }

            if(this.dataSourceName === 'pcap'){
                this.dxDataGridWidget.option('dataSource', window.$pcapdata);
            }
            this.dxDataGridWidget.option('elementAttr.class', 'drilldownGridStyle');
            this.dxDataGridWidget.option('keyExpr', ['messageId', 'timestamp']);
            this.dxDataGridWidget.option('scrolling.mode', 'virtual');
            this.dxDataGridWidget.option('scrolling.rowRenderingMode', 'virtual')
            this.dxDataGridWidget.option('pager.visible', false);
            this.dxDataGridWidget.option('pager.showInfo', true);
            this.dxDataGridWidget.option('pager.infoText', '{2} Records');
            this.dxDataGridWidget.option('pager.showPageSizeSelector', false);
            this.dxDataGridWidget.option('allowColumnResizing', true);
            this.dxDataGridWidget.option('hoverStateEnabled', false);
            this.dxDataGridWidget.option('showRowLines', true)
            // this.dxDataGridWidget.option('columnAutoWidth', true);
   
        }

        if(this.dataSourceName === 'voicetracker'){
            this.dxDataGridWidget.option('stateStoring.enabled',true) 
            this.dxDataGridWidget.option('stateStoring.type','localStorage') 
            this.dxDataGridWidget.option('stateStoring.storageKey','voiceStorage') 
            //this.dxDataGridWidget.filter(['reportStatus', '=', 'Valid by CET']);
            //var columns = this.dxDataGridWidget.option('columns');
            // columns.forEach(element => {
            //     if(element.dataField === 'reportStatus'){
            //         element.filterValue = 'Valid by CET'
            //     }
            // })
    
        }

        if(this.dataSourceName === 'packet'){
            this.dxDataGridWidget.option('stateStoring.enabled',true) 
            this.dxDataGridWidget.option('stateStoring.type','localStorage') 
            this.dxDataGridWidget.option('stateStoring.storageKey','packetStorage') 
            //this.dxDataGridWidget.filter(['reportStatus', '=', 'Valid by CET']);
            //var columns = this.dxDataGridWidget.option('columns');
            // columns.forEach(element => {
            //     if(element.dataField === 'reportStatus'){
            //         element.filterValue = 'Valid by CET'
            //     }
            // })
        }

        if (this.getPropertyValue('customizeFormItems')) {
            this._cutomizeFormItems(this.dxDataGridWidget);
        }

        if (this.getPropertyValue('enableRowTemplate')) {
            this._rowTemplate(this.dxDataGridWidget);
        }

        if (this.getPropertyValue('isMessageContentGrid')) {
            this.dxDataGridWidget.option('wordWrapEnabled', true)
            this.dxDataGridWidget.option('encodeHtml', false)
            this.dxDataGridWidget.option('pager.visible', false);
            this.dxDataGridWidget.option('headerFilter.visible', false);
            this.dxDataGridWidget.option('filterPanel.visible', false)
        }


        // if (this.getPropertyValue('datasourceProperty') === 'geotracker') {
        //     this.dxDataGridWidget.option('paging.pageSize', 15)
        // }

        var toolbarItems = this._createToolbarMenuItems(this.dxDataGridWidget);
        this.dxDataGridWidget.option('toolbar.items', toolbarItems)


       

    }

    _cutomizeFormItems(dataGrid) {
        var _this = this;
        var formItems = [];
        var formItemsString = this.getPropertyValue('formItems');
        if (formItemsString !== undefined) { // console.log(formItemsString);
            var items = formItemsString.split(',');
            var formItem = {};

            // var lookUpNamesStr = _this.lookupName;
            // var lookupColumnsStr = _this.lookupColumn;
            // var lookUpNamesArr = lookUpNamesStr.split(",");
            // var lookUpColumnArr = lookupColumnsStr.split(",");

            // loop 3l items to show in the popup
            items.forEach(item => {
                var itemTypeArr = item.split('/');
                formItem = {
                    dataField: itemTypeArr[0],
                    editorType: itemTypeArr[1]
                }

                if (itemTypeArr[1] === 'dxSelectBox' || itemTypeArr[1] === 'dxTagBox') {
                    var selectBoxItems = [];
                    if (! _this.dataSourceName.includes('/')) {
                        $.ajax({
                            type: "GET",
                            url: url + "api/trackers/" + _this.dataSourceName + itemTypeArr[0],
                            dataType: "json",
                            success: function (result, status, xhr) {
                                var dataArray = result.data;
                                for (var i = 0; i < dataArray.length; i++) {
                                    if (Object.prototype.hasOwnProperty.call(dataArray[i], itemTypeArr[0])) {
                                        var val = dataArray[i][itemTypeArr[0]];
                                        selectBoxItems.push(val);
                                    }
                                }
                            },
                            error: function (xhr, status, error) {
                                console.log("Result: " + status + " " + error + " " + xhr.status + " " + xhr.statusText)
                            }
                        });

                        formItem.editorOptions = {
                            dataSource: selectBoxItems
                        }
                    } else {
                        $.ajax({
                            type: "GET",
                            url: url + "api/trackers/" + _this.formItemsDataSource + itemTypeArr[0],
                            dataType: "json",
                            success: function (result, status, xhr) {
                                var dataArray = result.data;
                                // console.log('before',itemTypeArr[0] + '-' +selectBoxItems);

                                for (var i = 0; i < dataArray.length; i++) {
                                    if (Object.prototype.hasOwnProperty.call(dataArray[i], itemTypeArr[0])) {
                                        var val = dataArray[i][itemTypeArr[0]];
                                        // console.log(val);
                                        selectBoxItems.push(val);
                                    }
                                }
                                // console.log('after ',itemTypeArr[0] + '-' +selectBoxItems);
                            },
                            error: function (xhr, status, error) {
                                console.log("Result: " + status + " " + error + " " + xhr.status + " " + xhr.statusText)
                            }
                        });

                        formItem.editorOptions = {
                            dataSource: selectBoxItems,
                            searchEnabled: true
                        }
                    }

                }
                formItems.push(formItem);

            });
            // console.log(formItems);
            dataGrid.option('editing.form.items', formItems);


        }
    }


    drilldownGridColumnWidth(e, dataGrid) {
        if(dataGrid.option('elementAttr.id') === 'MO' || dataGrid.option('elementAttr.id') === 'MT'){
            switch (e.columnIndex) {
                case 0:
                    e.column.width = '30px'
                    break;
                case 1:
                    e.column.width = '100px';
                    break;
                case 2:
                    e.column.width = '200px'
                    break;
                case 3:
                    e.column.width = '40px'
                    break;
                case 4:
                    e.column.width = '40px'
                    break;
                case 5:
                    e.column.width = '120px'
                    break;
                case 6:
                    e.column.width = '20px'
                    break;
                default:
            }
        }else if(dataGrid.option('elementAttr.id') === 'pcap'){
            switch (e.columnIndex) {
                case 0:
                    e.column.width = '30px'
                    break;
                case 1:
                    e.column.width = '120px';
                    break;
                case 2:
                    e.column.width = '40px'
                    break;
                case 3:
                    e.column.width = '70px'
                    break;
                case 4:
                    e.column.width = '200px'
                    break;
               
                default:
            }
        }
       
    }

    _focusRow(dataGrid) { // console.log(dataGrid);
        var _this = this;
    
        dataGrid.option('onCellPrepared', function (e) {
            _this.drilldownGridColumnWidth(e, dataGrid);
            _this.MTgrid = $('#MT').dxDataGrid('instance');
            _this.MOgrid = $('#MO').dxDataGrid('instance');
            _this.MOchart = $('#MOchart').dxChart('instance');
            _this.MTchart = $('#MTchart').dxChart('instance');
            _this.pcapGrid = $('#pcap').dxDataGrid('instance');
            e.cellElement[0].className = 'drilldownGridFont';
            //add colors to message summary cells
            if (e.rowType === 'data' && e.column.dataField === 'messageSummary')  {
              //  console.log(e);
                if(e.data.messageSummary === 'Measurement Report (UL-DCCH)'){e.cellElement.addClass('yellow');}  
                if(e.data.messageSummary === 'Active Set Update (DL-DCCH)'){e.cellElement.addClass('blue');}
                if(e.data.messageSummary === 'Active Set Update Complete (UL-DCCH)'){e.cellElement.addClass('green');}
                if(e.data.messageSummary === 'RRC Connection Reconfiguration (DL-DCCH)'){e.cellElement.addClass('purple');}
                if(e.data.messageSummary === 'RRC connection Reconfiguration complete (UL-DCCH)'){e.cellElement.addClass('green');}
                if(e.data.messageSummary === 'RRC Connection Request (UL-CCCH)'){e.cellElement.addClass('blue');}
                
            }  

            if (e.rowType === 'data' && e.column.dataField === 'messageProtocol')  {
                if(e.data.messageProtocol.length > 0)
                {e.cellElement.addClass('pink');}
            }
           
        });

        
        var selectionChangedRaised = false;
        dataGrid.option('onSelectionChanged', function (e) {
            selectionChangedRaised = true;
        });


        dataGrid.option('onRowClick', function (e) { // console.log(e);
            var dataGrid1 = e.component;
            var moChart;
            var mtChart;
           // console.log(dataGrid1);
            var keys = dataGrid1.getSelectedRowKeys()[0];
            // var tiem = new Date(keys.timestamp).getTime()
             //  console.log(tiem);
           // console.log(document.getElementById('MOMap'));
              // document.getElementById('MOMap').contentWindow.addHighlightedPoints(tiem);


            if(_this.MOchart !== undefined && _this.MOchart !== null){
                if(_this.MOchart._dataSource !== undefined)
                moChart =  _this.MOchart._dataSource._items;
            }
            if(_this.MTchart !== undefined && _this.MTchart !== null){
                if(_this.MTchart._dataSource !== undefined)
                mtChart =  _this.MTchart._dataSource._items;
            }
           
            // var moChart = window.$mometrics;
            // var mtChart = window.$mtmetrics;
            if (dataGrid1._$element[0].id === 'MO') {
                var dataItemsMT= window.$mtdata;
                var dataItemsPcap =  window.$pcapdata;
            
                if(dataItemsMT !== undefined && _this.MTgrid !== undefined){
                    var rowMT = dataItemsMT.find(x => new Date(x.timestamp).getTime() - new Date(keys.timestamp).getTime() >= 0);
                    var rowIndexMT = dataItemsMT.indexOf(rowMT);
                    var currentMT = _this.MTgrid.option('focusedRowIndex');
                    if (currentMT !== rowIndexMT) {
                        _this.MTgrid.option('focusedRowIndex', rowIndexMT);
                    }
                    _this.selectPoint(moChart,keys,_this.MOchart);
                    _this.selectPoint(mtChart,keys,_this.MTchart);
                }

                if(dataItemsPcap !== undefined && _this.pcapGrid !== undefined){
                    var rowPcap = dataItemsPcap.find(x => new Date(x.timestamp).getTime() - new Date(keys.timestamp).getTime() >= 0);
                    var rowIndex = dataItemsPcap.indexOf(rowPcap);
                    var current = _this.pcapGrid.option('focusedRowIndex');
                    if (current !== rowIndex) {
                        _this.pcapGrid.option('focusedRowIndex', rowIndex);
                    }
                    _this.selectPoint(moChart,keys,_this.MOchart);
                   // _this.selectPoint(mtChart,keys,_this.MTchart);
                }
                
               
            }
            if (dataGrid1._$element[0].id === 'MT' ) {
                var dataItemsMO= window.$modata;
                var rowMO = dataItemsMO.find(x => new Date(x.timestamp).getTime() - new Date(keys.timestamp).getTime() >= 0)
                var rowMOIndex = dataItemsMO.indexOf(rowMO);
                var currentMO = _this.MOgrid.option('focusedRowIndex');
                if (currentMO !== rowMOIndex) {
                    _this.MOgrid.option('focusedRowIndex', rowMOIndex);
                }
                _this.selectPoint(moChart,keys,_this.MOchart);
                _this.selectPoint(mtChart,keys,_this.MTchart);
            }

            if (dataGrid1._$element[0].id === 'pcap' ) {
                var dataItemsMO= window.$modata;
                var rowMO = dataItemsMO.find(x => new Date(x.timestamp).getTime() - new Date(keys.timestamp).getTime() >= 0)
                var rowMOIndex = dataItemsMO.indexOf(rowMO);
                var currentMO = _this.MOgrid.option('focusedRowIndex');
                if (currentMO !== rowMOIndex) {
                    _this.MOgrid.option('focusedRowIndex', rowMOIndex);
                }
               

                //console.log(pcapdataGrid);
                var rowData = dataGrid1.getSelectedRowsData();
                 var FrameContent = rowData[0].frameContent;
               var arrayVal = [];
               var jsonOb =  JSON.parse(FrameContent.replaceAll("\\", "\\\\"));
              //console.log(jsonOb);
       for(const key in jsonOb){
           var item = {};
          for(const k in jsonOb[key]){
       
           if(!(k == 'ParentElementId' &&  jsonOb[key][k] == "0")){
               item[k] = jsonOb[key][k];
           }
           }
           arrayVal.push(item);
       }
       
                var treeView = $('#treeView').dxTreeView('instance');
          
                treeView.option('dataSource', arrayVal)
            }

            _this.selectPoint(moChart,keys,_this.MOchart);
            // }

            if (! selectionChangedRaised) {
                var keys2 = dataGrid1.getSelectedRowKeys();
                dataGrid1.deselectRows(keys2);
                dataGrid1.option('focusedRowIndex', -1)
            } else {
                
            } selectionChangedRaised = false;
        });

        // if(_this.MOchart!== undefined){
        //     _this.MOchart.option('onPointClick', function(e) {
        //         const point = e.target;
        //         console.log(point);
        //         if (point.isSelected()) {
        //           point.clearSelection();
        //         } else {
        //           point.select();
        //         }
        //       });
        // }
  
    }

    selectPoint(gridData,gridKeys,chart){
        if(gridData !== undefined && chart !== undefined){
            var point = gridData.find(x => new Date(x.timestamp).getTime() - new Date(gridKeys.timestamp).getTime() >= 0);
            var key;
        if(point !== undefined){
            key = Object.keys(point)[0];
        if(chart.getSeriesByName(key).isSelected()){
            chart.getSeriesByName(key).clearSelection();
         }else{
            chart.getSeriesByName(key).getPointsByArg(point.timestamp)[0].select();
             }
        }
        }
        
        
    }

    _rowTemplate(dataGrid) { // var _this = this;

        dataGrid.option('rowAlternationEnabled', true);
        dataGrid.option('showColumnLines', false);
        dataGrid.option('editing.mode', 'form');
        dataGrid.option('wordWrapEnabled', true);

        dataGrid.option('dataRowTemplate', function (container, info) {
            if (info.rowType === 'data') {
                var data = info.data;
                var objectDate = new Date(data.timestamp);
                var fullYear = objectDate.getFullYear();
                var month = objectDate.getMonth() + 1;
                var day = objectDate.getDate();
                var hour = objectDate.getHours();
                var minute = objectDate.getMinutes();
                // var second = objectDate.getSeconds();
                // var millisecond = objectDate.getMilliseconds();
                var newFormat = day + "/" + month + "/" + fullYear + "," + hour + ":" + minute;


                var tr1 = $('<tr>').appendTo(container);
                var tr2 = $('<tr>').appendTo(container);
                var tdUserName = $('<td>').appendTo(tr1);
                var tdTimestamp = $('<td>').appendTo(tr1);
                var tdText = $('<td colspan="3">').appendTo(tr2);
                var tdButtons = $('<td align="right">').appendTo(tr1);

                var rowContainer = $('<div >').appendTo(tdUserName);
                var rowContainer2 = $('<div >').appendTo(tdTimestamp);
                var rowContainer3 = $('<div >').appendTo(tdText);
                var rowContainer4 = $('<div >').appendTo(tdButtons);


                $('<div>').appendTo(rowContainer).append("<span>" + data.userName + "</span>");

                $('<div>').appendTo(rowContainer2).append("<span >" + newFormat + "</span>");

                $('<div>').appendTo(rowContainer3).append("<span >" + data.text + "</span>");

                var buttons = $('<div>');
                $('<div>').appendTo(rowContainer4).dxButton({ // text: 'Edit',
                    icon: 'edit',
                    onClick: function (e) {
                        dataGrid.editRow(info.rowIndex);
                        dataGrid.refresh();
                    }
                });

                $('<div>').css('margin-left', '10px').appendTo(rowContainer4).dxButton({ // text: 'Delete',
                    icon: 'close',
                    onClick: function (e) {
                        dataGrid.deleteRow(info.rowIndex);
                        dataGrid.refresh();
                    }
                });

                buttons.appendTo(rowContainer);

            }

        })
    }

//     showFrameContent(dataGrid){
//         var _this = this;
//      dataGrid.option('onRowClick', function (e) {
//         var pcapdataGrid = e.component;
//          //console.log(pcapdataGrid);
//          var rowData = pcapdataGrid.getSelectedRowsData();
//           var FrameContent = rowData[0].frameContent;
//         var arrayVal = [];
//         var jsonOb =  JSON.parse(FrameContent.replaceAll("\\", "\\\\"));
//        //console.log(jsonOb);
// for(const key in jsonOb){
//     var item = {};
//    for(const k in jsonOb[key]){

//     if(!(k == 'ParentElementId' &&  jsonOb[key][k] == "0")){
//         item[k] = jsonOb[key][k];
//     }
//     }
//     arrayVal.push(item);
// }

//          var treeView = $('#treeView').dxTreeView('instance');
   
//          treeView.option('dataSource', arrayVal)

//     });

//     }


    // getTreeViewItemChildren(parentId, json) {
    //     var items = [];
    //      var frameContent =  JSON.parse(json);
    //     for (var key in frameContent) {
    //       if (frameContent[key].ParentElementId === parentId) {
    //         items.push({
    //           id: frameContent[key].ElementId,
    //           text: frameContent[key].ShowName,
    //           expanded: false,
    //           items: this.getTreeViewItemChildren(frameContent[key].ElementId, frameContent)
    //         })
    //       }
    //     }
    //    // console.log(items);
    //     return items;
    //   }

}

class CustomGrid {
    constructor(dashboardControl) {
        ResourceManager.registerIcon(svgIcon);
        this.name = "customGridCustomItem";
        this.metaData = CustomGridMetaData;
    }

    createViewerItem(model, $element, content) {
        return new CustomGridViewer(model, $element, content);
    }
}

// render() {
//     return (
//         <DataGrid
//             dataSource={customDataSource}>
//             <RemoteOperations
//                 groupPaging={true} />
//         </DataGrid>
//     );
// }
// }
export default CustomGrid;

