import { CustomItemViewer } from 'devexpress-dashboard/common'
import { CustomItem } from 'devexpress-dashboard/model'


const WEBPAGE_EXTENSION_NAME = 'kepler';
const svgIcon = `<?xml version="1.0" encoding="utf-8"?>        
    <svg version="1.1" id="` + WEBPAGE_EXTENSION_NAME + `" xmlns = "http://www.w3.org/2000/svg" xmlns: xlink = "http://www.w3.org/1999/xlink" x = "0px" y = "0px" viewBox = "0 0 24 24" style = "enable-background:new 0 0 24 24;" xml: space = "preserve" >
        <path class="dx-dashboard-contrast-icon" d="M20.7,4.7l-3.4-3.4C17.1,1.1,16.9,1,16.6,1H4C3.4,1,3,1.4,3,2v20c0,0.6,0.4,1,1,1h16
        c0.6,0,1-0.4,1-1V5.4C21,5.1,20.9,4.9,20.7,4.7z M19,21H5V3h11v2c0,0.6,0.4,1,1,1h2V21z"/>
        <path class="dx-dashboard-accent-icon" d="M13.7,17.5c-0.2-0.4-1.6-1.8-1.4-2.2s0.2-1.1-0.1-1.3c-0.3-0.1-0.7,0.1-0.7-0.2
        c-0.1-0.3-1.1-0.2-1.2-1.6c-0.1-1.5-0.6-2-1.2-2s-1.6,0.6-1.5,0c0-0.1,0-0.2,0-0.3c-1,1-1.6,2.5-1.6,4.1c0,3.3,2.7,6,6,6
        c0.6,0,1.1-0.1,1.6-0.2C13.7,19.1,13.9,17.8,13.7,17.5z M12,8c-1.1,0-2.2,0.3-3.1,0.9H9c1,0.2,3.1,0.7,3.1,0.3S12,8.3,12.2,8.4
        c0.2,0.2,0.8,0.7,0.6,1S12,10,12.2,10.3c0.2,0.2,0.8,0.6,1,0.4s-0.1-0.9,0.2-0.8c0.3,0,1.8,0.8,1.3,1.1s-1.4,1.9-1.9,2
        s-0.9,0.2-0.8,0.6c0.2,0.5,0.5,0.2,0.7,0.3c0.1,0.1,0.1,0.4,0.3,0.6s0.4,0.1,0.7,0.1c0.3-0.1,2.5,0.9,2.3,1.4
        c-0.2,0.5-0.2,1.2-1,2.1c-0.5,0.5-0.7,1.1-0.9,1.5c2.3-0.8,4-3,4-5.6C18,10.7,15.3,8,12,8z"/>
    </svg>`;

    const keplerPageMetadata = {
    customProperties: [{
        ownerType: CustomItem,
        propertyName: 'Url',
        valueType: 'string',
    },{
        ownerType: CustomItem,
        propertyName: 'Id',
        valueType: 'string',
    }
],
    optionsPanelSections: [{
        title: 'Custom Options',
        items: [{
            dataField: 'Url',
            editorType: 'dxTextBox',
            editorOptions: {
                placeholder: "Enter kepler link to display"
            }
        },
        {
            dataField: 'Id',
            editorType: 'dxTextBox',
            editorOptions: {
                placeholder: "Enter iframe id"
            }
        }]
    }],
    icon: WEBPAGE_EXTENSION_NAME,
    title: 'kepler',
    index: 2
};



class KeplerItemViewer extends CustomItemViewer {
    constructor(model, $container, options) {        
        super(model, $container, options);
    }

     GetQueryStringParams(sParam) {
        var sPageURL = window.location.search.substring(1);
        var sURLVariables = sPageURL.split('&');
        for (var i = 0; i < sURLVariables.length; i++) {
            var sParameterName = sURLVariables[i].split('=');
            if (sParameterName[0] == sParam) {
                return decodeURIComponent(sParameterName[1]);
            }
        }
       return "";
    }

    renderContent(element, changeExisting) {
        var url = this.getPropertyValue('Url');
        // let contentWidth = this.contentWidth(),
        //     contentHeight = this.contentHeight();
    //     var keplerElement =  document.createElement("div");
    //     keplerElement.setAttribute('id', 'kepler');
    //    // keplerElement.setAttribute('class', 'tableauPlaceholder');
    //    keplerElement.style.height = contentHeight + 'px';
    //    keplerElement.style.width = contentWidth + 'px';
            while (element.firstChild)
                element.removeChild(element.firstChild);
            this.iframe = document.createElement('iframe');
            this.iframe.id = this.getPropertyValue('Id');
            this.iframe.width = '100%';
            this.iframe.height = '100%';
            // this.iframe.width = contentWidth;
            // this.iframe.height = contentHeight;
            this.iframe.style.border = "none";
            element[0].appendChild(this.iframe);


            if(url !== undefined){
             this.iframe.src = this.getPropertyValue('Url');
            }

            if(this.iframe.id !== 'gismap'){

                var urlParam = window.location.search;
            //dashboardId=VoiceTracker_&dashboardState=%7B%22Parameters%22%3A%7B%22MOlogfile%22%3A%22O2CET_NUC1_1_EQ1_M2MVoice_20220427095142%22%2C%22MTlogfile%22%3A%22O2CET_NUC2_1_EQ1_M2MVoice_20220427095143%22%2C%22Timestamp%22%3A%222022-04-27T09%3A57%3A21.860%22%7D%7D
            var MOmap = document.getElementById('MOMap');
            var MTmap = document.getElementById('MT_Map');
            var MOmapPacket = document.getElementById('MOmapPacket');
            var timeBefore = this.GetQueryStringParams('timeBefore');
            var timeAfter = this.GetQueryStringParams('timeAfter');
       

           var mofile =  this.GetQueryStringParams('MoDriveTestLogFile');
            var mtfile =  this.GetQueryStringParams('MtDriveTestLogFile');
           var failuretimestamp = this.GetQueryStringParams('FailureTimestamp');
           var starttimestamp = this.GetQueryStringParams('StartTimestamp');
           var endtimestamp = this.GetQueryStringParams('EndTimestamp');
           var moFilePacket = this.GetQueryStringParams('DriveTestLogFileName');
      

            if(MOmap !== null){
                MOmap.src = 'https://insight2-kepler.azurewebsites.net/?timeBefore=' + timeBefore+ '&timeAfter=' + timeAfter+ '&logFile=' + mofile + '&timeStamp='+ failuretimestamp + '&starttimestamp=' + starttimestamp + '&endtimestamp=' + endtimestamp
                //MOmap.src = 'https://insight2-kepler.azurewebsites.net/map?logFile=' + mofile + '&timeStamp='+ failuretimestamp
                //console.log('MO',MOmap.src);
            }
            if(MTmap !== null){
                MTmap.src = 'https://insight2-kepler.azurewebsites.net/?timeBefore=' + timeBefore+ '&timeAfter=' + timeAfter+ '&logFile=' + mtfile + '&timeStamp='+failuretimestamp+ '&starttimestamp=' + starttimestamp + '&endtimestamp=' + endtimestamp
               // console.log('MT',MTmap.src);
            }
            if(MOmapPacket !== null){
                MOmapPacket.src = 'https://insight2-kepler.azurewebsites.net/?timeBefore=' + timeBefore+ '&timeAfter=' + timeAfter+ '&logFile=' + moFilePacket + '&timeStamp='+ failuretimestamp+ '&starttimestamp=' + starttimestamp + '&endtimestamp=' + endtimestamp
                //MOmap.src = 'https://insight2-kepler.azurewebsites.net/map?logFile=' + mofile + '&timeStamp='+ failuretimestamp
               // console.log('MOpacket',MOmapPacket.src);
            }
            }
            
    }
}
class KeplerItem {
    constructor(dashboardControl) {
        dashboardControl.registerIcon(svgIcon);    
        this.name = WEBPAGE_EXTENSION_NAME;
        this.metaData = keplerPageMetadata;
    }

    createViewerItem(model, $element, content) {
        return new KeplerItemViewer(model, $element, content);
    }
}


export default KeplerItem;