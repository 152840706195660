import { Dashboard, CustomItem } from 'devexpress-dashboard/model'
import { CustomItemViewer, ResourceManager } from 'devexpress-dashboard/common'
import { FormItemTemplates } from 'devexpress-dashboard/designer'
import dxButton from 'devextreme/ui/button'
import dxPopup from 'devextreme/ui/popup'
import GridPaginationOptionsExtension from './GridCustomOptionsExtension'
import dxDashboardControl from 'devexpress-dashboard/integration';
import CustomGrid from './CustomGrid'


var svgIcon = '<svg id="funnelChartItemIcon" viewBox="0 0 24 24"><path stroke="#ffffff" fill="#f442ae" d="M12 2 L2 22 L22 22 Z" /></svg>';
var buttonItemMetaData = {
	// bindings: [{
	// 	propertyName: 'measureValue',
	// 	dataItemType: 'Measure',
	// 	displayName: 'Value'
	// }, {
	// 	propertyName: 'dimensionValue',
	// 	dataItemType: 'Dimension',
	// 	displayName: 'Argument',
	// 	enableColoring: true,
	// 	enableInteractivity: true
	// }],
	// interactivity: {
	// 	filter: true
	// },
	customProperties: [{
		ownerType: CustomItem,
		propertyName: 'dashboardIdProperty',
		valueType: 'string',
		defaultValue: 'Inside'
	}],
	optionsPanelSections: [{
		title: 'Dashboard Id',
		items: [{
			dataField: 'dashboardIdProperty',
			label: {
				text: 'Dashboard Id'
			},
            editorType: 'dxTextBox',
            editorOptions: {
                placeholder: "Enter tableau link to display"
            }
		}]
	}],
	icon: 'funnelChartItemIcon',
	title: 'Button'
};




class ButtonItemViewer extends CustomItemViewer {
	constructor(model, $container, options) {		
        super(model, $container, options);
        this.dxButton = null;
        this.dxButtonSettings = undefined;
	}

    // _getDataSource() {
    //     var clientData = [];
    //     if (this.getBindingValue('measureValue').length > 0) {
    //         this.iterateData(function (dataRow) {
    //             clientData.push({
    //                 measureValue: dataRow.getValue('measureValue')[0],
    //                 dimensionValue: dataRow.getValue('dimensionValue')[0] || '',
    //                 dimensionDisplayText: dataRow.getDisplayText('dimensionValue')[0],
    //                 measureDisplayText: dataRow.getDisplayText('measureValue')[0],
    //                 dimensionColor: dataRow.getColor('dimensionValue')[0],
    //                 clientDataRow: dataRow
    //             });
    //         });
    //     }
    //     return clientData;
    // };

    _getDxButtonSettings() {
        var _this = this;
        var dashboardId = _this.getPropertyValue('dashboardIdProperty');
        return {
           // dataSource: this._getDataSource(),
           text:"Add",
           type:"normal",
            onClick: function (e) {
           //   openPopup(dashboardId);
          // console.log(dashboardId);
           var popupContainer = document.createElement('div');
           document.body.appendChild(popupContainer);
           var popupOptions = { 
               width : '80vw',
               height : '80vh',
               dragEnabled:true,
               resizeEnabled: true,
              // showTitle:true,
               position: {
                   at: 'center',
                   my: 'center',
                  // of: '.dx-dashboard-item'
                 },
              // hideOnOutsideClick: true,
               showCloseButton:true,
               wrapperAttr: {
                   // id: "elementId",
                   class: "hide-overlay"
               },
               contentTemplate: function(contentContainer) {
                 //  console.log(contentContainer);
                   var dashboardContainer = document.createElement('div');
                   dashboardContainer.id = 'webDashboard';
                   dashboardContainer.style.position = 'relative';
                   dashboardContainer.style.width = '100%';
                   dashboardContainer.style.height = '100%';
                   dashboardContainer.style.left = 0;
                   dashboardContainer.style.right = 0;
                   dashboardContainer.style.top = 0;
                   dashboardContainer.style.bottom = 0;
                   var dashboardOptions = {
                    //endpoint: 'http://localhost:5000/api/dashboard',
                   endpoint: window.endpoint,
                // endpoint : 'https://localhost:44361/api/dashboard',
                   // onItemEndUpdate : onItemEndUpdate ,
                    initialDashboardId: dashboardId,
                    workingMode: "ViewerOnly",
                    extensions: {
                        "urlState": {
                            includeDashboardStateToUrl: true
                        }
                    }
                   }
                   var parameterDashboard  = new dxDashboardControl(dashboardContainer, dashboardOptions);   
                   parameterDashboard.registerExtension(new GridPaginationOptionsExtension(parameterDashboard));
                   parameterDashboard.registerExtension(new CustomGrid(parameterDashboard));
                   return dashboardContainer;
               },
               onHidden: function() {
                   document.body.removeChild(popupContainer)
               },
               // title: 'Drill Down',
           };
           var popup = new dxPopup(popupContainer, popupOptions);
           popup.show();

            }
        };
    }

    // setSelection() {
    //     var _this = this;
    //     this.dxFunnelWidget.getAllItems().forEach(function (item) {
    //         item.select(_this.isSelected(item.data.clientDataRow));
    //     });
    // }

    // clearSelection() {
    //     this.dxFunnelWidget.clearSelection();
    // }

    // setSize(width, height) {
    //     Object.getPrototypeOf(ButtonItemViewer.prototype).setSize.call(this, width, height);
    //     this.dxButton.render();
    // }


    renderContent(element, changeExisting) {
        if (!changeExisting) {

            while (element.firstChild)
                element.removeChild(element.firstChild);

            var div = document.createElement('div');
            div.style.width = "100%";
            div.style.height = "100%";
            element[0].appendChild(div);
            this.dxButton = new dxButton(div, this._getDxButtonSettings());
        } else {
            this.dxButton.option(this._getDxButtonSettings());
        }
    }
}
class ButtonItem {
    constructor(dashboardControl) {
        ResourceManager.registerIcon(svgIcon);
        this.name = "buttonCustomItem";
		this.metaData = buttonItemMetaData;
    }

    createViewerItem(model, $element, content) {
        return new ButtonItemViewer(model, $element, content);
    }
}

export default ButtonItem;