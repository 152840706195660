import { CustomItemViewer } from 'devexpress-dashboard/common'
import { CustomItem } from 'devexpress-dashboard/model'


const WEBPAGE_EXTENSION_NAME = 'WebPage';
const { tableau } = window;
const svgIcon = `<?xml version="1.0" encoding="utf-8"?>
    <svg version="1.1" id="` + WEBPAGE_EXTENSION_NAME + `" xmlns = "http://www.w3.org/2000/svg" xmlns: xlink = "http://www.w3.org/1999/xlink" x = "0px" y = "0px" viewBox = "0 0 24 24" style = "enable-background:new 0 0 24 24;" xml: space = "preserve" >
        <path class="dx-dashboard-contrast-icon" d="M20.7,4.7l-3.4-3.4C17.1,1.1,16.9,1,16.6,1H4C3.4,1,3,1.4,3,2v20c0,0.6,0.4,1,1,1h16
        c0.6,0,1-0.4,1-1V5.4C21,5.1,20.9,4.9,20.7,4.7z M19,21H5V3h11v2c0,0.6,0.4,1,1,1h2V21z"/>
        <path class="dx-dashboard-accent-icon" d="M13.7,17.5c-0.2-0.4-1.6-1.8-1.4-2.2s0.2-1.1-0.1-1.3c-0.3-0.1-0.7,0.1-0.7-0.2
        c-0.1-0.3-1.1-0.2-1.2-1.6c-0.1-1.5-0.6-2-1.2-2s-1.6,0.6-1.5,0c0-0.1,0-0.2,0-0.3c-1,1-1.6,2.5-1.6,4.1c0,3.3,2.7,6,6,6
        c0.6,0,1.1-0.1,1.6-0.2C13.7,19.1,13.9,17.8,13.7,17.5z M12,8c-1.1,0-2.2,0.3-3.1,0.9H9c1,0.2,3.1,0.7,3.1,0.3S12,8.3,12.2,8.4
        c0.2,0.2,0.8,0.7,0.6,1S12,10,12.2,10.3c0.2,0.2,0.8,0.6,1,0.4s-0.1-0.9,0.2-0.8c0.3,0,1.8,0.8,1.3,1.1s-1.4,1.9-1.9,2
        s-0.9,0.2-0.8,0.6c0.2,0.5,0.5,0.2,0.7,0.3c0.1,0.1,0.1,0.4,0.3,0.6s0.4,0.1,0.7,0.1c0.3-0.1,2.5,0.9,2.3,1.4
        c-0.2,0.5-0.2,1.2-1,2.1c-0.5,0.5-0.7,1.1-0.9,1.5c2.3-0.8,4-3,4-5.6C18,10.7,15.3,8,12,8z"/>
    </svg>`;

const webPageMetadata = {
    // bindings: [{
    //     propertyName: 'Attribute',
    //     dataItemType: 'Dimension',
    //     array: false,
    //     displayName: "Attribute",
    //     emptyPlaceholder: 'Set Attribute',
    //     selectedPlaceholder: "Configure Attribute"
    // }],
    // interactivity: {
    //     filter: true
    // },
    customProperties: [{
        ownerType: CustomItem,
        propertyName: 'Url',
        valueType: 'string',
        defaultValue: 'https://prod-uk-a.online.tableau.com',
        },
        {
            ownerType: CustomItem,
            propertyName: 'Id',
            valueType: 'string',
           // defaultValue: 'https://prod-uk-a.online.tableau.com',
            }],
    optionsPanelSections: [{
        title: 'Custom Options',
        items: [{
            dataField: 'Url',
            editorType: 'dxTextBox',
            editorOptions: {
                placeholder: "Enter tableau link to display"
            }
        },
        {
            dataField: 'Id',
            editorType: 'dxTextBox',
            editorOptions: {
                placeholder: "Item Id"
            }
        }
    ]
    }],
    icon: WEBPAGE_EXTENSION_NAME,
    title: 'Tableau page',
    index: 2
};

class WebPageItemViewer extends CustomItemViewer {
    constructor(model, $container, options) {
        super(model, $container, options);
        this.mapViewer = null;
    }

    renderContent(element, changeExisting) {

        var url = this.getPropertyValue('Url');

        let contentWidth = this.contentWidth(),
            contentHeight = this.contentHeight();

        // var tableauElement =  document.createElement("div");
        // tableauElement.setAttribute('id', 'vizContainer');
        // tableauElement.setAttribute('class', 'tableauPlaceholder');
        // tableauElement.style.height = contentHeight + 'px';
        // tableauElement.style.width = contentWidth + 'px';

        // tableauElement.style.maxWidth = contentWidth + 'px';
        // tableauElement.style.maxHeight = contentHeight + 'px';

         if(!changeExisting || !this.iframe) {
            while (element.firstChild)
            element.removeChild(element.firstChild);
            this.iframe = document.createElement('iframe');
           
            this.iframe.id = this.getPropertyValue('Id');
            this.iframe.width = '100%';
            this.iframe.height = '100%';
            this.iframe.style.border = "none";
            element[0].appendChild(this.iframe);
            this.iframe.src = url;
        }
        

//         var containerDiv = document.getElementById("vizContainer");
//         //var url = "https://prod-uk-a.online.tableau.com/t/fusatuk22/views/Operator-dashboard2/Dashboard1?:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link";
// if(url !== undefined){
//     var viz = new tableau.Viz(containerDiv, url);
// }

//console.log(tableauElement);

    }
    //     setSize(width, height) {
    //     super.setSize(width, height);
    //     let contentWidth = this.contentWidth(),
    //         contentHeight = this.contentHeight();
    //         var containerDiv = document.getElementById("vizContainer");
    //         //console.log(containerDiv.children);
    //         //console.log(contentWidth);
    //         //console.log(contentHeight);

    //     // this.mapViewer.option('width', contentWidth);
    //     // this.mapViewer.option('height', contentHeight);
    // }
}
class WebPageItem {
    constructor(dashboardControl) {
        dashboardControl.registerIcon(svgIcon);
        this.name = WEBPAGE_EXTENSION_NAME;
        this.metaData = webPageMetadata;
    }

    createViewerItem(model, $element, content) {
        return new WebPageItemViewer(model, $element, content);
    }
}


export default WebPageItem;