// import Greetings from './Greetings';
import React from 'react';
import DashboardControl from 'devexpress-dashboard-react';
// import { ReactKeycloakProvider } from "@react-keycloak/web";
// import keycloak from "./keycloak";
import {DashboardPanelExtension, DashboardPrivateSettings} from 'devexpress-dashboard/common';
import WebPageItem from './WebPageItem';
import OnlineMapItem from './OnlineMapItem';
import RangeSliderItem from './RangeSlider';
import GridPaginationOptionsExtension from './GridCustomOptionsExtension';
// import {FormItemTemplates} from 'devexpress-dashboard/designer'
// import {ChartItem, ComboBoxItem, GridItem} from 'devexpress-dashboard/model';
import * as Model from 'devexpress-dashboard/model';
import './App.css';
import KeplerItem from './keplerItem';
// import LoginForm from './components/LoginForm';
import ButtonItem from './ButtonCustomItem';
import CustomGrid from './CustomGrid';
import LineChartItem from './CustomChart';
import LoadDrillDown from "./components/loadDrillDownData"
import LoadDrillDownPacket from "./components/loadDrillDownPacketData"
import TreeViewItem from './customTreeView';
import SelectBoxItem from './customSelectBox';
// import PrivateRoute from "./PrivateRoute";
// import './keycloakHelper.js';
import './global';

var dashboardPanel;
var dashboardControl;
// var restore = false;
var showpanel = window.showpanel;
// var comboBoxIdProperty = {
//     ownerType: Model.ComboBoxItem,
//     propertyName: 'comboBoxId',
//     //defaultValue: false,
//     valueType: 'string'
// };


//  initKeycloak();
const dashboardControlRef = React.createRef();


// function reloadData() {
//   dashboardControlRef.current.instance.reloadData();open
// };

function onBeforeRender(e) {

    dashboardControl = e.component;
    //    sessionStorage.setItem('dashboardControl',dashboardControl);
    // console.log(e);
    // dashboardControl.loadDashboard("dashboard13");
    //    .done(function () { alert("A dashboard is loaded successfully."); })
    //    .fail(function() { alert("Cannot load a dashboard."); });

    dashboardControl.registerExtension(new WebPageItem(dashboardControl));
    //dashboardControl.registerExtension(new OnlineMapItem(dashboardControl));
    //dashboardControl.registerExtension(new RangeSliderItem(dashboardControl));
    dashboardControl.registerExtension(new KeplerItem(dashboardControl));
   // dashboardControl.registerExtension(new GridPaginationOptionsExtension(dashboardControl));
    dashboardControl.registerExtension(new ButtonItem(dashboardControl));
    dashboardControl.registerExtension(new CustomGrid(dashboardControl));
    dashboardControl.registerExtension(new LineChartItem(dashboardControl));
    dashboardControl.registerExtension(new TreeViewItem(dashboardControl));
    dashboardControl.registerExtension(new SelectBoxItem(dashboardControl));
    var dashboardPrivateSettings = new DashboardPrivateSettings(dashboardControl);
    // console.log(dashboardPrivateSettings);
    dashboardPrivateSettings.customNumericFormatMode = "Enabled";
    dashboardControl.registerExtension(dashboardPrivateSettings);
    // Subscribe to the DashboardInitialized event:
    dashboardControl.on('dashboardInitialized', onDashboardInitialized)


    var UrlStateExtension = dashboardControl.findExtension('url-state');
    UrlStateExtension._options.includeDashboardIdToUrl = true;
    UrlStateExtension._options.includeDashboardStateToUrl = false;

    var viewerApiExtension = dashboardControl.findExtension('viewer-api');
    if (viewerApiExtension) {
        viewerApiExtension.on('itemWidgetOptionsPrepared', onItemWidgetOptionsPrepared);
        viewerApiExtension.on('itemCaptionToolbarUpdated', onItemCaptionToolbarUpdated);
        viewerApiExtension.on('dashboardTitleToolbarUpdated', onDashboardTitleToolbarUpdated);

    }
    


    

    // dynamic constant lines
    // var optionsPanelExtension = dashboardControl.findExtension("item-options-panel");
    // if (optionsPanelExtension) {
    //     optionsPanelExtension.on('customizeSections', onCustomizeSections)
    // }

    // dashboard panel
    //    var url = window.location.href;
    //     if (url.includes("stc")) {
    //         showpanel = false;

    //     }
    if (dashboardControl != null) { // console.log(dashboardControl);
        if (dashboardControl.allowSwitchToDesigner && showpanel) {
            dashboardPanel = new DashboardPanelExtension(dashboardControl);
            dashboardPanel.panelWidth = 140; // change panel's width here
            dashboardControl.surfaceLeft(dashboardPanel.panelWidth);
            dashboardControl.registerExtension(dashboardPanel);
        }
    }


    // customize datasource wizard
    var dataSourceWizardExtension = dashboardControl.findExtension('data-source-wizard');
    if (dataSourceWizardExtension) {
        dataSourceWizardExtension.on('customizeDataSourceWizard', customizeDataSourceWizard)
    }
}


function onDashboardInitialized(args) {

    //    var items = args.dashboard.items();
    //    //console.log(items);
    //    var itemType;

    //    for (var i=0; i< items.length; i++){
    //     itemType = items[i].itemType();

    //     if( itemType === 'CustomItem'){
    //        var customItemType = items[i]._meta.customItemType;
    //        if(customItemType === 'WebPage'){
    //         var url= items[i].customProperties.Url();
    //        }
    //     }
    //    }

    // if(args.dashboard.dashboardJSON.Title["@Text"] === 'tableau'){
    //     var tableauURL = args.dashboard.dashboardJSON.Items.Item2.CustomProperties.Url;
    //     console.log(tableauURL);
    // }

}

function onDashboardTitleToolbarUpdated(e) {
    if (showpanel) {
        e.options.navigationItems.push({
            type: "button",
            icon: "dx-dashboard-designer-menu-icon",
            hint: "Menu",
            click: function (element) {
                if (dashboardPanel.visible() === false && dashboardControl.isDesignMode() === false) {
                    dashboardPanel.showPanelAsync({}).done(function (e) {
                        dashboardControl.surfaceLeft(e.surfaceLeft);
                    });
                } else if (dashboardPanel.visible() === true && dashboardControl.isDesignMode() === false) {
                    dashboardPanel.hidePanelAsync({}).done(function (e) {

                        dashboardControl.surfaceLeft(e.surfaceLeft);
                    });
                }
            }
        });
    }

    // e.options.actionItems.push(
    //     { type: "button",
    //      icon: "reload",
    //       hint: "Refresh",
    //       click: function (element)
    //       {    dashboardControl.reloadData();
    //      },
    //      template: function() {
    //         var titleHeight = 20;
    //         var imageSrs = 'http://www.free-icons-download.net/images/blue-play-button-icon-17903.png';
    //         var $image = $('<img>').attr("src", imageSrs).height(titleHeight + 'px');
    //         $image.on('load', function () {
    //             let imageHeight = $image.height();
    //             if (imageHeight > titleHeight) {
    //                 $image.width(Math.floor($image.width() * (titleHeight / imageHeight)));
    //                 $image.height(titleHeight);
    //             } else {
    //                 $image.css('margin-top', Math.ceil((titleHeight - imageHeight) / 2));
    //             }
    //             $image.css({ visibility: 'visible' });
    //         });

    //         return $image;
    //     }
    //     });

    // e.toolbarOptions.items.unshift({
    //     location: "after",
    //     widget: "dxButton",
    //     options: {
    //         icon: "refresh",
    //         onClick: function() {
    //             console.log(e);
    //             dataGrid.refresh();
    //         }
    //     }
    // });

    // console.log(args.options);
}

function onItemCaptionToolbarUpdated(e, s) { // remove maximize
    if (e.itemName === "customItemDashboardItem1" || e.itemName === "customItemDashboardItem3" || e.itemName === "customItemDashboardItem4" || e.itemName === "customItemDashboardItem5" || e.itemName === "customItemDashboardItem6") {

        var target = e.options.actionItems.find(function (element) {
            return element.name === "maximize-down-item";
        });

        var index = e.options.actionItems.indexOf(target);
        if (index > -1) {
            e.options.actionItems.splice(index, 1);
        }
    }


    // add minimize
    // e.options.actionItems.push({
    //     type: "button",
    //     icon: "base-triangle",
    //     hint: "Minimize",
    //     click: function () {
    //         // DevExpress.ui.notify("The component name of this dashboard item is " + e.itemName, "info");

    //         console.log(e);
    //         console.log(e.parentContainer);
    //     }
    // });

}


function onItemWidgetOptionsPrepared(args) {

   
    // if (args.dashboardItem instanceof ChartItem) {

    //     var serializedConstantLines = args.dashboardItem.customProperties.getValue(chartConstantLinesProperty.propertyName);
    //     var constantLines = JSON.parse(serializedConstantLines);

    //     var valueAxisOptions = args.options["argumentAxis"] || [];

    //     constantLines.forEach(function (line) { // console.log(line);
    //         var axisOptions = valueAxisOptions;
    //         if (axisOptions) {
    //             var value = line.value;

    //             if (line.isBound) {
    //                 var point = args.itemData.getAxis('Argument').getPoints();
    //                 for (var i = 0; i < point.length; i++) { // console.log(args.itemData.getAxis('Argument').getPoints()[i].getDimensionValue(line.measureId));
    //                 }

    //                 // value = args.itemData.getDimensionValue(line.measureId).getValue()
    //             }axisOptions.constantLines = axisOptions.constantLines || [];
    //             axisOptions.constantLines.push({
    //                 value: value,
    //                 color: line.color,
    //                 dashStyle: 'solid',
    //                 width: 1,
    //                 label: {
    //                     text: line.labelText
    //                 }
    //             });
    //         }
    //     });

    //     // args.options.commonSeriesSettings = {
    //     //     aggregation : {
    //     //       enabled : true
    //     // }
    //     // }
    //     // fullstackedbar: {
    //     //     // Enables data aggregation for all Full-Stacked Bar series
    //     //     aggregation: {
    //     //         enabled: true
    //     //     }
    //     // }


    //     // args.options.argumentAxis.aggregationInterval = 'year';

    //     // args.options.series = {
    //     // aggregation: {
    //     //     enabled: true,
    //     //     method: 'sum'

    //     // }
    //     // };
    //     // args.options.dataSource = [
    //     // {
    //     //     arg : 'VF',
    //     //     val : 323
    //     // },
    //     // {
    //     //     arg : 'VF',
    //     //     val : 347
    //     // },
    //     // {
    //     //     arg : 'VF',
    //     //     val : 2850
    //     // },
    //     // {
    //     //     arg : 'VF',
    //     //     val : 6300
    //     // },
    //     // {
    //     //     arg : 'VF',
    //     //     val : 1288
    //     // },
    //     // {
    //     //     arg : 'O2',
    //     //     val : 199
    //     // },
    //     // {
    //     //     arg : 'O2',
    //     //     val : 1226
    //     // },
    //     // {
    //     //     arg : 'O2',
    //     //     val : 6400
    //     // },
    //     // {
    //     //     arg : 'O2',
    //     //     val : 39250
    //     // },
    //     // {
    //     //     arg : 'O2',
    //     //     val : 39448
    //     // }];
    //     // args.options.argumentAxis.categories = [323,347,2850,6300,1288,199,1226,6400,39250,39448,3700,1228,6225,3350,3179,1815,1761,1617,522,6175,1392,99];
    //     // args.options.argumentAxis.aggregateByCategory = true;
    //     // args.options.argumentAxis.argumentField = 'operator';
    //     // args.options.argumentAxis.visualRange  = [99, 6300];
    //     // args.options.argumentAxis.type = 'continuous';
    //     // args.options.argumentAxis.argumentType = 'numeric';


    //     // args.options.valueAxis[0].categories = ['3UK', 'EE', 'VF', 'O2'];
    //     // args.options.valueAxis[0].valueField = 'Frequency';
    //     args.options.valueAxis[0].constantLines = [
    //         {
    //             label: {
    //                 visible: false
    //             },
    //             width: 1,
    //             value: 1,
    //             dashStyle: 'solid'

    //         }, {
    //             label: {
    //                 visible: false
    //             },
    //             width: 1,
    //             value: 0,
    //             dashStyle: 'solid'

    //         }
    //     ];
    // }

    if (args.dashboardItem instanceof Model.GridItem) {
        // console.log(args);

        // args.options.allowColumnResizing =  true;
        // args.options.columnResizingMode = 'nextColumn';
         args.options.columnAutoWidth = true;
        args.options.filterPanel = {
            visible: true
        };
        // args.options.focusedRowEnabled = true;
        // args.options.focusedRowIndex = 0;

        // args.options.stateStoring = {
        //     enabled: true,
        //     type: 'localStorage',
        //     storageKey: 'storage',
        // };

        // args.options.pager = {
        // showNavigationButtons: true
        // };

        args.options.columnChooser = {
            enabled: true,
            mode: 'select'
        };

        args.options.allowColumnReordering = true;


        args.options.groupPanel = {
            visible: true
        };

        args.options.grouping = {
            autoExpandAll: false
        };

        // args.options.stateStoring = {
        //     enabled: true,
        //     type: 'localStorage',
        //     storageKey: 'storage',
        // };
        args.options.toolbar = {
            items: ['columnChooserButton', 'groupPanel', 'searchPanel', 'addRowButton',]
        };

    }

    // if(args.dashboardItem instanceof ComboBoxItem){
    //     //  console.log("data items: ",args.dashboardItem.dataItems())
    //     //  console.log("showAllValue: ",args.dashboardItem.showAllValue())
    //     Model.registerCustomProperty(comboBoxIdProperty);
    //     var comboBoxId = args.dashboardItem.customProperties.getValue(comboBoxIdProperty.propertyName);
    //     console.log(comboBoxId);
    //     if(comboBoxId == 'MO'){
    //         args.options.onValueChanged = function(e) {
    //            // console.log(e);
              
    //           // console.log(args.dashboardItem.hiddenMeasures()[0].dataMember()); 
    //           // console.log(args.dashboardItem.hiddenMeasures()[0]._model);
    //             var index = e.value;
    //             var ds = e.component.getDataSource();
    //           //console.log(ds);
    //           var templateName =  ds._items[index].value[0];
    //           $.ajax({
    //             type: "GET",
    //             url: "https://localhost:44361/api/trackers/template/"+ templateName,
    //             dataType: "json",
    //             success: function (result, status, xhr) {
    //            // console.log(result);
    //             var dataArray = result.data;
    //            // console.log(dataArray);
    //             var queryString = '';
    //           for(var i= 0; i < dataArray.length; i++){
          
    //              queryString += '&Param' +(i+1)+ '=' + dataArray[i].parameterNames;
    //           }
    //         //   var urlMO = 'https://prod-uk-a.online.tableau.com/t/fusatuk22/views/Parameters/Sheet1?:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link&:embed=y&:toolbar=false';
    //         // //  var urlMT = 'https://prod-uk-a.online.tableau.com/t/fusatuk22/views/Parameters/Sheet1?:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link&:embed=y&:toolbar=false';
    //         //   document.getElementById('MoChart').src = urlMO + queryString;
    //         //  // document.getElementById('MTchart').src = urlMT + queryString;
         
    //             },
    //             error: function (xhr, status, error) {
    //                 alert("Result: " + status + " " + error + " " + xhr.status + " " + xhr.statusText)
    //             }
    //         });
  
    //          }
    //     }else if(comboBoxId == 'MT'){
    //         args.options.onValueChanged = function(e) {
    //           //  console.log(e);
    //             var index = e.value;
    //             var ds = e.component.getDataSource();
    //          // console.log(ds);
    //             var parameters =  ds._items[index].value[2];
    //         // console.log(parameters);
    //           var splitParam =  parameters.split(',');
    //           var queryString = '';
    //           for(var i= 0; i < splitParam.length; i++){
    //              queryString += '&Param' +(i+1)+ '=' + splitParam[i];
                 
    //           }
    //          // var urlMO = 'https://prod-uk-a.online.tableau.com/t/fusatuk22/views/Parameters/Sheet1?:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link&:embed=y&:toolbar=false';
    //           var urlMT = 'https://prod-uk-a.online.tableau.com/t/fusatuk22/views/Parameters/Sheet1?:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link&:embed=y&:toolbar=false';
    //         //  document.getElementById('MoChart').src = urlMO + queryString;
    //           document.getElementById('MTchart').src = urlMT + queryString;
    //          }
    //     }
     
    //  }


}

function customizeDataSourceWizard(args) { // console.log(args);
}

function GetQueryStringParams(sParam) {
    var sPageURL = window.location.search.substring(1);
    var sURLVariables = sPageURL.split('&');
    for (var i = 0; i < sURLVariables.length; i++) {
        var sParameterName = sURLVariables[i].split('=');
        if (sParameterName[0] == sParam) {
            return decodeURIComponent(sParameterName[1]);
        }
    }
   return "";
}


 function getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for(var i=0;i < ca.length;i++) {
        var c = ca[i];
        while (c.charAt(0)==' ') c = c.substring(1,c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
    }
    return null;
}

function App() {
    // var isLoggedIn = getCookie("loggedIn");
    // console.log(isLoggedIn);
    var url = window.location.href;
   var data;
   var dashboardId = GetQueryStringParams('dashboardId');
   //var failureTimeStamp = GetQueryStringParams('FailureTimestamp');
   var startTimestamp = GetQueryStringParams('StartTimestamp');
   var endTimestamp = GetQueryStringParams('EndTimestamp');
   var MoDriveTestLogFile = GetQueryStringParams('MoDriveTestLogFile');
    var MtDriveTestLogFile = GetQueryStringParams('MtDriveTestLogFile');
    var DriveTestLogFileName = GetQueryStringParams('DriveTestLogFileName');
    var DriveTestLogFile = GetQueryStringParams('DriveTestLogFile');
    var timeBefore = GetQueryStringParams('timeBefore');
    var timeAfter = GetQueryStringParams('timeAfter');
   var myArray;

   data = {
         // failureTimeStamp: failureTimeStamp,
          startTimestamp: startTimestamp,
          endTimestamp: endTimestamp,
          MoDriveTestLogFile: MoDriveTestLogFile,
          MtDriveTestLogFile: MtDriveTestLogFile,
          DriveTestLogFileName: DriveTestLogFileName,
          DriveTestLogFile : DriveTestLogFile,
          timeBefore:  timeBefore,
          timeAfter: timeAfter
     }
  
  if(dashboardId === 'drilldownNew_' ){
    return (
   <LoadDrillDown gridData={data} />
   )
  }else if(dashboardId === 'drilldownpacket_' ){
    return (
    <LoadDrillDownPacket gridData = {data} />
    )
  }
  else {
    return (
    <div className="welcome"
    style={
        {
            position: 'absolute',
            top: '0px',
            left: '0px',
            right: '0px',
            bottom: '0px',
            height: '100%'
        }
}> 

    <DashboardControl style={
            {height: '100%'}
        }
        id='webDashboard'
        ref={ dashboardControlRef }
        endpoint= {window.endpoint}
        onBeforeRender={onBeforeRender}
        defaultWorkingMode="Viewer"
        initialDashboardId={dashboardId}></DashboardControl>

        </div>
    )
  }

}

export default App;

