import $ from 'jquery';
import React from 'react';
import ReactDOM from "react-dom/client";
import { Button } from 'devextreme-react/button';
import { ProgressBar } from 'devextreme-react/progress-bar';
import './loadDrillDownData.css'
import DashboardControl from 'devexpress-dashboard-react';
import KeplerItem from '../keplerItem'
// import LoginForm from './components/LoginForm';
import * as Model from 'devexpress-dashboard/model';
import ButtonItem from '../ButtonCustomItem';
import CustomGrid from '../CustomGrid';
import LineChartItem from '../CustomChart';
import { json } from 'react-router-dom';
import {ComboBoxItem} from 'devexpress-dashboard/model';
import TreeViewItem from '../customTreeView';
import '../global';

// var url = "https://insight2-server.azurewebsites.net/";
// //var url = "https://localhost:44361/";
var url = window.link;
var comboBoxIdProperty = {
  ownerType: Model.ComboBoxItem,
  propertyName: 'comboBoxId',
  // defaultValue: false,
  valueType: 'string'
};

var dashboardPanel;
var dashboardControl;
var showpanel = true;
const maxValue = 100; 


// function formatTime(value) {
//   return `00:00:${(`0${value}`).slice(-2)}`;
// }

function statusFormat(ratio) {

  return `Loading: ${parseInt(ratio * 100)}%`;
}

function onBeforeRender(e) {
  dashboardControl = e.component;
  dashboardControl.registerExtension(new KeplerItem(dashboardControl));
  dashboardControl.registerExtension(new ButtonItem(dashboardControl));
  dashboardControl.registerExtension(new CustomGrid(dashboardControl));
  dashboardControl.registerExtension(new LineChartItem(dashboardControl));
  dashboardControl.registerExtension(new TreeViewItem(dashboardControl));
  //dashboardControl.on('dashboardInitialized', onDashboardInitialized)
  var UrlStateExtension = dashboardControl.findExtension('url-state');
  UrlStateExtension._options.includeDashboardIdToUrl = false;
  UrlStateExtension._options.includeDashboardStateToUrl = false;

  var viewerApiExtension = dashboardControl.findExtension('viewer-api');
  if (viewerApiExtension) {
       viewerApiExtension.on('itemWidgetOptionsPrepared', onItemWidgetOptionsPrepared);
      viewerApiExtension.on('itemCaptionToolbarUpdated', onItemCaptionToolbarUpdated);
      viewerApiExtension.on('dashboardTitleToolbarUpdated', onDashboardTitleToolbarUpdated);


  }

}

function onDashboardInitialized(e){
  // console.log(e);
  //     var items = e.dashboard.items();
  //      console.log(items);
  //      var itemType;

  //      for (var i=0; i< items.length; i++){
  //       itemType = items[i].itemType();
       
  //       if( itemType === 'ComboBox'){
           
  //       }
  //      }

  var state = '{"Items":{"comboBoxDashboardItem1":{"MasterFilterValues":[["LTE"]]}}}';
  e.component.setDashboardState(state);
}

function onDashboardTitleToolbarUpdated(e) {
  if (showpanel) {
      e.options.navigationItems.push({
          type: "button",
          icon: "dx-dashboard-designer-menu-icon",
          hint: "Menu",
          click: function (element) {
              if (dashboardPanel.visible() === false && dashboardControl.isDesignMode() === false) {
                  dashboardPanel.showPanelAsync({}).done(function (e) {
                      dashboardControl.surfaceLeft(e.surfaceLeft);
                  });
              } else if (dashboardPanel.visible() === true && dashboardControl.isDesignMode() === false) {
                  dashboardPanel.hidePanelAsync({}).done(function (e) {

                      dashboardControl.surfaceLeft(e.surfaceLeft);
                  });
              }
          }
      });
  }

}

function onItemCaptionToolbarUpdated(e, s) { // remove maximize
  if (e.itemName === "customItemDashboardItem1" || e.itemName === "customItemDashboardItem2" || e.itemName === "customItemDashboardItem3" || e.itemName === "customItemDashboardItem4" || e.itemName === "customItemDashboardItem5" || e.itemName === "customItemDashboardItem6") {

      var target = e.options.actionItems.find(function (element) {
          return element.name === "maximize-down-item";
      });

      var index = e.options.actionItems.indexOf(target);
      if (index > -1) {
          e.options.actionItems.splice(index, 1);
      }
  }

}


function onItemWidgetOptionsPrepared(args) {

  if (args.dashboardItem instanceof ComboBoxItem) {
      // console.log("data items: ",args.dashboardItem.dataItems())
      // console.log("showAllValue: ",args.dashboardItem.showAllValue())
      //Model.registerCustomProperty(comboBoxIdProperty);
      var comboBoxId = args.dashboardItem.customProperties.getValue(comboBoxIdProperty.propertyName);
       console.log(comboBoxId);
      if (comboBoxId === 'MO') {
      //  args.options.selectedItem.key = 1;

          args.options.onSelectionChanged = function (e) {
            console.log(e);
              var moChart = $('#MOchart').dxChart('instance');
           //   console.log(moChart);
              var index = e.value;
              var ds = e.component.getDataSource();
              // console.log(ds);
           //   var templateName = ds._items[index].value[0];
           var templateName = e.selectedItem.text;
              $.ajax({
                  type: "GET",
                  url: url + "api/trackers/templatebykey/" + templateName,
                  dataType: "json",
                  success: function (result, status, xhr) { // console.log(result);
                      var dataArray = result.data;

                    //   console.log("dataArray",dataArray);
                      var panes = [];
                      var series = [];
                      var valueField = 'value';
                      for (let i = 0; i < dataArray.length; i++) {
                        let obj = panes.find(panes => panes.name === dataArray[i].chartName);

                        if (obj === undefined) {
                            panes.push({name: dataArray[i].chartName})
                        }
                        series.push({pane: dataArray[i].chartName, valueField: dataArray[i].parameter, name: dataArray[i].parameter})
                      }

                      var MOdataSource = [];

                      // for(let i = 0; i< window.$mometrics.length; i++){
                      //   let found = dataArray.find( item => item['parameter'] === window.$mometrics[i].name );
                      //   if(found !== undefined){
                      //     MOdataSource.push(window.$mometrics[i]);
                      //   }
                      // }

                      for(let i = 0; i< window.$mometrics.length; i++){
                        let found = dataArray.find( item => item['parameter'] === window.$mometrics[i].name );
                        if(found !== undefined){
                         // console.log(found,window.$mometrics[i]);
                         var myvalue={
                         [window.$mometrics[i].name]: window.$mometrics[i].value,
                         timestamp: window.$mometrics[i].timestamp
                        };
                          MOdataSource.push(myvalue);
                        }
                      }

                    //   console.log('mo panes', panes);
                    //   console.log('mo series', series);
                    //   console.log("MOdataSource",MOdataSource);

                      moChart.option('dataSource', MOdataSource);
                      moChart.option('panes', panes);
                      moChart.option('series', series);
                      moChart.option('tooltip.customizeTooltip', function (info) { // console.log(info)
                      })
                     moChart.refresh();
                  },
                  error: function (xhr, status, error) {
                      console.log("Result: " + status + " " + error + " " + xhr.status + " " + xhr.statusText)
                  }
              });

          }
      } 
      

  }


}

let intervalId;

class LoadDrillDownPacket extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      seconds: maxValue,
      inProgress: false,
      isActive: true
    };

    // this.MTstate = {
    //   seconds: maxValue,
    //   inProgress: false,
    //   isActive: true
    // };

    this.gridData = this.props.gridData;
   //console.log(  this.gridData);

    this.moData = {
      //FailureTimestamp: this.gridData.failureTimeStamp.replace('T', ' '),
      StartTimestamp: this.gridData.startTimestamp.replace('T', ' '),
     EndTimestamp: this.gridData.endTimestamp.replace('T', ' '),
      MOlogfile : this.gridData.DriveTestLogFileName,
      PcapFileId : this.gridData.DriveTestLogFile,
      TimeBefore: this.gridData.timeBefore,
      TimeAfter: this.gridData.timeAfter,
    }
    this.modatastr = JSON.stringify(this.moData);

    // this.mtData = {
    //   Timestamp: this.gridData.failureTimeStamp.replace('T', ' '),
    //   MTlogfile : this.gridData.MtDriveTestLogFile
    // }

    // this.mtdatastr = JSON.stringify(this.mtData);

    //global variables
   window.$modata = [];
   window.$mtdata = [];

    this.timer = this.timer.bind(this);
  }

  render() {
//console.log(this.state);
  var _this = this;
  if (this.state.isActive) {
    return (
      <div>

      <div className="welcome"
             style={
                 {
                     position: 'absolute',
                     top: '0px',
                     left: '0px',
                     right: '0px',
                     bottom: '0px',
                     height: '100%'
                 }
               }> 
          
            <DashboardControl style={
                     {height: '100%'}
                 }
                 id='webDashboard'
                 endpoint= {window.endpoint}
                 onBeforeRender={onBeforeRender}
                 defaultWorkingMode="Viewer"
                 initialDashboardId="drilldownpacket_"></DashboardControl>
            </div>
      
      <div className="form" id='progressBar'>
 
        {/* <div className="progress-info">
                    Time left {formatTime(this.state.seconds)}
        </div> */}
        <ProgressBar
          id="progress-bar-Packet"
          className={this.state.seconds === 0 ? 'complete' : '' }
          width="90%"
          min={0}
          max={maxValue}
          statusFormat={statusFormat}
          value={maxValue - this.state.seconds}
          onInitialized = {
          function (e) {
            const state = {
              inProgress: !_this.state.inProgress
          };
  
              if (_this.state.seconds === 0) {
                  state.seconds = maxValue;
              }
  
            intervalId = setInterval(() => _this.timer(), 300);
          _this.setState(state);
        // var ajaxTime = new Date().getTime();
        var ajaxMessagesDone = false;
        var ajaxMeasurementsDone = false;
        var ajaxpcapMessagesDone = false;
       var ajaxMTMeasurementsDone = true;
        
        // $.ajax({
        //     url: url + 'api/trackers/l3messageo2MO?userData=' + _this.modatastr,
        //     type: 'GET',
        //     dataType: 'json',
        //     // data: JSON.stringify(_this.moData),
        //     success: function (data, textStatus, xhr) {
        //       console.log(data);
        //         window.$modata = data;
        //         ajaxMessagesDone = true;
        //         _this.rebindMOgridDataSource(window.$modata)
        //   if(ajaxMessagesDone && ajaxMeasurementsDone & ajaxpcapMessagesDone ){
        //     //console.log('moL3Messages')
        //     const state = {
        //       seconds: 0
        //     };
        //     _this.setState(state);
        //   }
        //     },
        //     error: function (xhr, textStatus, errorThrown) {
        //         console.log('Error in Operation');
        //     }
        // }).done(function () {
        
        //     // _this.totalTime = new Date().getTime() - ajaxTime;

        //     // _this.setState({
        //     //     seconds: _this.totalTime / 1000
        //     // });
        // });


        $.ajax({
            url: url + 'api/trackers/metricsmo?userData=' + _this.modatastr,
            type: 'GET',
            dataType: 'json',
            // data: JSON.stringify(_this.moData),
            success: function (data, textStatus, xhr) {

              window.$mometrics = data.item2;
              ajaxMeasurementsDone = true;
              _this.rebindMOchartDataSource(window.$mometrics);

              window.$modata = data.item1;
              ajaxMessagesDone = true;   
              _this.rebindMOgridDataSource(window.$modata);

              var state = '{"Items":{"comboBoxDashboardItem1":{"MasterFilterValues":[["LTE"]]}}}';
              dashboardControl.setDashboardState(state); 
           
                if(ajaxMessagesDone && ajaxMeasurementsDone & ajaxpcapMessagesDone ){
                 // console.log('moMetricsDone')
                  const state = {
                    seconds: 0
                  };
                  _this.setState(state);
                }
            },
            error: function (xhr, textStatus, errorThrown) {
                console.log('Error in Operation');
                console.log(xhr);
                console.log(textStatus);
                console.log("errorThrown:",errorThrown);
            }
        }).done(function () {
     
            // _this.totalTime = new Date().getTime() - ajaxTime;
            // // console.log(_this.totalTime/1000);
            // e.component.option('max', _this.totalTime / 1000)
            // e.component.option('value', (_this.totalTime / 1000) - _this.state.seconds)
            // _this.setState({
            //     seconds: _this.totalTime / 1000
            // });
        });

        
        $.ajax({
          url: url + 'api/trackers/pcap?userData='+ _this.modatastr,  
          type: 'GET',  
          dataType: 'json',
          success: function (data, textStatus, xhr) {  
           
           window.$pcapdata = data;
           ajaxpcapMessagesDone = true;
           _this.rebindPcapgridDataSource(window.$pcapdata);
              if(ajaxMessagesDone && ajaxMeasurementsDone & ajaxpcapMessagesDone ){
               // console.log('pcap3MessagesDone')
                // _this.MTstate.seconds = 0;
                const state = {
                  seconds: 0
                };
                _this.setState(state);
              
              } 
          },  
          error: function (xhr, textStatus, errorThrown) {  
            console.log('Error in Operation');
            console.log(xhr);
            console.log(textStatus);
            console.log("errorThrown:",errorThrown);
          }  
      })

          }
            }

          onComplete= {function(e){
           $('#progressBar').hide();
          }}
        />


      </div>


</div>
    );
  }
  }
  rebindMOgridDataSource(datasource){
    var count = datasource.length;
    $('#MO').dxDataGrid('instance').option("dataSource",datasource);
    //$('#MO').dxDataGrid('instance').option("filterPanel.texts.clearFilter", count + ' Records');
  }

  rebindPcapgridDataSource(datasource){
    var count = datasource.length;
    $('#pcap').dxDataGrid('instance').option("dataSource",datasource);
    //$('#pcap').dxDataGrid('instance').option("filterPanel.texts.clearFilter", count + ' Records');
  }

  rebindMOchartDataSource(datasource){
   var mochart = $('#MOchart').dxChart('instance');
   //console.log('mochart', mochart)
    mochart.option('datasource', datasource)
  //  mochart.refresh()
  }

  timer() {
    const state = {
      seconds: this.state.seconds - 1,
    };
    if(this.state.seconds>1){
      this.setState(state);
    }

    
  }


  // MTtimer() {
  //   const state = {
  //     seconds: this.MTstate.seconds - 1,
  //   };
  //   if(state.seconds>1){
  //     this.MTstate.seconds = state.seconds;
  //   }

    
  // }



}

export default LoadDrillDownPacket;
